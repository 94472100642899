/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { ReactNode } from 'react';
import { ValueType } from 'react-select';

import OptionType, { OptionProps } from 'types/optionType';
import CampoPrototipo, {
  CampoPrototipoProps,
} from 'components/PDV/Geral/CampoPrototipo';
import SelectHighlighter from 'components/PDV/SelectHighlighter';
import { MobileSelectWrapper } from 'components/update/Select/MobileSelect';

import AsyncSelectInput, { AsyncSelectInputProps } from './Input';

type SelectProps = {
  handleGetOptions: (
    inputValue: string,
    forceEnter?: boolean
  ) => Promise<OptionType[]>;
};

export type AsyncSelectProps = {
  withoutDefaultOptions?: boolean;
  asControlledByObject?: boolean;
  onOptionSelect?: (option: OptionProps) => void;
  onSelect?: () => void;
  topRightElement?: ReactNode;
  isLeitorCodigoBarras?: boolean;
  asMobileView?: boolean;
  width?: string;
  fontWeightLabel?: string;
  setValor?: React.Dispatch<React.SetStateAction<any>>;
};

export type AsyncSelectFieldProps = Omit<AsyncSelectInputProps, 'disabled'> &
  Omit<CampoPrototipoProps, 'disabled'> &
  AsyncSelectProps &
  SelectProps;

const AsyncSelectField = ({
  handleGetOptions,
  name,
  label,
  required = false,
  isDisabled,
  helperText,
  id,
  isMulti = false,
  onOptionSelect,
  isLeitorCodigoBarras = false,
  options,
  colSpan,
  colStart,
  colEnd,
  rowSpan,
  width,
  rowStart,
  rowEnd,
  withoutDefaultOptions,
  asControlledByObject = false,
  errorPropName,
  errorText,
  onSelect,
  topRightElement,
  asMobileView = false,
  shouldAppearTheAddress,
  setValor,
  labelColor,
  fontWeightLabel = 'semibold',
  ...selectProps
}: AsyncSelectFieldProps) => {
  return (
    <CampoPrototipo
      name={name}
      errorPropName={errorPropName}
      errorText={errorText}
      isRequired={required}
      isDisabled={isDisabled}
      helperText={helperText}
      id={id}
      width={width}
      label={label}
      labelColor={labelColor}
      rowSpan={rowSpan}
      rowStart={rowStart}
      rowEnd={rowEnd}
      colSpan={colSpan}
      colStart={colStart}
      colEnd={colEnd}
      topRightElement={topRightElement}
      defaultValue=""
      isInvalid={!!errorText}
      fontWeightLabel={fontWeightLabel}
    >
      {(
        { formState: { isSubmitting }, setValue, clearErrors },
        { value, ...fieldProps }
      ) => (
        <MobileSelectWrapper asMobileView={asMobileView}>
          {({ onClose }) => {
            if (setValor) {
              setValor(value);
            }
            return (
              <AsyncSelectInput
                handleGetOptions={
                  isDisabled ? async () => [] as OptionType[] : handleGetOptions
                }
                withoutDefaultOptions={withoutDefaultOptions}
                isDisabled={isSubmitting || isDisabled}
                {...fieldProps}
                {...selectProps}
                id={id}
                isMulti={isMulti}
                formatOptionLabel={SelectHighlighter}
                value={asControlledByObject ? value : undefined}
                isOptionSelected={
                  asControlledByObject
                    ? undefined
                    : (option) => {
                        if (value) {
                          if (Array.isArray(value) && value.length > 0) {
                            return value.some((v) => option.value === v);
                          }

                          return option.value === value;
                        }

                        return false;
                      }
                }
                onChange={async (
                  option: ValueType<OptionType> | null | undefined
                ) => {
                  const delay = (ms: number) =>
                    new Promise((resolve) => setTimeout(resolve, ms));

                  await delay(0);

                  if (option) {
                    clearErrors(`${name}` as const);
                  }

                  if (onOptionSelect) {
                    onOptionSelect(option as OptionProps);
                  }

                  const onClear = () => {
                    setValue(`${name}` as const, null, { shouldDirty: true });
                  };

                  if (!option) {
                    onClear();
                    return;
                  }

                  if (Array.isArray(option) && option.length === 0) {
                    onClear();
                    return;
                  }

                  if (Array.isArray(option)) {
                    setValue(
                      `${name}` as const,
                      option.map((opt) =>
                        asControlledByObject ? opt : opt.value
                      ),
                      { shouldDirty: true }
                    );
                    return;
                  }

                  if (shouldAppearTheAddress) {
                    const labelInicial: number = (option as OptionType).label?.indexOf(
                      '-'
                    );
                    const labelFinal: number = (option as OptionType).label?.indexOf(
                      '('
                    );

                    const splittedString = (option as OptionType).label.slice(
                      labelInicial + 1,
                      labelFinal
                    );
                    setValue(
                      `${name}` as const,
                      {
                        ...option,
                        label: splittedString,
                      },
                      { shouldDirty: true }
                    );

                    return;
                  }

                  setValue(
                    `${name}` as const,
                    asControlledByObject
                      ? option
                      : (option as OptionType).value,
                    {
                      shouldDirty: true,
                    }
                  );

                  if (onSelect) {
                    onSelect();
                  }

                  if (onClose) {
                    onClose();
                  }
                }}
                shouldAppearTheAddress={shouldAppearTheAddress}
              />
            );
          }}
        </MobileSelectWrapper>
      )}
    </CampoPrototipo>
  );
};

export default AsyncSelectField;
