import { useCallback, useEffect, useImperativeHandle } from 'react';
import { useMediaQuery } from '@chakra-ui/react';
import { useForm, FormProvider } from 'react-hook-form';
import { toast } from 'react-toastify';

import { useProdutosFormularioContext } from 'store/Produtos/ProdutosFormulario';
import api, { ResponseApi } from 'services/api';
import { useValidationFormDirtyFields } from 'hooks/useValidationFormDirtyFields';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

import { ItemEcommerceMobile } from './TabPanels/components/ItemEcommerceMobile';
import { ItemEcommerce } from './TabPanels/components/ItemEcommerce';
import { valueTabs } from './TabPanels/validationForms';

type ValoresEcommerceProps = {
  id: string;
  produtoId: string;
  identificacaoIntegracao: number;
  titulo: string;
  descricao: string;
  anunciado: boolean;
  itensInclusos: string;
  tempoGarantia: string;
  disponibilidadeEntrega: string;
  campo1: string;
  campo2: string;
  [key: string]: string | number | boolean;
};

export const Ecommerce = () => {
  const {
    idProduto,
    setIsLoading,
    ref,
    onChangeFormIsDirty,
  } = useProdutosFormularioContext();

  const formMethods = useForm();

  const {
    getValues,
    formState: { dirtyFields },
    reset,
  } = formMethods;

  const isDirty = useValidationFormDirtyFields(dirtyFields);

  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

  const getValoresEcommerce = useCallback(() => {
    return valueTabs.map((valueEcommerce) => {
      const identificacao = valueEcommerce.value;

      const newValues = {
        produtoId: idProduto,
        identificacaoIntegracao: identificacao,
        titulo: getValues(`titulo-${identificacao}`),
        descricao: getValues(`descricao-${identificacao}`),
        anunciado: getValues(`anunciado-${identificacao}`),
        itensInclusos: getValues(`itensInclusos-${identificacao}`),
        tempoGarantia: getValues(`tempoGarantia-${identificacao}`),
        disponibilidadeEntrega: getValues(
          `disponibilidadeEntrega-${identificacao}`
        ),
        campo1: getValues(`campo1-${identificacao}`),
        campo2: getValues(`campo2-${identificacao}`),
      } as ValoresEcommerceProps;

      return newValues;
    });
  }, [getValues, idProduto]);

  const getDataEcommerce = useCallback(async () => {
    setIsLoading(true);
    const response = await api.get<void, ResponseApi<ValoresEcommerceProps[]>>(
      `${ConstanteEnderecoWebservice.PRODUTOS_CADASTRAR_DADOS_GERAIS_V2}/${idProduto}/ecommerces`
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((item: string) => toast.warning(item));
      }

      if (response.sucesso && response.dados.length > 0) {
        const element = {} as { [key: string]: string | number | boolean };
        // para manter uma lógica mais simples e fácil, quando for passar o "name" nos campos do formulário do ecommerce
        // passar sempre o nome que vem da api e a identificação da integração, assim nessa função já altera todos os valores corretamente.
        // ex: name={`anunciado-${identificacao}`} olhar também no componente -> import { TrayEcommerce } from './TabPanels/Tray' para entender a estrutura também
        response.dados.forEach((dataEcommerce) => {
          const identificacao = dataEcommerce.identificacaoIntegracao;
          Object.keys(dataEcommerce).forEach((key) => {
            if (dataEcommerce && dataEcommerce[key]) {
              const valor = dataEcommerce[key];
              element[`${key}-${identificacao}`] = valor;
            }
          });
        });

        reset(element);
        setIsLoading(false);
      }
      setIsLoading(false);
    }
    setIsLoading(false);
  }, [idProduto, setIsLoading, reset]);

  const salvarDados = useCallback(async () => {
    if (isDirty === false) {
      return true;
    }
    setIsLoading(true);
    const data = getValoresEcommerce();

    const response = await api.put<void, ResponseApi<ValoresEcommerceProps[]>>(
      `${ConstanteEnderecoWebservice.PRODUTOS_CADASTRAR_DADOS_GERAIS_V2}/${idProduto}/ecommerces`,
      data
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((item: string) => toast.warning(item));
        setIsLoading(false);
        return false;
      }

      if (response.sucesso) {
        setIsLoading(false);
        return true;
      }
      setIsLoading(false);
      return false;
    }
    setIsLoading(false);
    return true;
  }, [getValoresEcommerce, idProduto, isDirty, setIsLoading]);

  useImperativeHandle(ref, () => ({
    onClick: () => salvarDados(),
  }));

  useEffect(() => {
    getDataEcommerce();
  }, [getDataEcommerce]);

  useEffect(() => {
    onChangeFormIsDirty(isDirty);
  }, [isDirty, onChangeFormIsDirty]);

  return (
    <FormProvider {...formMethods}>
      {isLargerThan900 ? <ItemEcommerce /> : <ItemEcommerceMobile />}
    </FormProvider>
  );
};
