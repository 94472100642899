import React, { useCallback, useRef, useState } from 'react';
import { Box, Icon, IconButton } from '@chakra-ui/react';
import { HexColorPicker } from 'react-colorful';
import { useFormContext, useWatch } from 'react-hook-form';
import { FiChevronDown } from 'react-icons/fi';

import './styles.css';

import Input from 'components/PDV/Input';

import { useClickOutside } from '../../hooks';

const style = {
  borderWidth: '3px',
  borderStyle: 'solid',
  borderRadius: '32px',
};

export const ColorPicker = ({ name }: { name: string }) => {
  const [isOpen, toggle] = useState(false);
  const cor = useWatch({ name });
  const formMethods = useFormContext();

  const divRef = useRef<HTMLDivElement>(null);

  const close = useCallback(() => toggle(false), []);
  useClickOutside(divRef, close);

  const calcularContrasteYIQ = useCallback((hexcolor: string) => {
    if (hexcolor?.toUpperCase() === '#FFF') {
      return 'black';
    }
    if (hexcolor?.toUpperCase() === '#000') {
      return 'white';
    }

    const r = parseInt(hexcolor?.substring(1, 3), 16);
    const g = parseInt(hexcolor?.substring(3, 5), 16);
    const b = parseInt(hexcolor?.substring(5, 7), 16);
    const yiq = (r * 299 + g * 587 + b * 114) / 1000;
    return yiq >= 128 ? 'black' : 'white';
  }, []);

  return (
    <div
      className="picker"
      tabIndex={0}
      role="button"
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          e.preventDefault();
          toggle(!isOpen);
        }
      }}
    >
      <div
        style={{
          ...style,
          borderColor: cor,
        }}
      >
        <div
          className="swatch"
          style={{
            backgroundColor: cor,
            borderColor: calcularContrasteYIQ(cor),
          }}
          role="button"
          tabIndex={-1}
          onKeyDown={() => {}}
          onClick={() => toggle(!isOpen)}
        >
          <IconButton
            aria-label="Abrir seletor de cor"
            icon={<Icon as={FiChevronDown} fontSize="lg" />}
            transform={isOpen ? 'rotate(180deg)' : 'rotate(0deg)'}
            variant="link"
            color={calcularContrasteYIQ(cor) || 'black'}
            p={1}
            px={1}
            borderRadius="md"
            minW="1px"
            pointerEvents="none"
            boxShadow="none"
            tabIndex={-1}
            _focus={{ boxShadow: 'none' }}
          />
        </div>
      </div>

      {isOpen && (
        <div
          className="popover"
          ref={divRef}
          style={{
            backgroundColor: 'transparent',
            top: '45px',
            left: '-78px',
          }}
        >
          <HexColorPicker
            color={cor}
            onChange={(e) => {
              formMethods.setValue(name, e?.toUpperCase());
            }}
          />
          <Box w="200px">
            <Input
              name={name}
              id={name}
              placeholder="#000000"
              fontSize="14px"
              borderRadius="0px"
              onInput={(e: any) =>
                formMethods.setValue(name, e.target.value?.toUpperCase())
              }
              style={{
                boxShadow: 'none',
                border: 'none',
                width: '200px',
              }}
              maxLength={7}
              label=""
            />
          </Box>
        </div>
      )}
    </div>
  );
};
