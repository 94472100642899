import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Col, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import {
  Text,
  Flex,
  useToken,
  GridItem,
  Box,
  Icon,
  Checkbox,
  Tooltip,
  FormLabel,
} from '@chakra-ui/react';
import { MdInfo } from 'react-icons/md';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

import auth from 'modules/auth';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import MeioPagamentoEnum from 'constants/enum/fiscal/meioPagamento';
import TipoIntegracaoPagamentoEnum from 'constants/enum/tipoIntegracaoPagamento';
import PlanoContratacaoEnum from 'constants/enum/planoContratacao';
import TipoContaFinanceiraEnum from 'constants/enum/tipoContaFinanceira';
import { moneyMask } from 'helpers/format/fieldsMasks';
import { enumReferenciaServicoStargate } from 'constants/enum/referenciaServicoStargate';

import InputPadrao from 'components/Input/InputPadrao';
import CheckBoxAtivoInativo from 'components/CheckBox/CheckBoxAtivoInativo';
import { OptionsInterface } from 'components/Select/SelectCreate';
import SelectPadrao from 'components/Select/SelectPadrao';
import AccordionPadrao from 'components/Accordion/AccordionPadrao';
import ButtonComOpcoes from 'components/Button/ButtonComOpcoes';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import { validacaoContaFinanceira } from 'pages/FormaRecebimento/naoPossuiContaFinanceira';
import TableCampoFormularioPadrao from 'components/Table/TableCampoFormularioPadrao';
import { ModalConfirmacaoExcluir } from 'components/Modal/ModalConfirmacaoExcluir';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import { InfoTooltip } from 'components/update/Tooltip/InfoTooltip';

import OptionType from 'types/optionType';

import ParcelamentoModal from './ModalParcelamentos';
import { ModalContaFinanceiraPorLoja } from '../ModalContaFinanceiraPorLoja';

import {
  ContaFinanceira,
  ContaFinanceiraInterface,
  CredenciadoraCartaoProps,
  FormaPagamentoRecebimentoLojas,
  IdRota,
  LojasConfigStone,
  LojasProps,
  StoneConfig,
  UncontrolledFormInterface,
} from './Types';

export const UncontrolledForm = ({
  errors,
  setError,
  clearErrors,
  watch,
  register,
  control,
  setContaFinanceiraLoja,
  readonly,
  setValue,
  meioPagamento,
  formaPagamentoRecebimentoLoja,
  getValues,
  setMeioPagamento,
  isCadastrar,
}: UncontrolledFormInterface) => {
  const { t } = useTranslation();
  const blue500 = useToken('colors', 'blue.500');
  const userCanAddInstallmentsForPayment =
    readonly || meioPagamento === MeioPagamentoEnum.ValePresente;

  const formaNaoPossuiParcelamento =
    meioPagamento === MeioPagamentoEnum.CartaoDebitoStone ||
    meioPagamento === MeioPagamentoEnum.PixPresencialZoop ||
    meioPagamento === MeioPagamentoEnum.PixEnvioZoop;

  const meioPagamentoConnectStone =
    meioPagamento === MeioPagamentoEnum.CartaoCreditoStone ||
    meioPagamento === MeioPagamentoEnum.CartaoDebitoStone;

  const meioPagamentoZoop =
    meioPagamento === MeioPagamentoEnum.PixPresencialZoop ||
    meioPagamento === MeioPagamentoEnum.PixEnvioZoop ||
    meioPagamento === MeioPagamentoEnum.BoletoZoop;

  const [isLoading, setIsLoading] = useState(false);
  const [contasFinanceiras, setContasFinanceiras] = useState<ContaFinanceira[]>(
    []
  );

  const [credenciadorasCartao, setCredenciadorasCartao] = useState<
    OptionType<string>[]
  >([]);
  const [caixas, setCaixas] = useState<StoneConfig[]>([]);
  const [atualizarContaFinanceira, setAtualizarContaFinanceira] = useState(
    false
  );
  const [listaLojasContaFinanceira, setListaLojasContaFinanceira] = useState<
    FormaPagamentoRecebimentoLojas[]
  >([]);

  const [lojas, setLojas] = useState<LojasProps[]>([]);
  const [lojasConfigStone, setLojasConfigStone] = useState<LojasConfigStone[]>(
    []
  );

  const hasMaisDeUmaLoja = (lojasConfigStone.length || []) > 1;

  const naoPossuiContaFinanceira = validacaoContaFinanceira(meioPagamento);

  const { id: idRota } = useParams<IdRota>();

  const [ParcelamentoModalIsOpen, setParcelamentoModalIsOpen] = useState(false);
  const [indexItemAlteracao, setIndexItemAlteracao] = useState(-1);
  const [contaFinanceiraRequired, setcontaFinanceiraRequired] = useState(false);

  const { id: idLojaAtual } = auth.getLoja();
  const isPlanoStart = auth.getPlano() === PlanoContratacaoEnum.START;
  const [
    listaContaFinanceiraLojaAtual,
    setListaContaFinanceiraLojaAtual,
  ] = useState<ContaFinanceira>({} as ContaFinanceira);

  const possuiServicoFrenteCaixa = auth.possuiServico(
    enumReferenciaServicoStargate.DISPOSITIVO_FRENTE_CAIXA
  ).permitido;

  const meioPagamentoOptions = Object.entries(MeioPagamentoEnum.properties)
    .filter(
      (obj) =>
        obj[1].value !== MeioPagamentoEnum.Dinheiro &&
        obj[1].value !== MeioPagamentoEnum.Cheque
    )
    .map((obj) => {
      return {
        label: obj[1].name,
        value: obj[1].value,
      };
    });

  const meioPagamentoOrdenado = meioPagamentoOptions.sort((a, b) => {
    const smartPosValues = [
      MeioPagamentoEnum.CartaoCreditoSmartPos,
      MeioPagamentoEnum.CartaoDebitoSmartPos,
      MeioPagamentoEnum.PixSmartPos,
    ];

    const zoopValues = [
      MeioPagamentoEnum.PixPresencialZoop,
      MeioPagamentoEnum.PixEnvioZoop,
      MeioPagamentoEnum.BoletoZoop,
    ];

    const meiosPagamentoStone = [
      MeioPagamentoEnum.CartaoCreditoStone,
      MeioPagamentoEnum.CartaoDebitoStone,
    ];

    const labelA = a.label.toUpperCase();
    const labelB = b.label.toUpperCase();

    const valueA = a.value;
    const valueB = b.value;

    const isSmartPosA = smartPosValues.includes(valueA);
    const isSmartPosB = smartPosValues.includes(valueB);
    const isStoneA = meiosPagamentoStone.includes(valueA);
    const isStoneB = meiosPagamentoStone.includes(valueB);
    const isZoopA = zoopValues.includes(valueA);
    const isZoopB = zoopValues.includes(valueB);

    if ((isSmartPosA || isStoneA || isZoopA) && !isSmartPosB) {
      return 1;
    }

    if (!isSmartPosA && (isSmartPosB || isStoneB || isZoopB)) {
      return -1;
    }

    if (isStoneA && !isStoneB && !isZoopB) {
      return 1;
    }
    if (!isStoneA && (isStoneB || isZoopB)) {
      return -1;
    }

    return labelA.localeCompare(labelB);
  });

  const contaFinanceiraLojaAtual = useCallback(() => {
    if (formaPagamentoRecebimentoLoja) {
      const contaFinanceiraFiltrada = formaPagamentoRecebimentoLoja.filter(
        (loja) => loja.lojaId === idLojaAtual
      );

      if (contaFinanceiraFiltrada.length > 0) {
        const itemContaFinanceira = contaFinanceiraFiltrada[0];
        if (itemContaFinanceira) {
          const contaFinanceiraLoja = contasFinanceiras.filter(
            (contaFinanceira) =>
              contaFinanceira.value === itemContaFinanceira.contaFinanceiraId
          )[0];

          const valueAtualContaFinanceira = {
            label: contaFinanceiraLoja?.label,
            value: contaFinanceiraLoja?.value,
          };
          setListaContaFinanceiraLojaAtual(valueAtualContaFinanceira);
        }
      }
    }
  }, [contasFinanceiras, formaPagamentoRecebimentoLoja, idLojaAtual]);

  const handleGetContaFinanceiraValues = useCallback(async () => {
    const response = await api.get<
      void,
      ResponseApi<ContaFinanceiraInterface[]>
    >(ConstanteEnderecoWebservice.CONTA_FINANCEIRA_LISTAR_SELECT_CONTA_BANCOS, {
      params: { lojaId: idLojaAtual },
    });

    if (response?.avisos) {
      response.avisos.map((item: string) => toast.warning(item));
    }

    if (response?.sucesso) {
      setContasFinanceiras(
        response.dados.map((contafinanceira) => {
          return {
            label: contafinanceira.nome,
            value: contafinanceira.id,
          } as OptionsInterface;
        })
      );
      setAtualizarContaFinanceira(true);
    }

    return undefined;
  }, [idLojaAtual]);
  const contaFinanceira = watch('contaFinanceira');

  const salvandoValoresContaFinanceraPorLoja = useCallback(
    (valoresContaFinanceiraSelecionada: FormaPagamentoRecebimentoLojas[]) => {
      setListaLojasContaFinanceira(valoresContaFinanceiraSelecionada);
      if (setContaFinanceiraLoja) {
        setContaFinanceiraLoja(valoresContaFinanceiraSelecionada);
      }
    },
    [setContaFinanceiraLoja]
  );

  const latestProps = useRef({ setValue, contaFinanceira });

  const deleteParcelamento = async (id: string) => {
    setIsLoading(true);
    const response = await api.delete<void, ResponseApi<void>>(
      ConstanteEnderecoWebservice.FORMA_RECEBIMENTO_PARCELA_EXCLUIR,
      {
        params: {
          formaRecebimentoParcelaId: id,
        },
      }
    );
    if (response) {
      if (response.avisos) {
        response.avisos.forEach((avisos) => toast.warning(avisos));
      }
      if (response.sucesso) {
        toast.success('O parcelamento foi removido com sucesso');
        setIsLoading(false);
        return true;
      }
    }
    setIsLoading(false);
    return false;
  };

  const removerItemList = (index: number, propertyName: string) => {
    ModalConfirmacaoExcluir({
      callback: async (ok: boolean) => {
        if (ok) {
          const items = getValues(propertyName) as Array<any>;
          const id = items[index]?.id;
          let deleteSuccess = Boolean(isCadastrar);
          if (!isCadastrar) {
            deleteSuccess = await deleteParcelamento(id);
          }
          if (deleteSuccess) {
            items.splice(index, 1);
            setValue(propertyName, [...items]);
          }
        }
      },
    });
  };

  const toggleInutilizarLoja = (idLoja: string) => {
    setLojasConfigStone((prevState) => {
      return prevState.map((loja) => {
        if (loja.id === idLoja) {
          return {
            ...loja,
            naoUtilizaStoneCode: !loja.naoUtilizaStoneCode,
            mostrarCaixas: false,
          };
        }
        return loja;
      });
    });
    setValue(`stoneCode.${idLoja}`, '');
  };

  const toggleMostrarCaixas = (idLoja: string) => {
    setLojasConfigStone((prevState) => {
      return prevState.map((loja) => {
        if (loja.id === idLoja) {
          return {
            ...loja,
            mostrarCaixas: !loja.mostrarCaixas,
          };
        }
        return loja;
      });
    });
  };

  const validarSerial = (
    newSerial: string,
    idCaixa: string,
    idLoja: string
  ) => {
    const listSerials = getValues(`serialStone.${idLoja}.`);
    const listValuesSerials = Object?.values(listSerials);

    const serialJaUsado =
      (listValuesSerials || [])?.filter((serial) => serial === newSerial)
        ?.length > 1;
    if (serialJaUsado) {
      setError(`serialStone.${idLoja}.${idCaixa}`, {
        message: 'Informe um serial não utilizado',
      });
    } else {
      clearErrors(`serialStone.${idLoja}.${idCaixa}`);
    }
  };

  const resetNameValue = useCallback(() => {
    setValue('nome', '');
  }, [setValue]);

  useEffect(() => {
    if (naoPossuiContaFinanceira) {
      latestProps.current.setValue('contaFinanceira', '');
    } else if (
      isPlanoStart &&
      contasFinanceiras[0] &&
      contasFinanceiras &&
      contasFinanceiras[0].value
    ) {
      latestProps.current.setValue(
        'contaFinanceira',
        contasFinanceiras[0].value
      );
      setcontaFinanceiraRequired(true);
    } else {
      setcontaFinanceiraRequired(true);
      latestProps.current.setValue(
        'contaFinanceira',
        idRota && listaContaFinanceiraLojaAtual.value
          ? listaContaFinanceiraLojaAtual.value
          : ''
      );
    }
  }, [
    naoPossuiContaFinanceira,
    atualizarContaFinanceira,
    idRota,
    listaContaFinanceiraLojaAtual,
    isPlanoStart,
    contasFinanceiras,
  ]);
  useEffect(() => {
    contaFinanceiraLojaAtual();
  }, [contaFinanceiraLojaAtual]);

  useEffect(() => {
    handleGetContaFinanceiraValues();
  }, [handleGetContaFinanceiraValues]);

  useEffect(() => {
    if (formaNaoPossuiParcelamento) {
      setValue('parcelas', []);
    }
  }, [formaNaoPossuiParcelamento, setValue]);

  const getLojas = useCallback(async () => {
    setIsLoading(true);
    const response = await api.get<void, ResponseApi<LojasProps[]>>(
      ConstanteEnderecoWebservice.LISTAR_LOJA_COM_CONTAS_FINANCEIRAS
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((avisos) => toast.warning(avisos));
      }

      if (response.dados && response.sucesso) {
        const newDadosLoja = response.dados.filter(
          (loja) => loja.id !== idLojaAtual
        );
        const configLoja = response.dados.map((loja) => {
          return {
            ...loja,
            mostrarCaixas: false,
            naoUtilizaStoneCode: false,
          };
        });
        setLojas(newDadosLoja);
        return configLoja;
      }
    }
    setIsLoading(false);
    return [];
  }, [idLojaAtual]);

  const obterCredenciadoraCartaoListaPaginada = useCallback(async () => {
    const response = await api.get<
      void,
      ResponseApi<CredenciadoraCartaoProps[]>
    >(ConstanteEnderecoWebservice.CREDENCIADORA_CARTAO_LISTAR_SELECT);

    if (response) {
      if (response?.avisos) {
        response.avisos.forEach((avisos) => toast.warning(avisos));
      }

      if (response?.sucesso && response?.dados) {
        const options = response.dados.map((dados) => ({
          value: dados.id,
          label: dados.nome,
        }));

        setCredenciadorasCartao(options);
      }
    }
  }, []);

  const getDadosFormaRecebimento = useCallback(async () => {
    setIsLoading(true);
    const configLojas = await getLojas();
    const response = await api.get<void, ResponseApi<StoneConfig[]>>(
      ConstanteEnderecoWebservice.CONTA_FINANCEIRA_LISTAR_SELECT_CAIXA_TODAS_LOJAS
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((avisos) => toast.warning(avisos));
      }

      if (response.sucesso && response.dados) {
        const listCaixaIdNome = response.dados?.map((caixa) => {
          return caixa?.listaIdNome;
        });

        response.dados?.forEach((loja) => {
          setValue(
            `stoneCode.${loja.lojaId}`,
            loja.stoneCode ? loja.stoneCode : ''
          );
        });

        setLojasConfigStone(() => {
          return (configLojas || [])?.map((loja) => {
            const lojaConfig = response.dados?.find(
              (item) => item.lojaId === loja.id
            );
            return {
              ...loja,
              naoUtilizaStoneCode: !lojaConfig?.stoneCode,
              mostrarCaixas: !!lojaConfig?.stoneCode,
            };
          });
        });

        listCaixaIdNome?.forEach((caixa, index) => {
          caixa?.forEach((item) => {
            setValue(
              `serialStone.${response.dados[index].lojaId}.${item.id}`,
              item.serialStone
            );
          });
        });

        setCaixas(response.dados);
      }
    }
    setIsLoading(false);
  }, [getLojas, setValue]);

  useEffect(() => {
    getDadosFormaRecebimento();
  }, [getDadosFormaRecebimento]);

  useEffect(() => {
    if (lojas?.length > 0) return;
    const valoresContaFinanceiraSelecionada = lojas?.map((loja, index) => {
      const idContaFinanceira = contaFinanceira[index];
      return {
        contaFinanceiraId: idContaFinanceira,
        lojaId: loja.id,
      };
    });

    salvandoValoresContaFinanceraPorLoja(valoresContaFinanceiraSelecionada);
  }, [
    contaFinanceira,
    lojas,
    salvandoValoresContaFinanceraPorLoja,
    watch,
    isPlanoStart,
  ]);

  useEffect(() => {
    obterCredenciadoraCartaoListaPaginada();
  }, [obterCredenciadoraCartaoListaPaginada]);

  useEffect(() => {
    if (meioPagamentoZoop) {
      resetNameValue();
    }
  }, [meioPagamentoZoop, resetNameValue]);

  return (
    <>
      {isLoading && <LoadingPadrao />}
      <Form.Row>
        <Col md={3} sm={6} xs={12}>
          <InputPadrao
            type="text"
            id="nome"
            label={t('Nome')}
            autoFocus
            required
            placeholder={t('Digite um nome')}
            maxLength={40}
            error={errors.nome}
            disabled={readonly || meioPagamentoZoop}
            control={control}
          />
        </Col>
        <Col md={3} sm={6} xs={12}>
          <SelectPadrao
            id="regraMeioPagamento"
            name="regraMeioPagamento"
            label={t('Meio de recebimento')}
            noSelectedText={t('Clique aqui para selecionar.')}
            defaultValue=""
            required
            styles={{
              option: (base: any, state: any) => ({
                ...base,
                cursor: 'pointer',
                position: 'relative',
                paddingLeft: '40px',
                color: 'var(--black)',
                backgroundColor: 'var(--white)',
                borderTop:
                  state.value === MeioPagamentoEnum.CartaoCreditoSmartPos ||
                  state.value === MeioPagamentoEnum.CartaoCreditoStone ||
                  state.value === MeioPagamentoEnum.PixPresencialZoop
                    ? '1px solid #cccccc'
                    : '',
                '&:hover': {
                  backgroundColor: 'var(--sti-ck-colors-gray-50)',
                },
                '&:after': {
                  content: state.isSelected ? '""' : 'none',
                  position: 'absolute',
                  left: '18px',
                  top: '50%',
                  transform: 'translateY(-50%) rotate(45deg)',
                  width: '5px',
                  height: '10px',
                  border: 'solid var(--black)',
                  borderWidth: '0 1px 1px 0',
                },
              }),
            }}
            control={control}
            readonly={readonly}
            error={errors.regraMeioPagamento}
            onChange={(newValue: any) => {
              const semContaFinanceira = validacaoContaFinanceira(
                newValue.value
              );
              if (newValue.value === 1) {
                setValue('IntegracaoPagamento', undefined);
                setValue('cnpjCredenciadora', '');
              }
              if (semContaFinanceira) {
                setcontaFinanceiraRequired(false);
                setError('contaFinanceira', { message: '' });
              } else {
                setcontaFinanceiraRequired(true);
              }

              setMeioPagamento(newValue.value);
            }}
            options={meioPagamentoOrdenado}
          />
        </Col>
        <Col
          style={{ position: 'relative' }}
          xl={3}
          lg={4}
          md={3}
          sm={6}
          xs={12}
        >
          <Flex mb="2px" justifyContent="space-between" alignItems="center">
            <Text color="black">Conta financeira</Text>
            {lojas?.length > 0 && (
              <Text
                textAlign="right"
                cursor={naoPossuiContaFinanceira ? 'not-allowed' : 'pointer'}
                _hover={{
                  textDecoration: 'underline',
                }}
                mt="5px"
                fontWeight="semibold"
                fontSize="10px"
                color={naoPossuiContaFinanceira ? 'gray.200' : 'purple.500'}
                onClick={() => {
                  if (!naoPossuiContaFinanceira) {
                    ModalContaFinanceiraPorLoja({
                      listaLojasContaFinanceira,
                      formaPagamentoRecebimentoLoja,
                      idRota,
                      salvandoValoresContaFinanceraPorLoja,
                    });
                  }
                }}
              >
                Diferenciar por loja
              </Text>
            )}
          </Flex>
          <SelectPadrao
            id="contaFinanceira"
            name="contaFinanceira"
            isDisabled={isPlanoStart}
            noSelectedText={
              contaFinanceiraRequired
                ? t('Clique aqui para selecionar.')
                : t('Não é preciso informar')
            }
            placeholder={
              contaFinanceiraRequired
                ? t('Clique aqui para selecionar.')
                : t('Não é preciso informar')
            }
            required={contaFinanceiraRequired}
            defaultValue={null}
            readonly={readonly || naoPossuiContaFinanceira}
            control={control}
            error={errors.contaFinanceira}
            options={contasFinanceiras}
          />
        </Col>
        <Col xl={0} lg={2} md={3} sm={4} xs={12}>
          <CheckBoxAtivoInativo
            id="ativo"
            name="ativo"
            label="Status"
            control={control}
            readonly={readonly}
          />
        </Col>
      </Form.Row>
      {meioPagamentoConnectStone && (lojasConfigStone || [])?.length > 0 && (
        <>
          {isLoading && <LoadingPadrao />}
          <Flex flexDirection="column" w="full" mt="28px" mb="36px">
            <Text fontWeight="bold">Connect Stone</Text>
            <Box
              bg="gray.100"
              pt={hasMaisDeUmaLoja ? '24px' : '32px'}
              pb="12px"
              px={hasMaisDeUmaLoja ? ['12px', '24px'] : ['24px', '48px']}
              w="full"
              borderRadius="5px"
            >
              {lojasConfigStone.map((loja) => {
                const lojaIsPlanoStart =
                  loja?.plano === PlanoContratacaoEnum.START;
                const idLoja = loja.id || '';
                const listCaixasDaLoja = caixas
                  ?.filter((caixa) => caixa?.lojaId === idLoja)
                  ?.map((caixa) => {
                    return caixa?.listaIdNome;
                  });
                const idContaCofre = listCaixasDaLoja[0]?.[0]?.id || '';

                const hasSomenteCaixaCofre =
                  listCaixasDaLoja?.[0]?.length === 1 &&
                  listCaixasDaLoja?.[0]?.[0]?.tipoContaFinanceira ===
                    TipoContaFinanceiraEnum.CONTA_COFRE;

                return (
                  <SimpleGridForm
                    bg={hasMaisDeUmaLoja ? 'gray.50' : 'gray.100'}
                    gap="0px"
                    px={hasMaisDeUmaLoja ? '24px' : '0px'}
                    pt={hasMaisDeUmaLoja ? '24px' : '0px'}
                    pb={hasMaisDeUmaLoja ? '32px' : '0px'}
                    borderColor={hasMaisDeUmaLoja ? 'gray.200' : 'undefined'}
                    borderRadius="5px"
                    borderWidth={hasMaisDeUmaLoja ? '1px' : '0px'}
                    mb="24px"
                    spacingY="24px"
                  >
                    {hasMaisDeUmaLoja && (
                      <GridItem
                        colSpan={[12, 12, 12, 4, 6]}
                        mr={['0px', '24px']}
                      >
                        <InputPadrao
                          type="text"
                          id="nomeLoja"
                          label="Nome da loja"
                          placeholder={loja.fantasia}
                          className="custom-input-styles"
                          error={errors?.nomeLoja}
                          disabled
                          control={control}
                        />
                        <style>
                          {`.custom-input-styles::-webkit-input-placeholder {
                          color: black;

                        }
                        .form-control {
                          border: 1px solid;
                          border-color: #CCCCCC;
                        }
                      `}
                        </style>
                      </GridItem>
                    )}
                    <GridItem colSpan={[12, 8, 8, 4, 3]} mr={['0px', '24px']}>
                      <InputPadrao
                        type="text"
                        id={`stoneCode.${idLoja}`}
                        onKeyDown={(e) => {
                          if (e.code === 'Space') e.preventDefault();
                        }}
                        label="Chave Privada Stone da loja"
                        placeholder="Informe sua chave privada da sua conta Stone"
                        error={errors?.stoneCode?.[idLoja]}
                        disabled={
                          readonly ||
                          (!hasSomenteCaixaCofre && loja.naoUtilizaStoneCode)
                        }
                        control={control}
                      />
                    </GridItem>
                    <GridItem colSpan={[12, 4, 4, 4, 3]}>
                      {(lojaIsPlanoStart || hasSomenteCaixaCofre) &&
                      idContaCofre ? (
                        <InputPadrao
                          type="text"
                          id={`serialStone.${idLoja}.${idContaCofre}`}
                          label="Identificador da maquininha"
                          onKeyDown={(e) => {
                            if (e.code === 'Space') e.preventDefault();
                          }}
                          placeholder="Digite o número serial"
                          disabled={
                            readonly ||
                            (!hasSomenteCaixaCofre && loja.naoUtilizaStoneCode)
                          }
                          control={control}
                          error={errors?.serialStone?.[idLoja]?.[idContaCofre]}
                          saveAsUpperCase
                          infoText="É possível utilizar apenas uma maquininha por caixa. Para saber o número serial, ligue o dispositivo e acesse: AJUDA > DETALHES DA MÁQUINA"
                          onBlur={(e) => {
                            const { value } = e.target || '';
                            if (!value) return;
                            validarSerial(value, idContaCofre, idLoja);
                          }}
                        />
                      ) : (
                        <Flex
                          alignItems="flex-end"
                          justifyContent="flex-start"
                          h="100%"
                        >
                          <Checkbox
                            name="inutilizarLoja"
                            id="inutilizarLoja"
                            boxShadow="inset 0px 3px 6px #00000029"
                            colorScheme="primary"
                            bg="white"
                            onChange={() => {
                              toggleInutilizarLoja(loja.id);
                            }}
                            isChecked={loja.naoUtilizaStoneCode}
                          />
                          <Text
                            onClick={() => {
                              toggleInutilizarLoja(loja.id);
                            }}
                            pb="5px"
                            ml="6px"
                            cursor="pointer"
                            userSelect="none"
                          >
                            Loja não utiliza
                          </Text>
                        </Flex>
                      )}
                    </GridItem>
                    {(listCaixasDaLoja.length || []) > 0 &&
                      !loja?.naoUtilizaStoneCode &&
                      !hasSomenteCaixaCofre &&
                      !lojaIsPlanoStart && (
                        <>
                          <GridItem
                            colSpan={12}
                            mt={hasMaisDeUmaLoja ? '18px' : '28px'}
                          >
                            <Flex
                              alignItems="center"
                              gap="4px"
                              cursor="pointer"
                              w="fit-content"
                              onClick={() => {
                                toggleMostrarCaixas(loja.id);
                              }}
                            >
                              <Text
                                fontWeight="bold"
                                letterSpacing="0px"
                                userSelect="none"
                              >
                                Informe qual maquininha será utilizada em cada
                                caixa
                              </Text>
                              <Icon
                                as={
                                  loja.mostrarCaixas
                                    ? IoIosArrowUp
                                    : IoIosArrowDown
                                }
                                boxSize="12px"
                                mt="2px"
                              />
                            </Flex>
                          </GridItem>
                          {loja?.mostrarCaixas && (
                            <GridItem
                              colSpan={12}
                              borderRadius="5px"
                              borderWidth="1px"
                              borderColor="gray.500"
                              padding={['32px 16px', '32px 16px', '32px 24px']}
                            >
                              <SimpleGridForm padding="0px 24px">
                                <GridItem colSpan={[6, 6, 4]}>
                                  <Text
                                    fontSize="2xs"
                                    color="gray.500"
                                    letterSpacing="0px"
                                  >
                                    Nome do caixa
                                  </Text>
                                </GridItem>
                                <GridItem colSpan={[6, 6, 4]}>
                                  <Flex alignItems="flex-start">
                                    <Text
                                      fontSize="2xs"
                                      color="gray.500"
                                      letterSpacing="0px"
                                      position="relative"
                                    >
                                      Identificador da maquininha
                                    </Text>
                                    <Tooltip
                                      label="É possível utilizar apenas uma maquininha por caixa. Para saber o número serial, ligue o dispositivo e acesse: AJUDA > DETALHES DA MÁQUINA"
                                      closeOnClick={false}
                                      shouldWrapChildren
                                      placement="left"
                                      bg="#0F0F0F"
                                      padding="20px"
                                      letterSpacing="0px"
                                    >
                                      <Icon
                                        onClick={() => {}}
                                        as={MdInfo}
                                        boxSize="10px"
                                        ml="4px"
                                        mb="4px"
                                      />
                                    </Tooltip>
                                  </Flex>
                                </GridItem>
                              </SimpleGridForm>

                              {(listCaixasDaLoja[0] || [])
                                ?.filter(
                                  (caixa) =>
                                    caixa.tipoContaFinanceira !==
                                    TipoContaFinanceiraEnum.CONTA_COFRE
                                )
                                ?.map((caixa) => {
                                  return (
                                    <SimpleGridForm
                                      bg="white"
                                      alignItems="center"
                                      borderRadius="5px"
                                      px="24px"
                                      py="12px"
                                      boxShadow=" 0px 0px 4px #00000029"
                                      key={caixa.id}
                                      mb="4px"
                                    >
                                      <GridItem colSpan={[12, 6, 4]}>
                                        <Text
                                          color="primary.50"
                                          fontWeight="bold"
                                        >
                                          {caixa.nome}
                                        </Text>
                                      </GridItem>
                                      <GridItem
                                        colSpan={[12, 6, 4]}
                                        mb={
                                          errors?.serialStone?.[idLoja]?.[
                                            caixa.id
                                          ] &&
                                          errors?.serialStone?.[idLoja]?.[
                                            caixa.id
                                          ]?.message
                                            ? '12px'
                                            : '0px'
                                        }
                                      >
                                        <InputPadrao
                                          type="text"
                                          id={`serialStone.${idLoja}.${caixa.id}`}
                                          saveAsUpperCase
                                          onKeyDown={(e) => {
                                            if (e.code === 'Space')
                                              e.preventDefault();
                                          }}
                                          placeholder="Digite o número serial"
                                          disabled={readonly}
                                          error={
                                            errors?.serialStone?.[idLoja]?.[
                                              caixa.id
                                            ]
                                          }
                                          control={control}
                                          onBlur={(e) => {
                                            const { value } = e.target || '';
                                            if (!value) return;

                                            validarSerial(
                                              value,
                                              caixa.id,
                                              idLoja
                                            );
                                          }}
                                        />
                                      </GridItem>
                                    </SimpleGridForm>
                                  );
                                })}
                            </GridItem>
                          )}
                        </>
                      )}
                  </SimpleGridForm>
                );
              })}
            </Box>
          </Flex>
        </>
      )}

      <Form.Row>
        <Col xl={0}>
          <AccordionPadrao
            title="Informações fiscais para operações com cartão"
            id="informacoesFiscais"
          >
            <Form.Row>
              <Col sm={6} xs={12}>
                <SelectPadrao
                  id="IntegracaoPagamento"
                  name="IntegracaoPagamento"
                  label={t('Tipo de integração de recebimento')}
                  noSelectedText={
                    meioPagamento !== 1
                      ? t('Clique aqui para selecionar.')
                      : t('Não é preciso informar')
                  }
                  placeholder={t('Selecione')}
                  defaultValue={undefined}
                  readonly={readonly || meioPagamento === 1}
                  control={control}
                  error={errors.IntegracaoPagamento}
                  options={Object.entries(
                    TipoIntegracaoPagamentoEnum.properties
                  ).map((obj: any) => {
                    return {
                      label: obj[1].name,
                      value: obj[1].value,
                    };
                  })}
                />
              </Col>
              <Col sm={6} xs={12}>
                <SelectPadrao
                  id="credenciadoraCartaoId"
                  name="credenciadoraCartaoId"
                  placeholder={t('Selecionar')}
                  label={t('Credenciadora de cartão')}
                  defaultValue={null}
                  readonly={readonly}
                  control={control}
                  error={errors.credenciadoraCartaoId}
                  options={credenciadorasCartao}
                />
              </Col>
            </Form.Row>
          </AccordionPadrao>
        </Col>
      </Form.Row>
      {possuiServicoFrenteCaixa && (
        <Form.Row>
          <Col xl={0}>
            <AccordionPadrao
              title="Configurações para utilizar no frente de caixa"
              id="informacoesFiscais"
            >
              <Form.Row>
                <Col sm={12}>
                  <Flex
                    flexDir={['column', 'column', 'row']}
                    gap="32px"
                    w="full"
                  >
                    <Flex align="flex-end" gap="14px">
                      <Checkbox
                        boxShadow="inset 0px 3px 6px #00000029"
                        colorScheme="primary"
                        bg="white"
                        mb="4px"
                        isChecked={watch('chefMobile')}
                        {...register('chefMobile')}
                        id="chefMobile"
                      />
                      <FormLabel
                        mb="0"
                        fontSize="sm"
                        color="black"
                        cursor="pointer"
                        userSelect="none"
                        htmlFor="chefMobile"
                      >
                        Utilizar no aplicativo de comanda de garçom
                      </FormLabel>
                    </Flex>
                    <Flex align="flex-end" gap="14px">
                      <Checkbox
                        boxShadow="inset 0px 3px 6px #00000029"
                        colorScheme="primary"
                        bg="white"
                        mb="4px"
                        isChecked={watch('autoAtendimento')}
                        {...register('autoAtendimento')}
                        id="autoAtendimento"
                      />
                      <FormLabel
                        mb="0"
                        fontSize="sm"
                        color="black"
                        userSelect="none"
                        cursor="pointer"
                        htmlFor="autoAtendimento"
                      >
                        Utilizar no aplicativo de autoatendimento
                      </FormLabel>
                    </Flex>
                    <Flex align="flex-end" gap="14px">
                      <Checkbox
                        boxShadow="inset 0px 3px 6px #00000029"
                        colorScheme="primary"
                        bg="white"
                        mb="4px"
                        isChecked={watch('documentoFiscalPreferencial')}
                        {...register('documentoFiscalPreferencial')}
                        id="documentoFiscalPreferencial"
                      />
                      <Flex align="center">
                        <FormLabel
                          mb="0"
                          fontSize="sm"
                          color="black"
                          userSelect="none"
                          cursor="pointer"
                          htmlFor="autoAtendimento"
                        >
                          Obrigatoriedade de cupom fiscal
                        </FormLabel>
                        <InfoTooltip
                          label="Se a opção estiver marcada será gerado automaticamente um cupom fiscal, caso contrário terá a opção de gerar uma NFe"
                          placement="auto"
                        />
                      </Flex>
                    </Flex>
                  </Flex>
                </Col>
              </Form.Row>
            </AccordionPadrao>
          </Col>
        </Form.Row>
      )}

      <Form.Row>
        <Col lg={12} style={{ paddingBottom: 0 }}>
          <span
            style={{
              color: 'var(--black)',
              padding: '0',
              fontWeight: 600,
              fontSize: '13px',
              float: 'left',
            }}
          >
            Parcelamentos
          </span>
          {!formaNaoPossuiParcelamento && (
            <Button
              id="adicionar-parcelamento"
              variant="link"
              onClick={() => {
                setIndexItemAlteracao(-1);
                setParcelamentoModalIsOpen(true);
              }}
              disabled={userCanAddInstallmentsForPayment}
              style={{
                color: `${blue500}`,
                padding: '0',
                fontWeight: 600,
                fontSize: '13px',
                float: 'right',
              }}
            >
              Adicionar parcelamento
            </Button>
          )}
        </Col>

        <Col lg={12} style={{ paddingTop: 0 }}>
          <TableCampoFormularioPadrao
            fieldName="parcelas"
            defaultColumnOrder=""
            headerColumns={[
              {
                name: 'descricao',
                displayName: 'Descrição',
                isOrderable: false,
                width: 'auto',
              },
              {
                name: 'qtParcelas',
                displayName: 'Quant. parcelas',
                isOrderable: false,
                width: 'auto',
              },
              {
                name: 'intervaloDias',
                displayName: 'Intervalo de dias',
                isOrderable: false,
                width: 'auto',
              },
              {
                name: 'taxaDescontoPercent',
                displayName: 'Taxa de transação (%)',
                isOrderable: false,
                width: 'auto',
              },
              {
                name: 'taxaDescontoValor',
                displayName: 'Taxa de transação (R$)',
                isOrderable: false,
                width: 'auto',
              },
              {
                name: 'acrescimoPercent',
                displayName: 'Acréscimo (%)',
                isOrderable: false,
                width: 'auto',
              },
              {
                name: 'acrescimoValor',
                displayName: 'Acréscimo (R$)',
                isOrderable: false,
                width: 'auto',
              },
              {
                name: 'acoes',
                displayName: 'Ações',
                isOrderable: false,
                width: '38px',
              },
            ]}
            emptyValuesText={t('Nenhum parcelamento cadastrado')}
            control={control}
            isLoading={false}
          >
            {({ tableValues }) => (
              <>
                {tableValues &&
                  tableValues.length > 0 &&
                  tableValues.map((tableValue, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <tr key={index}>
                      <td>{tableValue.descricao}</td>
                      <td>{tableValue.qtdeParcela}</td>
                      <td>{tableValue.intervaloDias}</td>
                      <td>
                        {moneyMask(
                          tableValue.taxaTransacaoPorcentagem.toFixed(2),
                          false
                        )}
                      </td>
                      <td>{moneyMask(tableValue.taxaTransacaoValor, false)}</td>
                      <td>
                        {moneyMask(
                          tableValue.acrescimoPorcentagem.toFixed(2),
                          false
                        )}
                      </td>
                      <td>{moneyMask(tableValue.acrescimoValor, false)}</td>
                      <td>
                        {!readonly && (
                          <ButtonComOpcoes
                            id="mostrarMais"
                            dropdownItems={[
                              {
                                title: t('Editar'),

                                onClick: () => {
                                  setIndexItemAlteracao(index);
                                  setParcelamentoModalIsOpen(true);
                                },
                              },
                              {
                                title: t('Remover'),
                                disabled: readonly,
                                onClick: () =>
                                  removerItemList(index, 'parcelas'),
                              },
                            ]}
                          />
                        )}
                        {readonly && (
                          <ButtonComOpcoes
                            id="mostrarMais"
                            dropdownItems={[
                              {
                                title: t('Visualizar'),
                                onClick: () => {
                                  setIndexItemAlteracao(index);
                                  setParcelamentoModalIsOpen(true);
                                },
                              },
                            ]}
                          />
                        )}
                      </td>
                    </tr>
                  ))}
              </>
            )}
          </TableCampoFormularioPadrao>
        </Col>
      </Form.Row>
      <ParcelamentoModal
        show={ParcelamentoModalIsOpen}
        onHide={() => {
          setParcelamentoModalIsOpen(false);
        }}
        register={register}
        control={control}
        readonly={readonly}
        getValues={getValues}
        isCadastrar={isCadastrar}
        formaPagamentoRecebimentoId={idRota}
        setValues={setValue}
        indexItem={indexItemAlteracao}
      />
    </>
  );
};

export default UncontrolledForm;
