import { Route } from 'react-router-dom';

import ConstanteRotas from 'constants/rotas';
import { PdvAutonomoProvider } from 'store/PdvAutonomo';

import { PdvAutonomo } from 'pages/Integracoes/LojaAplicativos/PdvAutonomo/PainelAdministrador';
import { PdvOfflineComercial } from 'pages/Integracoes/LojaAplicativos/PdvAutonomo';
import { DetalhesIntegracaoPdvOffline } from 'pages/Integracoes/LojaAplicativos/PdvAutonomo/DetalhesIntegracao';

import LayoutGuard from './LayoutGuard';

export const PdvAutonomoRoutes = [
  <Route path="/integracao-pdv" key="pdv-autonomo">
    <PdvAutonomoProvider>
      <LayoutGuard
        key="pdv-autonomo"
        component={PdvAutonomo}
        breadcrumb={[
          {
            title: 'Integrações',
          },
          {
            title: 'PDV Offline',
          },
        ]}
        path={ConstanteRotas.PDV_OFFLINE}
        exact
        meta={{ auth: true }}
      />
    </PdvAutonomoProvider>
    <LayoutGuard
      key="pdv-autonomo"
      component={PdvOfflineComercial}
      breadcrumb={[
        {
          title: 'Integrações',
        },
        {
          title: 'Loja de aplicativos',
          path: ConstanteRotas.LOJA_APLICATIVOS,
        },
        {
          title: 'PDV Offline',
        },
      ]}
      path={ConstanteRotas.PDV_OFFLINE_TELA_COMERCIAL}
      exact
      meta={{ auth: true }}
    />
    <LayoutGuard
      key="pdv-autonomo"
      component={DetalhesIntegracaoPdvOffline}
      breadcrumb={[
        {
          title: 'Integrações',
        },
        {
          title: 'Loja de aplicativos',
          path: ConstanteRotas.LOJA_APLICATIVOS,
        },
        {
          title: 'PDV Offline',
        },
      ]}
      path={ConstanteRotas.PDV_OFFLINE_DETALHES}
      exact
      meta={{ auth: true }}
    />
  </Route>,
];
