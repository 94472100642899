import { useReactToPrint } from 'react-to-print';
import React, {
  createContext,
  ReactNode,
  useContext,
  useMemo,
  useState,
  useRef,
  useCallback,
  useEffect,
} from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { useMediaQuery, Tooltip, Text, Box } from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';

import { useInformacoesGeraisContext } from 'store/PDV/InformacoesGerais';
import TipoContaFinanceiraEnum from 'constants/enum/tipoContaFinanceira';
import TipoTabelaPrecoEnum from 'constants/enum/tipoTabelaPrecoEnum';
import ConstanteFuncionalidades from 'constants/permissoes';
import auth from 'modules/auth';
import IdentificacaoTipoOperacaoEnum, {
  IdentificacaoTipoOperacao,
} from 'constants/enum/identificacaoTipoOperacao';
import ConstanteRotasPDV from 'constants/rotasPDV';
import { useOperacaoContext } from 'store/PDV/Operacao';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import api, { ResponseApi } from 'services/api';
import LogAuditoriaTelaEnum from 'constants/enum/logAuditoriaTela';
import { ImprimirPDF } from 'helpers/impressoes/imprimirPDF';

import { ModalConfirmacaoExcluir } from 'components/Modal/ModalConfirmacaoExcluir';
import { EmailModal } from 'components/PDV/CompartilharDocumentosFiscais/EmailModal';
import { ModalCompartilhar } from 'components/Modal/ModalCompartilhar';
import ImpressaoCupomFechamentoCaixa, {
  ImpressaoCupomFechamentoCaixaRefElement,
} from 'components/Impressao/impressaoFechamentoCaixa';
import {
  OpcoesIcon,
  PagamentosValePresenteIcon,
  PedidosIcon,
  TabelaPrecosIcon,
  TrocasIcon,
  ConsignacaoIcon,
} from 'icons';
import NavHeaderButton from 'components/PDV/Layout/NavHeader/Button';
import NavHeaderMenu from 'components/PDV/Layout/NavHeader/Menu';
import ModalListarVariacoesPdv from 'components/Modal/ModalListarSaldoVariacaoPdv';
import TabelaPrecosMenu from 'components/PDV/Layout/NavHeader/TabelaPrecosMenu';
import PedidosAbertos from 'components/PDV/Layout/NavHeader/PedidosAbertos';
import { ModalSuprimentoSangria } from 'components/PDV/Modal/ModalSuprimentoSangria';
import { ModalFechamentoCaixa } from 'components/update/Modal/ModalFechamentoCaixa';
import PlanoContratacaoEnum from 'constants/enum/planoContratacao';
import { ModalSelecionarLocalEstoque } from 'components/PDV/Modal/ModalSelecionarLocalEstoque';
import { ModalConfirmacao } from 'components/Modal/ModalConfirmacao';
import handleSairDevolucao from './SairDevolucao';

interface TabelaDePrecosProps {
  id: string;
  nome: string;
  padraoSistema: boolean;
}

interface MenuContextProps {
  menuItems: ReactNode[];
  atualizarPrecoProdutos: (tabelaPrecoId: string) => Promise<void>;
  tabelasPreco: TabelaDePrecosProps[];
  setChavePermissaoTemporaria: React.Dispatch<React.SetStateAction<string>>;
  setModalAuthIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  modalAuthIsOpen: boolean;
  chavePermissaoTemporaria: string;
}

const MenuContext = createContext<MenuContextProps>({} as MenuContextProps);

interface MenuContextProviderProps {
  children: React.ReactNode;
  renderDefaultItems?: boolean;
  items?: ReactNode[];
  alterarItensQueSeraoMostrados?: boolean;
  asAccordion?: boolean;
}

export default function MenuContextProvider({
  children,
  renderDefaultItems = true,
  items,
  asAccordion,
  alterarItensQueSeraoMostrados,
}: MenuContextProviderProps): JSX.Element {
  const history = useHistory();
  const idRota = useParams<{ id: string }>();

  const planoContratado = useMemo(() => auth.getPlano(), []);

  const [isSmalerThan1200] = useMediaQuery('(max-width: 1200px)');
  const [isSmalerThan900] = useMediaQuery('(max-width: 900px)');
  const [tabelasPreco, setTabelasPreco] = useState<TabelaDePrecosProps[]>([]);

  const {
    handleChangeTipoOperacao,
    handleChangeLocalEstoque,
    operacaoId,
  } = useOperacaoContext();

  const {
    contaFinanceira,
    possuiCadastroOutrosCaixas,
    detalhesTroca,
    setInformacoesTabelaPreco,
    localDeEstoqueSelecionado,
    locaisDeEstoque,
    operacaoItemlength,
    setTabelaPrecoId,
    handleSetInformacoesLocalEstoque,
  } = useInformacoesGeraisContext();

  const [
    chavePermissaoTemporaria,
    setChavePermissaoTemporaria,
  ] = useState<string>('');
  const [modalAuthIsOpen, setModalAuthIsOpen] = useState<boolean>(false);

  const {
    watch,
    setValue,
    control,
    getValues,
    handleSubmit,
  } = useFormContext();
  const possuiMovimentacaoFinanceira = watch('possuiMovimentacaoFinanceira');
  const cliente = watch('cliente');
  const identificacaoTipoOperacao = watch('identificacaoTipoOperacao');
  const tabelaPrecoNome = watch('tabelaPreco');
  const openOperacoes = useCallback(() => {
    history.push(ConstanteRotasPDV.PDV_CONSULTAR_OPERACOES);
  }, [history]);

  useEffect(() => {
    setInformacoesTabelaPreco(tabelaPrecoNome);
  }, [setInformacoesTabelaPreco, tabelaPrecoNome]);

  const openNovoLancamento = useCallback(() => {
    history.push(ConstanteRotasPDV.PDV_HOME);
  }, [history]);

  const permissaoCaixaFechar = auth.possuiPermissao(
    ConstanteFuncionalidades.CONTROLE_CAIXA_ABRIR_FECHAR
  );

  const permissaoTrocarProduto = auth.possuiPermissao(
    ConstanteFuncionalidades.PDV_LANCAR_TROCA_DEVOLUCAO
  ).permitido;

  const permissaoProdutoConsignado = auth.possuiPermissao(
    ConstanteFuncionalidades.PDV_BAIXAR_CONSIGNACAO
  ).permitido;

  const possuiPermissaoSuprimento = auth.possuiPermissao(
    ConstanteFuncionalidades.PDV_SUPRIMENTO
  ).permitido;

  const possuiPermissaoSangria = auth.possuiPermissao(
    ConstanteFuncionalidades.PDV_SANGRIA
  ).permitido;

  const possuiPermissaoRecebimentoConta = auth.possuiPermissao(
    ConstanteFuncionalidades.RECEBIMENTO_CONTAS_LISTAR
  ).permitido;

  const possuiPermissaoParaAlterarLocalDeEstoque = auth.possuiPermissao(
    ConstanteFuncionalidades.PDV_ALTERAR_LOCAL_ESTOQUE
  ).permitido;

  const possuiPermissaoParaAlterarTabelaPreco = auth.possuiPermissao(
    ConstanteFuncionalidades.TABELA_PRECO_ALTERAR_PDV
  ).permitido;

  const bobinaContainerRef = useRef<HTMLDivElement>(null);
  const bobinaComponentToPrintRef = useRef<ImpressaoCupomFechamentoCaixaRefElement>(
    null
  );

  const imprimirRelatorioBobina = useReactToPrint({
    content: () => bobinaContainerRef.current,
  });

  const plano = auth.getPlano();

  const handleEnvioEmail = useCallback(async (idCaixaMovimentacao: string) => {
    const { sucesso } = await ModalConfirmacaoExcluir({
      title: 'Deseja incluir todas as movimentações do caixa?',
      text: ' ',
      cancelButtonText: 'Não',
      confirmButtonText: 'Sim, incluir',
    });

    EmailModal({
      caixaMovimentacao: {
        caixaMovimentacaoId: idCaixaMovimentacao,
        listarMovimentacoes: sucesso,
      },
    });
  }, []);

  const handleCompartilharRelatorioA4 = useCallback(
    async (idCaixaMovimentacao: string) => {
      const { sucesso } = await ModalConfirmacaoExcluir({
        title: 'Deseja imprimir todas as movimentações do caixa?',
        text: ' ',
        cancelButtonText: 'Não ',
        confirmButtonText: 'Sim, imprimir',
      });
      const response = await api.get<void, ResponseApi<string>>(
        ConstanteEnderecoWebservice.FECHAMENTO_CAIXA_IMPRESSAO_A4,
        {
          params: {
            caixaMovimentacaoId: idCaixaMovimentacao,
            listarMovimentacoes: sucesso,
          },
        }
      );

      if (response) {
        if (response.avisos) {
          response.avisos.forEach((item) => toast.warning(item));
        }
        if (response.sucesso && response.dados) {
          ImprimirPDF(response.dados, 'relatorioControleCaixa');
        }
      }
    },
    []
  );

  const handleCompartilharImpressao = useCallback(
    async (idCaixaMovimentacao: string) => {
      ModalCompartilhar({
        items: [
          {
            titulo: 'Imprimir A4',
            onClickEmail: () => {
              handleEnvioEmail(idCaixaMovimentacao);
            },
            onClickImpressao: () => {
              handleCompartilharRelatorioA4(idCaixaMovimentacao);
            },
          },
          {
            titulo: 'Imprimir bobina',
            onClickImpressao: async () => {
              await bobinaComponentToPrintRef.current?.obterInformacoesImpressao();

              if (imprimirRelatorioBobina) {
                imprimirRelatorioBobina();
              }
            },
          },
        ],
        componenteParaImpressao: () => {
          return (
            <Box display="none">
              <ImpressaoCupomFechamentoCaixa
                ref={bobinaComponentToPrintRef}
                containerRef={bobinaContainerRef}
                caixaMovimentacaoId={contaFinanceira?.caixaMovimentacao?.id}
              />
            </Box>
          );
        },
      });
    },
    [
      contaFinanceira?.caixaMovimentacao?.id,
      handleCompartilharRelatorioA4,
      handleEnvioEmail,
      imprimirRelatorioBobina,
    ]
  );

  const handleFecharCaixa = useCallback(async () => {
    if (contaFinanceira) {
      const { sucesso } = await ModalFechamentoCaixa({
        casasDecimaisValor: 2,
        idCaixaMovimentacao: contaFinanceira?.caixaMovimentacao?.id,
        tela: LogAuditoriaTelaEnum.PDV,
      });

      if (sucesso) {
        await handleCompartilharImpressao(
          contaFinanceira?.caixaMovimentacao.id
        );
        history.replace('/');
        history.replace(ConstanteRotasPDV.PDV_HOME);
      }
    }
  }, [contaFinanceira, handleCompartilharImpressao, history]);

  const handleProdutosConsignacao = handleSubmit(() => {
    history.push(ConstanteRotasPDV.PDV_PRODUTOS_CONSIGNADOS);
  });

  const openRecebimentoContas = useCallback(() => {
    history.push(ConstanteRotasPDV.PDV_RECEBIMENTO_CONTAS);
  }, [history]);

  const latestProps = useRef({ getValues, setValue, handleChangeTipoOperacao });
  useEffect(() => {
    latestProps.current = { getValues, setValue, handleChangeTipoOperacao };
  });

  const handleAlterarTipoOperacao = useCallback(
    (tipoOperacao: number) => {
      if (idRota.id) {
        if (latestProps.current.handleChangeTipoOperacao) {
          latestProps.current.handleChangeTipoOperacao(tipoOperacao);
        }
      } else {
        latestProps.current.setValue('identificacaoTipoOperacao', tipoOperacao);
      }
    },
    [idRota]
  );

  const atualizarPrecoProdutos = useCallback(
    async (tabelaPrecoId: string) => {
      if (operacaoId) {
        const response = await api.patch<void, ResponseApi>(
          `${ConstanteEnderecoWebservice.PEDIDOORCAMENTOVENDA_ALTERAR_OPERACAO_TABELA_PRECO.replace(
            'id',
            operacaoId
          )}/${tabelaPrecoId}`
        );

        if (response) {
          if (response?.avisos) {
            response.avisos.map((item: string) => toast.warning(item));
          }
          if (response.sucesso && operacaoId) {
            if (possuiMovimentacaoFinanceira) {
              return;
            }

            setTabelaPrecoId(tabelaPrecoId);
          }
        }
      } else if (setTabelaPrecoId) {
        setTabelaPrecoId(tabelaPrecoId);
      }
    },
    [operacaoId, possuiMovimentacaoFinanceira, setTabelaPrecoId]
  );

  const onChangeLocalEstoque = useCallback(async () => {
    if (
      !possuiPermissaoParaAlterarLocalDeEstoque &&
      !chavePermissaoTemporaria
    ) {
      setModalAuthIsOpen(true);
    } else {
      await ModalSelecionarLocalEstoque({
        currentLocalEstoqueId: localDeEstoqueSelecionado.id,
        listarEstoques: locaisDeEstoque,
        onSubmit: async (newLocalEstoque) => {
          if (operacaoId) {
            await handleChangeLocalEstoque(newLocalEstoque.id);
          }
          handleSetInformacoesLocalEstoque(newLocalEstoque);
        },
      });

      setChavePermissaoTemporaria('');
    }
  }, [
    chavePermissaoTemporaria,
    handleChangeLocalEstoque,
    handleSetInformacoesLocalEstoque,
    locaisDeEstoque,
    localDeEstoqueSelecionado.id,
    operacaoId,
    possuiPermissaoParaAlterarLocalDeEstoque,
  ]);

  const handleTrocarProduto = handleSubmit(() => {
    history.push(ConstanteRotasPDV.PDV_TROCAR_PRODUTOS);
  });

  const ValorTrocaMobile = () => {
    if (isSmalerThan900 && detalhesTroca.valorTotalTroca > 0) {
      const validarValor = detalhesTroca.valorTotalTroca.toLocaleString(
        'pt-BR',
        {
          style: 'currency',
          currency: 'BRL',
        }
      );
      return (
        <NavHeaderButton
          icon={PagamentosValePresenteIcon}
          title={validarValor}
          variant="unstyled"
          titleColor="red.200"
          style={{
            marginLeft: !isSmalerThan1200 ? '37px' : '0px',
          }}
        />
      );
    }

    return null;
  };

  useEffect(() => {
    async function validatePermissonUser() {
      if (chavePermissaoTemporaria) await onChangeLocalEstoque();
    }
    validatePermissonUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chavePermissaoTemporaria]);

  async function handleChangeTabelaPreco() {
    const { success } = await ModalConfirmacao({
      title: 'Deseja continuar?',
      cancelButtonText: 'Cancelar',
      text: (
        <Text>
          O preço de todos os itens será recalculado de acordo com as regras e
          exceções da tabela de preço selecionada,
          <Text as="span" fontWeight="bold">
            {' '}
            inclusive para os produtos que alteram valor na venda.
          </Text>
        </Text>
      ),
      confirmButtonText: 'Sim, continuar',
    });

    return success;
  }

  const menuItems = useMemo(() => {
    let newMenuItems: React.ReactNode[];
    if (alterarItensQueSeraoMostrados) {
      newMenuItems = [...(items || [])];
    } else {
      newMenuItems = [
        ...(renderDefaultItems
          ? [
              <Tooltip
                hasArrow
                shouldWrapChildren
                label="Esta funcionalidade não está disponível neste plano, faça um upgrade"
                tabIndex={-1}
                placement="auto"
                isDisabled={planoContratado !== PlanoContratacaoEnum.START}
              >
                <NavHeaderMenu
                  disabled={
                    planoContratado === PlanoContratacaoEnum.START ||
                    detalhesTroca.idOperacao !== ''
                  }
                  icon={PedidosIcon}
                  title="Tipo Operação"
                  items={[
                    IdentificacaoTipoOperacaoEnum.properties[
                      IdentificacaoTipoOperacaoEnum.PEDIDO as IdentificacaoTipoOperacao
                    ],
                    IdentificacaoTipoOperacaoEnum.properties[
                      IdentificacaoTipoOperacaoEnum.ORCAMENTO as IdentificacaoTipoOperacao
                    ],
                    {
                      value:
                        IdentificacaoTipoOperacaoEnum.properties[
                          IdentificacaoTipoOperacaoEnum.CONSIGNACAO as IdentificacaoTipoOperacao
                        ].value,
                      name:
                        IdentificacaoTipoOperacaoEnum.properties[
                          IdentificacaoTipoOperacaoEnum.CONSIGNACAO as IdentificacaoTipoOperacao
                        ].name,
                      iconForMenuButton: ConsignacaoIcon,
                      isDisabled:
                        possuiMovimentacaoFinanceira || cliente?.padraoSistema,
                    },
                  ]}
                  value={identificacaoTipoOperacao}
                  setValue={(item: any) =>
                    handleAlterarTipoOperacao(parseFloat(item))
                  }
                  asAccordion={asAccordion}
                />
              </Tooltip>,
              <Controller
                name="tabelaPreco"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TabelaPrecosMenu
                    disabled={
                      planoContratado === PlanoContratacaoEnum.START ||
                      !possuiPermissaoParaAlterarTabelaPreco
                    }
                    minW="150px"
                    icon={TabelaPrecosIcon}
                    title="Tabela de Preços"
                    subtitle={tabelaPrecoNome?.name}
                    fontSize="xs"
                    style={{ marginLeft: !isSmalerThan1200 ? '37px' : '0px' }}
                    items={
                      tabelasPreco &&
                      tabelasPreco.map(({ id, nome }) => {
                        return {
                          value: id,
                          name: nome,
                        };
                      })
                    }
                    value={value?.value}
                    setValue={async (newValue: any) => {
                      const newTabelaPreco = tabelasPreco.find(
                        (t) => t.id === newValue
                      );
                      if (value.value !== newTabelaPreco?.id) {
                        const success = await handleChangeTabelaPreco();
                        if (newTabelaPreco && success) {
                          onChange({
                            value: newTabelaPreco.id,
                            name: newTabelaPreco.nome,
                          });
                          await atualizarPrecoProdutos(newTabelaPreco.id);
                        } else {
                          onChange(null);
                        }
                      }
                    }}
                    asAccordion={asAccordion}
                  />
                )}
              />,
              <NavHeaderButton
                icon={TrocasIcon}
                title="Trocas"
                variant="unstyled"
                onClick={() =>
                  handleSairDevolucao({
                    handleOperacao: handleTrocarProduto,
                    detalhesTroca,
                  })
                }
                isDisabled={!permissaoTrocarProduto || !cliente}
                style={{ marginLeft: !isSmalerThan1200 ? '37px' : '0px' }}
              />,

              <PedidosAbertos asAccordion={asAccordion} />,
              <NavHeaderMenu
                icon={OpcoesIcon}
                title="Opções"
                items={[
                  {
                    value: 0,
                    name: 'Novo lançamento',
                    onClick: () => {
                      handleSairDevolucao({
                        handleOperacao: openNovoLancamento,
                        detalhesTroca,
                      });
                    },
                  },
                  {
                    value: 1,
                    name: 'Consultar operações',
                    onClick: () => {
                      handleSairDevolucao({
                        handleOperacao: openOperacoes,
                        detalhesTroca,
                      });
                    },
                  },
                  {
                    value: 2,
                    name: 'Sangria',
                    isDisabled:
                      !possuiPermissaoSangria ||
                      (contaFinanceira?.tipoContaFinanceira ===
                        TipoContaFinanceiraEnum.CONTA_COFRE &&
                        possuiCadastroOutrosCaixas),
                    onClick: () => {
                      ModalSuprimentoSangria({
                        identificacaoTipoOperacaoEnum:
                          IdentificacaoTipoOperacaoEnum.TRANSFERENCIA_DINHEIRO_SAIDA,
                        contaFinanceira,
                      });
                    },
                  },
                  {
                    value: 3,
                    name: 'Suprimento',
                    isDisabled:
                      !possuiPermissaoSuprimento ||
                      (contaFinanceira?.tipoContaFinanceira ===
                        TipoContaFinanceiraEnum.CONTA_COFRE &&
                        possuiCadastroOutrosCaixas),
                    onClick: () => {
                      ModalSuprimentoSangria({
                        identificacaoTipoOperacaoEnum:
                          IdentificacaoTipoOperacaoEnum.TRANSFERENCIA_DINHEIRO_ENTRADA,
                        contaFinanceira,
                      });
                    },
                  },
                  {
                    value: 4,
                    name: 'Fechar caixa',
                    isDisabled:
                      !permissaoCaixaFechar.permitido ||
                      contaFinanceira?.tipoContaFinanceira ===
                        TipoContaFinanceiraEnum.CONTA_COFRE ||
                      !possuiCadastroOutrosCaixas,
                    onClick: () => {
                      handleFecharCaixa();
                    },
                  },
                  {
                    value: 5,
                    name: 'Recebimento de contas',
                    onClick: () => {
                      handleSairDevolucao({
                        handleOperacao: openRecebimentoContas,
                        detalhesTroca,
                      });
                    },
                    isDisabled:
                      !possuiPermissaoRecebimentoConta ||
                      (contaFinanceira?.tipoContaFinanceira ===
                        TipoContaFinanceiraEnum.CONTA_COFRE &&
                        possuiCadastroOutrosCaixas),
                  },
                  {
                    value: 6,
                    name: 'Consultar estoque',
                    onClick: () => {
                      ModalListarVariacoesPdv();
                    },
                    isDisabled:
                      plano === PlanoContratacaoEnum.START ||
                      plano === PlanoContratacaoEnum.PRO,
                  },
                  {
                    value: 7,
                    name: 'Local de estoque',
                    onClick: () => {
                      onChangeLocalEstoque();
                    },
                    isDisabled:
                      !!operacaoItemlength ||
                      plano === PlanoContratacaoEnum.START ||
                      plano === PlanoContratacaoEnum.PRO,
                  },
                  {
                    value: 8,
                    name: 'Baixar consignação',
                    onClick: () => {
                      handleSairDevolucao({
                        handleOperacao: handleProdutosConsignacao,
                        detalhesTroca,
                      });
                    },

                    isDisabled: !permissaoProdutoConsignado || !cliente,
                  },
                ]}
                asAccordion={asAccordion}
              />,
              <ValorTrocaMobile />,
            ]
          : []),
        ...(items || []),
      ];
    }

    return newMenuItems;
  }, [
    alterarItensQueSeraoMostrados,
    items,
    renderDefaultItems,
    planoContratado,
    detalhesTroca,
    possuiMovimentacaoFinanceira,
    cliente,
    identificacaoTipoOperacao,
    asAccordion,
    control,
    permissaoTrocarProduto,
    isSmalerThan1200,
    possuiPermissaoSangria,
    contaFinanceira,
    possuiCadastroOutrosCaixas,
    possuiPermissaoSuprimento,
    permissaoCaixaFechar.permitido,
    possuiPermissaoRecebimentoConta,
    plano,
    operacaoItemlength,
    permissaoProdutoConsignado,
    handleAlterarTipoOperacao,
    possuiPermissaoParaAlterarTabelaPreco,
    tabelaPrecoNome?.name,
    tabelasPreco,
    atualizarPrecoProdutos,
    handleTrocarProduto,
    openNovoLancamento,
    openOperacoes,
    handleFecharCaixa,
    openRecebimentoContas,
    onChangeLocalEstoque,
    handleProdutosConsignacao,
  ]);

  useEffect(() => {
    async function listarTabelasDePreco() {
      const response = await api.get<void, ResponseApi<TabelaDePrecosProps[]>>(
        `${ConstanteEnderecoWebservice.TABELA_PRECO_LISTAR_TABELAS_PRECO}?tipo=${TipoTabelaPrecoEnum.COMUM}`
      );

      if (response) {
        if (response?.avisos) {
          response.avisos.map((item: string) => toast.warning(item));
        }

        if (response.sucesso && response.dados) {
          setTabelasPreco(response.dados);

          if (!latestProps.current.getValues('tabelaPreco') && !operacaoId) {
            const tabelaPrecoPadraoSistema = response.dados.filter(
              (tabelaPreco) => {
                return tabelaPreco.padraoSistema;
              }
            );

            if (
              tabelaPrecoPadraoSistema &&
              tabelaPrecoPadraoSistema.length > 0
            ) {
              latestProps.current.setValue('tabelaPreco', {
                value: tabelaPrecoPadraoSistema[0].id,
                name: tabelaPrecoPadraoSistema[0].nome,
              });

              setTabelaPrecoId(tabelaPrecoPadraoSistema[0].id);
            }
          }
        }
      }
    }
    listarTabelasDePreco();
  }, [operacaoId, setTabelaPrecoId]);

  return (
    <MenuContext.Provider
      value={{
        menuItems,
        atualizarPrecoProdutos,
        tabelasPreco,
        modalAuthIsOpen,
        setChavePermissaoTemporaria,
        setModalAuthIsOpen,
        chavePermissaoTemporaria,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
}

export function useMenuContext(): MenuContextProps {
  const context = useContext(MenuContext);

  if (!context)
    throw new Error(
      'useMenuContext must be used within a MenuContextProvider.'
    );

  return context;
}
