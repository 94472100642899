import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { SimpleCard } from 'components/update/Form/SimpleCard';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';

import { FormularioProps } from './types';
import { NameAndStatus } from './components/Forms/NameAndStatus';
import { Regras } from './components/Forms/Regras';
import { ListPrecosVariacao } from './components/Lists/ListPrecosVariacao';
import { ListPrecosFixos } from './components/Lists/ListPrecosFixos';
import { SelecionarLojas } from './components/SelecionarLojas';

const Formulario = ({ isReadonly = false, isAlterar }: FormularioProps) => {
  const { watch, setValue } = useFormContext();

  const padronizarPreco = watch('padronizarPreco');
  const padraoSistema = watch('padraoSistema');

  useEffect(() => {
    if (!padronizarPreco) {
      setValue('arredondamentoAcima', 0);
      setValue('arredondamentoAbaixo', 0);
    }
  }, [padronizarPreco, setValue]);

  return (
    <SimpleCard>
      <SimpleGridForm columns={1}>
        <NameAndStatus isReadOnly={isReadonly || padraoSistema} />
        <Regras isReadOnly={isReadonly || padraoSistema} />
        <ListPrecosFixos isReadOnly={isReadonly} isAlterar={!!isAlterar} />
        <ListPrecosVariacao isReadOnly={isReadonly} isAlterar={!!isAlterar} />
        <SelecionarLojas
          label="Lojas habilitadas para esta tabela de preços"
          modalSubtitle="Marque apenas as lojas em que esta tabela de preços será aplicada"
          isReadonly={isReadonly || padraoSistema}
        />
      </SimpleGridForm>
    </SimpleCard>
  );
};

export default Formulario;
