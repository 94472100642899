import React, { useState, useEffect, useCallback } from 'react';
import { Box, VStack, SimpleGrid, HStack, Button } from '@chakra-ui/react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import useIsMountedRef from 'helpers/layout/useIsMountedRef';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import ConstanteRotas from 'constants/rotas';
import { usePadronizacaoContext } from 'store/Padronizacao/Padronizacao';

import ManterFoco from 'components/Geral/ManterFoco';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import { CompartilharOperacao } from 'components/Modal/ModalCompartilhar/CompartilharOperacao';

import Header from './Header';
import DescricaoItens from './DescricaoItens';
import FormaPagamento from './FormaPagamento';
import Acrescimos from './Acrescimos';
import Descontos from './Descontos';

export type VendasDetalhesTParams = { id: string };

type DetalhesProps = RouteComponentProps<VendasDetalhesTParams> & {
  operacaoId?: string;
  IsCadastroExterno?: boolean;
};

type Item = {
  quantidade: number;
};

const Detalhes = ({
  operacaoId: propsOperacaoId,
  IsCadastroExterno = false,
  match,
}: DetalhesProps) => {
  const operacaoId = propsOperacaoId || match?.params.id;

  const { casasDecimais } = usePadronizacaoContext();
  const history = useHistory();
  const isMountedRef = useIsMountedRef();

  const [operacao, setOperacao] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);

  const handleCompartilharOperacao = useCallback(() => {
    if (operacaoId) {
      CompartilharOperacao({
        isOpen: true,
        operacaoId,
      });
    }
  }, [operacaoId]);

  useEffect(() => {
    async function getDadosOperacao() {
      setIsLoading(true);

      const response = await api.get<void, ResponseApi<any>>(
        ConstanteEnderecoWebservice.PEDIDOORCAMENTOVENDA_OBTER_DETALHADO,
        {
          params: { id: operacaoId },
        }
      );

      if (response?.avisos) {
        response.avisos.map((item: string) => toast.warning(item));
      }

      if (response?.sucesso && isMountedRef.current) {
        const { dados } = response;
        setOperacao(dados);
      } else {
        history.push(ConstanteRotas.OPERACOES);
      }

      if (isMountedRef.current) setIsLoading(false);
    }

    getDadosOperacao();
  }, [history, isMountedRef, operacaoId]);

  return (
    <>
      {isLoading && <LoadingPadrao />}
      {operacao && (
        <>
          <Box boxShadow="md">
            <ManterFoco blockTab={isLoading}>
              <Header
                IsCadastroExterno={IsCadastroExterno}
                operacao={{
                  numeroOperacao: operacao.numeroOperacao,
                  identificacaoTipoOperacao:
                    operacao?.identificacaoTipoOperacao,
                  dataEmissao: operacao.dataEmissao,
                  status: operacao.status,
                  modeloNumeroFiscais: operacao.modeloNumeroFiscais,
                  cliente: operacao.cliente,
                  endereco: operacao.endereco,
                  telefone: operacao.telefone,
                  email: operacao.email,
                  vendedorNome: operacao.vendedorNome,
                  caixa: operacao.caixa,
                  tabelaPreco: operacao.tabelaPreco,
                  quantidade: operacao.quantidade,
                  subtotal: operacao.subtotal,
                  totalAcrescimos: operacao.totalAcrescimos,
                  totalDescontos: operacao.totalDescontos,
                  valorTotal: operacao.valorTotal,
                  troco: operacao.troco,
                  observacao: operacao.observacao,
                  motivoCancelamento: operacao.motivoCancelamento,
                  origem: operacao.origem,
                }}
              />
              <VStack
                bg="gray.100"
                borderBottomRadius="md"
                spacing={{ base: '5', sm: '8', md: '9', lg: '10' }}
                padding={['8px', '12px', '24px']}
                w="full"
              >
                <DescricaoItens
                  itens={operacao.operacaoItens || []}
                  subTotal={operacao.subtotal || 0}
                  valorTotal={
                    (operacao.valorTotal || 0) - (operacao.totalDesconto || 0)
                  }
                  quantidadeProdutos={operacao.quantidade || 0}
                  descontos={operacao.totalDescontos || 0}
                  isLoading={isLoading}
                  casasDecimais={casasDecimais}
                  quantidade={
                    operacao?.operacaoItens?.reduce(
                      (acc: number, item: Item) => acc + item?.quantidade,
                      0
                    ) || 0
                  }
                />
                <FormaPagamento
                  movimentacoesFinanceiras={
                    operacao.movimentacoesFinanceiras
                      ? operacao.movimentacoesFinanceiras.map(
                          (movimentacaoFinanceira: any) => ({
                            descricaoFormaPagto:
                              movimentacaoFinanceira.descricaoFormaPagto,
                            descricaoParcelas:
                              movimentacaoFinanceira.descricaoParcelas,
                            dataVencimento:
                              movimentacaoFinanceira.dataVencimento,
                            valor: movimentacaoFinanceira.valor,
                            cupomTroca: movimentacaoFinanceira.cupomTroca,

                            parcelas: operacao.parcelas
                              .filter(
                                (parcela: any) =>
                                  parcela.id ===
                                  movimentacaoFinanceira.identificacaoAgrupamento
                              )
                              .map((parcela: any) => ({
                                numeroParcela: parcela.numeroParcela,
                                dataVencimento: parcela.dataVencimento,
                                valor: parcela.valor,
                              })),
                          })
                        )
                      : []
                  }
                  totalPago={operacao.valorPago || 0}
                  troco={operacao.troco || 0}
                  isLoading={isLoading}
                />
                <SimpleGrid
                  w="full"
                  columns={{ base: 1, md: 2 }}
                  gap={{ base: '4', sm: '7', md: '8', lg: '9' }}
                >
                  <Acrescimos
                    acrescimos={operacao.acrescimos || 0}
                    outrasDespesas={operacao.outrasDespesas || 0}
                    valorFrete={operacao.frete || 0}
                    totalAcrescimos={operacao.totalAcrescimos || 0}
                  />
                  <Descontos
                    descontoItens={operacao.descontoItens || 0}
                    descontoAdicional={operacao.descontoAdicional || 0}
                    totalDescontos={operacao.totalDescontos || 0}
                  />
                </SimpleGrid>
              </VStack>
            </ManterFoco>
          </Box>
          <HStack w="full" justifyContent="center" mt="40px">
            <Button
              borderRadius="full"
              variant="outlineDefault"
              colorScheme="gray"
              borderColor="gray.400"
              height="36px"
              px="18px"
              onClick={handleCompartilharOperacao}
            >
              Compartilhar
            </Button>
          </HStack>
        </>
      )}
    </>
  );
};

export default Detalhes;
