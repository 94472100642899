import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { moneyMask } from 'helpers/format/fieldsMasks';

interface Totais {
  valorTotalAcrescimo: number;
  valorTotalDescontoItem: number;
  valorTotalDescontoAdicional: number;
  valorTotalItensSemDesconto: number;
  valorTotal: number;
  troco: number;
  itens: Array<Itens>;
}

interface Itens {
  numeroItem: string;
  nomeProduto: string;
  codigoBarrasInterno: string;
  quantidade: number;
  unidadeMedida: string;
  valorUnitario: number;
  valorTotalItem: number;
}

const Totais = ({
  valorTotalAcrescimo,
  valorTotalDescontoItem,
  valorTotalDescontoAdicional,
  valorTotal,
  itens,
  valorTotalItensSemDesconto,
  troco,
}: Totais) => {
  return (
    <Flex
      w="100%"
      borderBottom="1px solid"
      borderColor="gray.700"
      justifyContent="flex-end"
      pt={1}
      pb={1}
    >
      <Flex
        w="60%"
        maxW="150px"
        direction="column"
        alignItems="flex-end"
        justifyContent="center"
      >
        <Flex pt={1} w="100%" justifyContent="space-between">
          <Text>Total de Itens:</Text>
          <Text as="span">{itens.length}</Text>
        </Flex>
        {valorTotalAcrescimo > 0 ||
        valorTotalDescontoAdicional > 0 ||
        valorTotalDescontoItem > 0 ? (
          <Flex w="100%" justifyContent="space-between">
            <Text>Subtotal:</Text>
            <Text as="span">
              {moneyMask(valorTotalItensSemDesconto, false)}
            </Text>
          </Flex>
        ) : (
          ''
        )}
        {valorTotalAcrescimo > 0 && (
          <Flex w="100%" justifyContent="space-between">
            <Text fontSize="2xs">Acréscimo:</Text>
            <Text as="span" fontSize="2xs">
              {moneyMask(valorTotalAcrescimo, false)}
            </Text>
          </Flex>
        )}
        {valorTotalDescontoItem > 0 && (
          <Flex w="100%" justifyContent="space-between">
            <Text fontSize="2xs">Desconto Itens:</Text>
            <Text as="span" fontSize="2xs">
              {moneyMask(valorTotalDescontoItem, false)}
            </Text>
          </Flex>
        )}
        {valorTotalDescontoAdicional > 0 && (
          <Flex w="100%" justifyContent="space-between">
            <Text fontSize="2xs">Desconto Adicional:</Text>
            <Text as="span" fontSize="2xs">
              {moneyMask(valorTotalDescontoAdicional, false)}
            </Text>
          </Flex>
        )}
        <Flex w="100%" justifyContent="space-between">
          <Text>Valor Total:</Text>
          <Text as="span">{moneyMask(valorTotal, false)}</Text>
        </Flex>
        {troco > 0 && (
          <Flex w="100%" justifyContent="space-between">
            <Text>Troco:</Text>
            <Text as="span">{moneyMask(troco, false)}</Text>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default Totais;
