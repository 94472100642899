import * as yup from 'yup';
import { yupResolver as yupResolverInstance } from '@hookform/resolvers/yup';
import { useForm as useFormInstance } from 'react-hook-form';

import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';
import TipoProdutoEnum from 'constants/enum/tipoProduto';
import IndicadorEscalaRelevanteEnum from 'constants/enum/indicadorEscalaRelevante';
import { isValidCnpj } from 'helpers/validation/IsValidCpfCnpj';
import { isValidGTIN } from 'helpers/validation/isValidGtinEan';

import { schemaRegraFiscalExcecao } from './TabsContent/Fiscais/ExecucaoEstadoICMSModal/validationForm';
import { schemaVariacao } from './TabsContent/Variacoes/EditarVariacaoDrawer/validationForm';

export type VariacoesProps = {
  altura?: number;
  ativo?: boolean;
  codigoExterno?: string | null;
  codigoGTINEAN?: string | null;
  corId?: string;
  estoqueAtual?: number;
  estoqueMinimo?: number;
  largura?: number;
  pesoBruto?: number;
  pesoLiquido?: number;
  profundidade?: number;
  selecionado?: boolean;
  tamanho?: string | undefined;
  tamanhoDescricao?: string;
  corDescricao?: string;
  tamanhoId?: string;
};

export type CamposObrigatoriosProps = {
  tests: {
    name: string;
  }[];
};

export type SelectItemProps = {
  value: string;
  label: string;
};

export type FiscalProps = {
  produtoId: string;
  tipoProdutoFiscal: number;
  cstOrigem: number;
  codigoNcm: SelectItemProps | null;
  ncmLabel: string;
  codigoCest: string;
  regraFiscalId: SelectItemProps;
  icmsAliquota: number;
  regraFiscal: string;
  unidadeTributavel: number | SelectItemProps | string;
  codigoBeneficioFiscal: string;
  fcpAliquota: number;
  icmsStRetidoBaseCalculo: number;
  icmsStRetidoValor: number;
  fcpStRetidoBaseCalculo: number;
  fcpStRetidoValor: number;
  pisAliquota: number;
  cofinsAliquota: number;
  unidadeTributavelId: string;
  fatorConversao: string;
  qtdeConversao: number;
  indicadorEscalaRelevante: number;
  cnpjFabricante: string | null;
  codigoAnp: string;
  codif: string;
  percentualGLP: number;
  percentualGasNacional: number;
  percentualGasImportado: number;
  valorPartidaGLP: number;
  produtoRegraFiscalExcecoes: {
    reducaoBaseCalculo: number;
    codigoBeneficioFiscal: string;
    porcentagemFCP: number;
    icmsReducaoBaseCalculo: number;
    aliquotaIcms: number;
    estadoDestinoId: number;
    estadoOrigemId: number;
  }[];
};

export const shapePrincipal = {
  foto: yup.string().nullable().default(null),
  nome: yup
    .string()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .max(120, ConstanteMensagemValidacao.MAX_LENGTH_120)
    .default(null),
  nomeAbreviado: yup
    .string()
    .nullable()
    .max(50, ConstanteMensagemValidacao.MAX_LENGTH_50)
    .default(null),
  tipoProduto: yup
    .number()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .default(TipoProdutoEnum.PRODUTO_SIMPLES),
  sku: yup.string().nullable().default(null),
  codigoGTINEAN: yup
    .string()
    .nullable()
    .test(
      'codigoGTINEAN',
      ConstanteMensagemValidacao.GTIN_INVALIDO,
      (value) => {
        if (value) return isValidGTIN(value);

        return true;
      }
    )
    .max(14, ConstanteMensagemValidacao.MAX_LENGTH_14)
    .default(null),
  referencia: yup
    .string()
    .nullable()
    .max(60, ConstanteMensagemValidacao.MAX_LENGTH_60)
    .default(null),
  categoriaProduto: yup
    .object()
    .shape({
      value: yup
        .string()
        .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
        .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
        .default(null),
    })
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .default(null),
  marca: yup
    .object()
    .shape({
      value: yup
        .string()
        .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
        .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
        .default(null),
      label: yup
        .string()
        .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
        .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
        .default(null),
    })
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .default(null),
  unidadeMedida: yup
    .object()
    .shape({
      value: yup
        .string()
        .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
        .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
        .default(null),
      label: yup
        .string()
        .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
        .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
        .default(null),
    })
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .default(null),
  estoqueInicial: yup
    .number()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .default(0),
  estoqueMinimo: yup
    .number()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .default(0),
  tags: yup.array().of(yup.string()).nullable().default([]),
  ativo: yup
    .boolean()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .default(true),
};

const shapeVariacoes = {
  cores: yup
    .array()
    .of(
      yup
        .object()
        .shape({
          value: yup
            .string()
            .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
            .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
            .default(null),
          label: yup
            .string()
            .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
            .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
            .default(null),
        })
        .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
        .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
        .default(null)
    )
    .nullable()
    .default([]),
  tamanhos: yup
    .array()
    .of(
      yup
        .object()
        .shape({
          value: yup
            .string()
            .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
            .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
            .default(null),
          label: yup
            .string()
            .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
            .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
            .default(null),
        })
        .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
        .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
        .default(null)
    )
    .nullable()
    .default([]),
  variacoes: yup.array().of(schemaVariacao).nullable().default([]),
};

export const shapeFiscais = {
  tipoProdutoFiscal: yup
    .number()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  cstOrigem: yup
    .string()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .default(0),
  codigoNcm: yup
    .string()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .default(null),
  codigoCest: yup
    .string()
    .nullable()
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .default(null)
    .test(
      'min-length',
      'O campo deve ter no mínimo 7 caracteres',
      (value) => value === null || value === '' || value?.length === 7
    ),

  regraFiscal: yup
    .object()
    .shape({
      value: yup
        .string()
        .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
        .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
        .default(null),
      label: yup
        .string()
        .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
        .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
        .default(null),
    })
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .default(null),
  icmsAliquota: yup
    .number()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .default(0),
  icmsReducaoBaseCalculo: yup
    .number()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .default(0),
  codigoBeneficioFiscal: yup
    .string()
    .nullable()
    .notRequired()
    .test(
      'codigoBeneficioFiscal',
      ConstanteMensagemValidacao.TAMANHO_BENEFICIO_FISCAL,
      (value) => {
        if (value && value.length > 0) {
          if (value.length === 8 || value.length === 10) {
            return true;
          }
          return false;
        }
        return true;
      }
    )
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .default(null),
  fcpAliquota: yup
    .number()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .default(0),
  icmsStRetidoBaseCalculo: yup
    .number()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .default(0),
  icmsStRetidoValor: yup
    .number()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .default(0),
  fcpStRetidoBaseCalculo: yup
    .number()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .default(0),
  fcpStRetidoValor: yup
    .number()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .default(0),
  produtoRegraFiscalExcecoes: yup
    .array()
    .of(schemaRegraFiscalExcecao)
    .nullable()
    .default([]),
  pisAliquota: yup
    .number()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .default(0),
  cofinsAliquota: yup
    .number()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .default(0),
  unidadeTributavel: yup
    .object()
    .shape({
      value: yup
        .string()
        .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
        .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
        .default(null),
      label: yup
        .string()
        .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
        .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
        .default(null),
    })
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .default(null),
  fatorConversao: yup
    .string()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .max(1, ConstanteMensagemValidacao.MAX_LENGTH_1)
    .default('*'),
  qtdeConversao: yup
    .number()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .test(
      'qtdeConversao',
      ConstanteMensagemValidacao.VALOR_MAIOR_QUE_0,
      (value) => {
        if (value && value > 0) return true;

        return false;
      }
    )
    .default(1),
  indicadorEscalaRelevante: yup
    .number()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .default(IndicadorEscalaRelevanteEnum.NENHUM),
  cnpjFabricante: yup
    .string()
    .nullable()
    .test(
      'cnpjFabricante',
      ConstanteMensagemValidacao.CNPJ_INVALIDO,
      function verificarCnpjFabricante() {
        const { indicadorEscalaRelevante, cnpjFabricante } = this.parent;

        if (indicadorEscalaRelevante === IndicadorEscalaRelevanteEnum.NAO) {
          return cnpjFabricante ? isValidCnpj(cnpjFabricante) : false;
        }

        return true;
      }
    )
    .max(18, ConstanteMensagemValidacao.CNPJ_INVALIDO),
  codigoAnp: yup.string().nullable().default(''),
  CODIF: yup
    .string()
    .nullable()
    .max(21, ConstanteMensagemValidacao.MAX_LENGTH_21)
    .default(null),
  percentualGLP: yup
    .number()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .default(0),
  percentualGasNacional: yup
    .number()
    .nullable()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .default(0),
  percentualGasImportado: yup
    .number()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .default(0),
  valorPartidaGLP: yup
    .number()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .default(0),
};

const shapeOutrasInformacoes = {
  controlaEstoque: yup
    .boolean()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .default(true),
  permiteAlteraValorNaVenda: yup
    .boolean()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .default(false),
  codigoExterno: yup
    .string()
    .nullable()
    .max(60, ConstanteMensagemValidacao.MAX_LENGTH_60)
    .default(null),
  codigoBarrasInterno: yup.string().nullable().default(null),
  outrasInformacoesLargura: yup.number().nullable().default(0),
  outrasInformacoesAltura: yup.number().nullable().default(0),
  outrasInformacoesProfundidade: yup.number().nullable().default(0),
  outrasInformacoesPesoLiquido: yup.number().nullable().default(0),
  outrasInformacoesPesoBruto: yup.number().nullable().default(0),
};

const shapeKits = {
  produtoCorTamanhoKitItens: yup
    .array()
    .of(
      yup.object().shape({
        produtoCorTamanhoId: yup
          .string()
          .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
          .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
          .default(null),
        quantidade: yup
          .number()
          .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
          .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
          .test(
            'quantidade',
            ConstanteMensagemValidacao.VALOR_MAIOR_QUE_0,
            (value) => {
              if (value && value > 0) return true;

              return false;
            }
          )
          .default(1),
        valor: yup
          .number()
          .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
          .typeError(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
          .default(0),
      })
    )
    .nullable()
    .default([]),
};

export const shapeFood = {
  usarComoComplemento: yup.boolean().default(false),
  utilizarBalanca: yup.boolean().default(false),
  cobrarTaxaServico: yup.boolean().default(true),
  baixarSaldoMateriaPrima: yup.boolean().default(false),
  produtoCombo: yup.boolean().default(false),
  exportarBalanca: yup.boolean().default(false),
  utilizarPrecoDosItensEtapa: yup.boolean().default(false),
  diasParaValidade: yup.number().default(0),
  precoCombo: yup.number().default(0),
  gerenciadorImpressao: yup
    .object()
    .shape({
      label: yup.string(),
      value: yup.string(),
    })
    .default(undefined),
  composicaoProduto: yup.string().default(undefined),
  imagemCardapio: yup.string().default(undefined),
  imagemDestaque: yup.string().default(undefined),
};

const produtoPrecoLojas = {
  produtoPrecoLojas: yup.object().shape({
    lojas: yup.array().of(
      yup.object().shape({
        id: yup.string(),
        lojaRazao: yup.string(),
      })
    ),
    precoPorLoja: yup
      .array()
      .of(
        yup.object().shape({
          id: yup.string().nullable(),
          precoCompra: yup.number().default(0),
          markup: yup.number().default(0),
          precoCusto: yup.number().default(0),
          precoVenda: yup.number().default(0),
        })
      )
      .nullable()
      .default([]),
    valoresAlterados: yup.boolean().default(false),
  }),
};

const schema = yup.object().shape({
  ...shapePrincipal,
  ...shapeVariacoes,
  ...shapeFiscais,
  ...shapeOutrasInformacoes,
  ...shapeKits,
  ...shapeFood,
  ...produtoPrecoLojas,
});

export const schemaPrincipal = yup.object().shape({
  ...shapePrincipal,
});

export const schemaFiscais = yup.object().shape({
  ...shapeFiscais,
});

export const yupResolver = yupResolverInstance(schema);
export const yupResolverDadosGerais = yupResolverInstance(schemaPrincipal);

export const defaultValues = schema.default();

export const useForm = useFormInstance;
