import React, {
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
  ReactNode,
  MutableRefObject,
  useRef,
} from 'react';

type ClienteId = {
  label: string | null;
  value: string | null;
};

type FormData = {
  clienteId: ClienteId | string;
  status: number | null;
  numeroOperacao?: number | string;
  filtrarPeloCaixaAtual?: boolean;
  dataEmissaoInicio: Date;
  dataEmissaoFim: Date;
  possuiDevolucao: boolean;
  identificacaoTipoOperacao: number[] | [];
  contasFinanceirasId?: string[];
};

interface OperacoesContextProps {
  contextFiltros: FormData;
  setContextFiltros: Dispatch<SetStateAction<FormData>>;
  carregarDataContextRef: MutableRefObject<boolean>;
}

const OperacoesFiltrosContext = createContext<OperacoesContextProps>(
  {} as OperacoesContextProps
);

interface OperacoesFiltroProviderProps {
  children: ReactNode;
}

export default function OperacoesFiltroProvider({
  children,
}: OperacoesFiltroProviderProps): JSX.Element {
  const [contextFiltros, setContextFiltros] = useState<FormData>(
    {} as FormData
  );
  const carregarDataContextRef = useRef(false);

  return (
    <OperacoesFiltrosContext.Provider
      value={{
        contextFiltros,
        setContextFiltros,
        carregarDataContextRef,
      }}
    >
      {children}
    </OperacoesFiltrosContext.Provider>
  );
}

export function useOperacoesFiltroContext(): OperacoesContextProps {
  const context = useContext(OperacoesFiltrosContext);

  if (!context)
    throw new Error(
      'useLojaFiltroContext must be used within a LojaFiltroProvider.'
    );

  return context;
}
