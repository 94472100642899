import React from 'react';
import { Box, Flex, FormLabel, GridItem, Text } from '@chakra-ui/react';

import { Switch } from 'components/update/Switch';
import { LabelSelect } from '../components/LabelSelect';

export const FluxoOperacional = () => {
  return (
    <GridItem
      colSpan={[12, 12, 6, 4, 4]}
      minW={['100%', '100%', '50%', '373px']}
    >
      <Text fontWeight="bold">Fluxo operacional</Text>
      <Flex
        bg="#E8E8E8"
        minH={['inherit', 'inherit', '280px']}
        padding="24px"
        pt="16px"
        borderRadius="5px"
        border="1px solid #CCCCCC"
        flexDir="column"
        gap="16px"
        height={['fit-content', 'full', 'full', 'full', 'fit-content']}
      >
        <Flex
          pb="16px"
          pt="8px"
          borderBottom="1px solid #ccc"
          height="fit-content"
          flexWrap="wrap"
          w="full"
          justifyContent="space-between"
        >
          <Flex>
            <FormLabel letterSpacing="0px" mr="2px">
              CPF na nota
            </FormLabel>
          </Flex>
          <Box mr="10px">
            <Switch size="md" label="" id="requestCPF" name="requestCPF" />
          </Box>
        </Flex>
        <Flex
          height="fit-content"
          pb="16px"
          pt="8px"
          borderBottom="1px solid #ccc"
          flexWrap="wrap"
          w="full"
          justifyContent="space-between"
        >
          <Flex>
            <FormLabel letterSpacing="0px" mr="2px">
              Selecionar o local de consumo
            </FormLabel>
          </Flex>
          <Box mr="10px">
            <Switch
              size="md"
              label=""
              id="askWhereToConsume"
              name="askWhereToConsume"
            />
          </Box>
        </Flex>
        <Flex
          height="fit-content"
          pt="8px"
          flexWrap="wrap"
          w="full"
          justifyContent="space-between"
        >
          <LabelSelect
            name="paperSize"
            label="Tamanho da impressão:"
            selectWidth="160px"
            showBorder={false}
            options={[
              {
                label: 'Bobina 57mm',
                value: '57',
              },
              {
                label: 'Bobina 80mm',
                value: '80',
              },
            ]}
          />
        </Flex>
      </Flex>
    </GridItem>
  );
};
