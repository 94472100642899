import { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import ConstanteRotas from 'constants/rotas';
import { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { enumIdentificacaoIntegracao } from 'constants/enum/enumIdentificacaoIntegracao';
import auth from 'modules/auth';
import enumReferenciaServicoStargate from 'constants/enum/referenciaServicoStargate';
import { usePdvAutonomoContext } from 'store/PdvAutonomo';
import { sistemaApiKey } from 'services/sistemaApiKey';

import { Configuracao } from './Configuracao';
import { FormaRecebimento } from './FormaRecebimento';
import { Dados } from './Dados';
import { Dispositivo } from './Dispositivo';
import { TabelaPreco } from './TabelaPreco';

type TabsProps = {
  label: string;
  value: number;
  content: JSX.Element;
};

export const usePdvAutonomo = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { getIntegracao, infoIntegracao } = usePdvAutonomoContext();

  const valueTabs = [
    {
      label: 'Dados',
      value: 0,
      content: <Dados />,
      isDisabled: false,
    },
    {
      label: 'Configuração',
      isDisabled: !infoIntegracao?.sincronizacaoHabilitada,
      value: 1,
      content: <Configuracao />,
    },
    {
      label: 'Forma recebimento',
      isDisabled: !infoIntegracao?.sincronizacaoHabilitada,
      value: 2,
      content: <FormaRecebimento />,
    },
    {
      label: 'Tabela de preço',
      isDisabled: !infoIntegracao?.sincronizacaoHabilitada,
      value: 3,
      content: <TabelaPreco />,
    },
    {
      label: 'Dispositivos',
      isDisabled: !infoIntegracao?.sincronizacaoHabilitada,
      value: 4,
      content: <Dispositivo />,
    },
  ];

  const possuiServicoPdvAutonomo = auth.possuiServico(
    enumReferenciaServicoStargate.DISPOSITIVO_PDV
  ).permitido;

  const [activeStep, setActiveStep] = useState<TabsProps>(valueTabs[0]);

  const history = useHistory();

  function handleVoltarPainelAdm() {
    history.push(ConstanteRotas.LOJA_APLICATIVOS);
  }

  const handleUpdateStep = (itemTab: TabsProps) => {
    setActiveStep(itemTab);
  };

  const cadastrarIntegracao = useCallback(async () => {
    const response = await sistemaApiKey().post<void, ResponseApi<FormData>>(
      ConstanteEnderecoWebservice.PDV_AUTONOMO_CADASTRAR_INTEGRACAO,
      {
        identificacaoIntegracao: enumIdentificacaoIntegracao.PDV_AUTONOMO,
      }
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((aviso: string) => toast.warning(aviso));
      }
      if (response.sucesso) {
        setIsLoading(false);
      }
    }
  }, []);

  useEffect(() => {
    const getConfigPdvAutonomo = async () => {
      setIsLoading(true);
      await getIntegracao(cadastrarIntegracao);
      setIsLoading(false);
    };
    getConfigPdvAutonomo();
  }, []);

  useEffect(() => {
    if (!possuiServicoPdvAutonomo) {
      history.push(ConstanteRotas.LOJA_APLICATIVOS);
    }
  }, []);

  return {
    handleVoltarPainelAdm,
    infoIntegracao,
    handleUpdateStep,
    isLoading,
    activeStep,
    valueTabs,
    setActiveStep,
  };
};
