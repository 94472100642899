import { toast } from 'react-toastify';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import OperacaoItem from 'types/operacaoItem';

export interface OperacaoItemObter extends OperacaoItem {
  produto: string;
  cor?: string;
  tamanho?: string;
  foto?: string;
  permiteAlterarValorNaVenda?: boolean;
  volumeUnitario: boolean;
  solicitarInformacaoComplementar: boolean;
  informacoesComplementares: string;
  itemConsignado: boolean;
}

interface OperacaoObter {
  vendedorOpcaoSelect: { id: string; nome: string };
  clienteFornecedorOpcaoSelect: { id: string; nome: string };
  tabelaPrecoOpcaoSelect: { id: string; nome: string };
  observacao: string;
  operacaoItens: OperacaoItemObter[];
  numeroOperacao: number;
  possuiMovimentacaoFinanceira: boolean;
  identificacaoTipoOperacao: number;
  mensagemItensKitAtualizados: string;
  localEstoqueId: string;
  saldoVoucher: number;
}

const obterOperacaoComItens = async (
  id: string
): Promise<OperacaoObter | undefined> => {
  const response = await api.get<void, ResponseApi<OperacaoObter>>(
    ConstanteEnderecoWebservice.PEDIDOORCAMENTOVENDA_OBTER_COM_ITENS,
    { params: { id } }
  );

  if (response?.avisos) {
    response.avisos.map((item: string) => toast.warning(item));
  }

  if (response?.sucesso) {
    return response.dados;
  }

  return undefined;
};

export default obterOperacaoComItens;
