export const enumRelatorioProdutosPersonalizadoPadrao = {
  RELATORIO_PRODUTO_POR_VENDA: 1,
  LUCRO_POR_PRODUTO: 2,
  PRODUTO_COM_PRECO: 3,

  properties: [
    {
      id: 1,
      name: 'Produto por venda',
      value: 1,
    },
    {
      id: 2,
      name: 'Lucro por produto',
      value: 2,
    },
    {
      id: 3,
      name: 'Produto com preço',
      value: 3,
    },
  ],
};
