/* eslint-disable no-nested-ternary */
import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from 'react';
import {
  Box,
  Grid,
  VStack,
  HStack,
  AspectRatio,
  Image,
  Flex,
  Button,
  Text,
  Icon,
  Fade,
  Input,
  useMediaQuery,
  useBreakpointValue,
  IconButton,
  useDisclosure,
  GridItem,
  Stack,
} from '@chakra-ui/react';
import { useFormContext, FormProvider } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FiMinus, FiPlus } from 'react-icons/fi';
import { isIOS as reactDeviceDetectIsIOS, isMobile } from 'react-device-detect';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import ConstanteFuncionalidades from 'constants/permissoes';
import TipoValorEnum, { TipoValor } from 'constants/enum/tipoValor';
import OptionType from 'types/optionType';
import obterPrecoProduto from 'helpers/api/TabelaPreco/obterPrecoProduto';
import ButtonItemDesconto from 'components/PDV/ButtonItemDesconto';
import auth from 'modules/auth';
import LogAuditoriaTelaEnum from 'constants/enum/logAuditoriaTela';
import { SubstituirParametroRota } from 'constants/rotas';
import ConstanteRotasPDV from 'constants/rotasPDV';
import { useInformacoesGeraisContext } from 'store/PDV/InformacoesGerais';
import cadastrarOperacao from 'helpers/api/Operacao/cadastrarOperacao';
import cadastrarOperacaoItem from 'helpers/api/OperacaoItem/cadastrarOperacaoItem';
import alterarOperacaoItem from 'helpers/api/OperacaoItem/alterarOperacaoItem';
import { OperacaoItemObter } from 'helpers/api/Operacao/obterOperacaoComItens';
import { useOperacaoContext } from 'store/PDV/Operacao';
import { useLancamentoEditarItemContext } from 'store/PDV/LancamentoEditarItem';
import getHeaderChaveTemporaria from 'helpers/api/getHeaderChaveTemporaria';
import SelectProdutoProvider, {
  ProdutoOption,
  SelectProdutoContext,
} from 'store/PDV/SelectProduto';
import { isIOS } from 'helpers/layout/isIOS';
import { blurIOS } from 'helpers/layout/blurIOS';
import { moneyMask } from 'helpers/format/fieldsMasks';
import TipoProdutoEnum, { TipoProduto } from 'constants/enum/tipoProduto';

import {
  LeitorCodigoBarrasIcon,
  ProdutoSemImagemIcon,
  SalvarInserirNovoIcon,
} from 'icons';
import SelectProduto, {
  SelectProdutoRef,
} from 'components/PDV/Select/SelectProduto';
import SelectPadrao from 'components/PDV/Select/SelectPadrao';
import { NumberInput } from 'components/update/Input/NumberInput';
import OperacaoCamposAlterarEnum from 'constants/enum/operacaoCamposAlterar';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import ModalAutorizacaoFuncionalidade from 'components/Modal/ModalAutorizacaoFuncionalidade';
import CampoContainer from 'components/PDV/Geral/CampoContainer';
import { usePadronizacaoContext } from 'store/Padronizacao/Padronizacao';
import Overlay from 'components/PDV/Geral/Overlay';
import fontSizes from 'theme/foundations/fontSizes';
import ManterFoco from 'components/Geral/ManterFoco';
import { MobileSelectWrapperModal } from 'components/update/Select/MobileSelect/MobileSelectWrapperModal';
import { ButtonDefault } from 'components/Button/ButtonChakra';
import { InputNumber } from 'components/update/Input/InputNumber';

import { useForm, yupResolver, defaultValues } from './validationForm';
import { ModalAdicionarEditarItem } from './ModalAdicionarEditarItem';
import { ModalCodigoBarras } from './ModalCodigoBarras';
import { ModalInformacoesComplementares } from '../ModalInformacoesComplementares';
import { obterProdutosLancamento } from './Functions/getProdutoLancamento';

interface OperacaoItemObterInformacoesAlterar {
  foto: string;
  permiteAlterarValorNaVenda: boolean;
  tamanhos: TamanhoOption[];
  itemConsignado: boolean;
  tipoProduto: TipoProduto;
}

type TamanhoOption = {
  id: string;
  nome: string;
  padraoSistema: boolean;
};

interface AdicionarEditarItemProps {
  chavePermissaoTemporaria?: string;
  index?: string;
}

interface CalculoValoresItem {
  valorItemSemDesconto: number;
  valorDescontoItem: number;
  valorItemComDesconto: number;
}
interface AlterarLocalEstoque {
  id: string;
  conteudoCampo: string;
  tela: number;
  campo: number;
}

const AdicionarEditarItem = ({
  chavePermissaoTemporaria,
}: AdicionarEditarItemProps) => {
  const asMobileView = useBreakpointValue({ base: true, md: false });

  const [isLargerThan360] = useMediaQuery('(min-width: 360px)');
  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');
  const [isLargerThan1800] = useMediaQuery('(min-width: 1800px)');

  const history = useHistory();
  const { setValue, getValues, watch } = useFormContext();
  const formMethods = useForm({
    defaultValues,
    resolver: yupResolver,
    shouldUnregister: false,
  });

  const {
    contaFinanceira,
    operacaoItemlength,
    localDeEstoqueSelecionado,
    ultimoEstoqueSelecionado,
    detalhesTroca,
    tabelaPrecoId,
  } = useInformacoesGeraisContext();
  const { operacaoId, operacaoIsLoading } = useOperacaoContext();
  const [valorDesconto, setValorDesconto] = useState(0);
  const { casasDecimais, obterCasasDecimais } = usePadronizacaoContext();
  const {
    operacaoItemEditando,
    setOperacaoItemEditando,
  } = useLancamentoEditarItemContext();
  const permissaoConcederAcrescimosDescontos = useMemo(
    () =>
      auth.possuiPermissao(
        ConstanteFuncionalidades.PDV_CONCEDER_ACRESCIMOS_DESCONTOS.codigo
      ),
    []
  );

  const userChangedTheLocaleStock =
    ultimoEstoqueSelecionado.current.id !== localDeEstoqueSelecionado.id &&
    !operacaoItemlength;

  const {
    isOpen: isModalSelectProdutoOpen,
    onOpen: onModalSelectProdutoOpen,
    onClose: onModalSelectProdutoClose,
  } = useDisclosure({ defaultIsOpen: !operacaoId });

  const {
    isOpen: isModalCodigoBarrasOpen,
    onOpen: onModalCodigoBarrasOpen,
    onClose: onModalCodigoBarrasClose,
  } = useDisclosure();

  const selectProdutoRef = useRef<SelectProdutoRef>(null);
  const [
    modalAutorizacaoDescontoAcrescimoIsOpen,
    setModalAutorizacaoDescontoAcrescimoIsOpen,
  ] = useState(false);
  const [
    informacoesProdutoAtual,
    setInformacoesProdutoAtual,
  ] = useState<ProdutoOption>();

  const [isLoading, setIsLoading] = useState(false);
  const [produtoFoiAlterado, setProdutoFoiAlterado] = useState<
    { id: string }[]
  >([]);

  const [
    produtoCorTamanhoId,
    quantidade,
    valorUnitario,
    descontoItem,
    tipoDescontoItem,
  ] = formMethods.watch([
    'produtoCorTamanhoId',
    'quantidade',
    'valorUnitario',
    'descontoItem',
    'tipoDescontoItem',
  ]);

  const viewIsLoading = isLoading || operacaoIsLoading;

  const tabelaPreco = watch('tabelaPreco');
  const vendedor = watch('vendedor');
  const possuiMovimentacaoFinanceira = watch('possuiMovimentacaoFinanceira');

  const naoPermitirAlterarValorNaVenda =
    !produtoCorTamanhoId ||
    !informacoesProdutoAtual ||
    (informacoesProdutoAtual &&
      !informacoesProdutoAtual.permiteAlterarValorNaVenda) ||
    informacoesProdutoAtual?.tipoProduto === TipoProdutoEnum.PRODUTO_KIT;

  const calcularDescontoItem = (
    tipoDesconto: TipoValor,
    descItem: number,
    valorItem: number
  ): number => {
    if (tipoDesconto === TipoValorEnum.REAIS) {
      setValorDesconto(0);
      return descItem;
    }
    setValorDesconto(
      Math.round(((descItem / 100) * valorItem + Number.EPSILON) * 100) / 100
    );
    return (
      Math.round(((descItem / 100) * valorItem + Number.EPSILON) * 100) / 100
    );
  };

  const calcularValorItemSemDesconto = (
    qtd: number,
    valorUnit: number
  ): number => {
    return Number((qtd * valorUnit).toFixed(2));
  };

  const calcularValorItemComDesconto = useCallback(
    (
      qtd: number,
      valorUnit: number,
      tipoDesconto: TipoValor,
      descItem: number
    ): number => {
      let valorItem = calcularValorItemSemDesconto(qtd, valorUnit);
      valorItem -= calcularDescontoItem(tipoDesconto, descItem, valorItem);
      return Number(valorItem.toFixed(2));
    },
    []
  );

  const calcularValoresItem = (
    operacaoItem: OperacaoItemObter
  ): CalculoValoresItem => {
    const valorItemSemDesconto = calcularValorItemSemDesconto(
      operacaoItem.quantidade,
      operacaoItem.valorUnitario
    );

    const valorDescontoItem = calcularDescontoItem(
      operacaoItem.tipoDescontoItem,
      operacaoItem.descontoItem,
      valorItemSemDesconto
    );

    const valorItemComDesconto = calcularValorItemComDesconto(
      operacaoItem.quantidade,
      operacaoItem.valorUnitario,
      operacaoItem.tipoDescontoItem,
      operacaoItem.descontoItem
    );

    return { valorItemSemDesconto, valorDescontoItem, valorItemComDesconto };
  };

  const obterOperacaoItem = async (
    operacaoItem: OperacaoItemObter,
    caluloValoresItem: CalculoValoresItem
  ) => {
    let informacoesComplementares = '';

    if (operacaoItem.solicitarInformacaoComplementar) {
      informacoesComplementares = await ModalInformacoesComplementares();
    }

    return {
      produtoCorTamanhoId: operacaoItem.produtoCorTamanhoId,
      quantidade: operacaoItem.quantidade,
      valorUnitario: operacaoItem.valorUnitario,
      valorItemComDesconto: caluloValoresItem.valorItemComDesconto,
      valorItemSemDesconto: caluloValoresItem.valorItemSemDesconto,
      tipoDescontoItem: operacaoItem.tipoDescontoItem,
      descontoItem: operacaoItem.descontoItem,
      valorDescontoItem: Number(caluloValoresItem.valorDescontoItem.toFixed(2)),
      tela: LogAuditoriaTelaEnum.PDV,
      operacaoId,
      informacoesComplementares,
    };
  };

  const handleCreateOperacaoItem = async (operacaoItem: OperacaoItemObter) => {
    if (possuiMovimentacaoFinanceira) {
      toast.warning(
        'Para adicionar um novo item é necessário remover o pagamento adicionado.'
      );
      return;
    }

    setIsLoading(true);

    const { userId: usuarioId } = auth.getDadosToken();

    const caluloValoresItem = calcularValoresItem(operacaoItem);

    const operacaoItemCadastrar = await obterOperacaoItem(
      operacaoItem,
      caluloValoresItem
    );

    if (!operacaoId) {
      const { id: vendedorId } = getValues('vendedor') || {};
      const { id: clienteFornecedorId } = getValues('cliente') || {};
      const { id: lojaId } = auth.getLoja();
      const observacao = getValues('observacao');
      const identificacaoTipoOperacao = getValues('identificacaoTipoOperacao');

      if (
        vendedorId &&
        clienteFornecedorId &&
        lojaId &&
        usuarioId &&
        contaFinanceira
      ) {
        const newOperacao = await cadastrarOperacao({
          vendedorId,
          clienteFornecedorId,
          lojaId,
          usuarioId,
          tela: LogAuditoriaTelaEnum.PDV,
          identificacaoTipoOperacao,
          observacao,
          caixaMovimentacaoId: contaFinanceira?.caixaMovimentacao?.id,
          tabelaPrecoId: tabelaPrecoId || tabelaPreco?.value,
          operacaoItem: operacaoItemCadastrar,
          devolucaoId: detalhesTroca.idOperacao || null,
          localEstoqueId: localDeEstoqueSelecionado.id,
        });

        if (newOperacao) {
          history.replace(
            SubstituirParametroRota(
              ConstanteRotasPDV.PDV_LANCAMENTO_ID,
              'id?',
              newOperacao.id
            )
          );

          return;
        }
      }
    }

    if (operacaoItemCadastrar.operacaoId) {
      const operacaoItemId = await cadastrarOperacaoItem(operacaoItemCadastrar);

      if (operacaoItemId) {
        const operacaoItens = getValues('operacaoItens');

        const newOperacaoItem = {
          id: operacaoItemId,
          ...operacaoItem,
          tamanho: informacoesProdutoAtual?.tamanhos.every(
            (item) => item.padraoSistema === true
          )
            ? ''
            : operacaoItem.tamanho,
          valorItemComDesconto: caluloValoresItem.valorItemComDesconto,
          valorItemSemDesconto: caluloValoresItem.valorItemSemDesconto,
          valorDescontoItem: caluloValoresItem.valorDescontoItem,
          informacoesComplementares:
            operacaoItemCadastrar.informacoesComplementares,
        } as OperacaoItemObter;

        setValue('operacaoItens', [...(operacaoItens || []), newOperacaoItem]);

        setInformacoesProdutoAtual(undefined);
      }
    }

    if (selectProdutoRef?.current) {
      selectProdutoRef.current.blur();
      selectProdutoRef.current.focus();
    }

    setIsLoading(false);
  };

  const handlePutOperacaoItem = async (operacaoItem: OperacaoItemObter) => {
    setIsLoading(true);

    if (operacaoId && operacaoItemEditando) {
      const valorItemSemDesconto = calcularValorItemSemDesconto(
        operacaoItem.quantidade,
        operacaoItem.valorUnitario
      );
      const valorTotalDescontoItem = calcularDescontoItem(
        operacaoItem.tipoDescontoItem,
        operacaoItem.descontoItem,
        valorItemSemDesconto
      );

      const valorDescontoItem =
        Math.round((valorTotalDescontoItem + Number.EPSILON) * 100) / 100;

      const valorItemComDesconto = calcularValorItemComDesconto(
        operacaoItem.quantidade,
        operacaoItem.valorUnitario,
        operacaoItem.tipoDescontoItem,
        operacaoItem.descontoItem
      );
      const tamanhoItem = informacoesProdutoAtual?.tamanhos.find(
        (tamanho) => tamanho.id === produtoCorTamanhoId
      );
      const newOperacaoItem = {
        ...operacaoItemEditando,
        ...operacaoItem,
        valorItemComDesconto,
        valorItemSemDesconto,
        valorDescontoItem,
        tamanho: tamanhoItem?.nome,
      } as OperacaoItemObter;

      const success = await alterarOperacaoItem(
        {
          ...operacaoItemEditando,
          produtoCorTamanhoId: operacaoItem.produtoCorTamanhoId,
          quantidade: operacaoItem.quantidade,
          valorUnitario: operacaoItem.valorUnitario,
          valorItemComDesconto,
          valorItemSemDesconto,
          tipoDescontoItem: operacaoItem.tipoDescontoItem,
          descontoItem: operacaoItem.descontoItem,
          valorDescontoItem,
          tela: LogAuditoriaTelaEnum.PDV,
          operacaoId,
        },
        operacaoItemEditando.chavePermissaoTemporariaAlterar
      );

      if (success) {
        const operacaoItens = getValues('operacaoItens') || [];
        const currentOperacaoItem = (operacaoItens as Array<any>).find(
          (op: any) => op.id === operacaoItemEditando.id
        );
        const currentOperacaoItemIndex = (operacaoItens as Array<any>).indexOf(
          currentOperacaoItem
        );

        operacaoItens.splice(currentOperacaoItemIndex, 1);
        operacaoItens.splice(currentOperacaoItemIndex, 0, newOperacaoItem);

        setValue('operacaoItens', [...operacaoItens]);

        setOperacaoItemEditando(undefined);
        setInformacoesProdutoAtual(undefined);
      }
    }

    setIsLoading(false);
  };

  async function sendChangeLocalStock({
    campo,
    id,
    conteudoCampo,
    tela,
  }: AlterarLocalEstoque) {
    const data = {
      campo,
      id,
      conteudoCampo,
      tela,
    } as AlterarLocalEstoque;
    const response = await api.put<void, ResponseApi<string>>(
      ConstanteEnderecoWebservice.PEDIDOORCAMENTOVENDA_ALTERAR,
      {
        ...data,
      }
    );

    if (response.avisos) {
      response.avisos.forEach((avisos) => toast.warn(avisos));
    }

    if (response.sucesso) {
      ultimoEstoqueSelecionado.current = localDeEstoqueSelecionado;
    }
  }

  const getDadosDoProduto = useCallback(async (produto: OptionType) => {
    const outrosDadosProduto = await obterProdutosLancamento(produto);

    if (outrosDadosProduto) {
      const tamanhosFiltered = outrosDadosProduto.tamanhos.filter(
        (itemTamanho) => !itemTamanho.padraoSistema
      );

      setInformacoesProdutoAtual({
        ...outrosDadosProduto,
        nome: produto.label.split('|')[0],
        tamanhos:
          tamanhosFiltered.length > 0
            ? tamanhosFiltered
            : outrosDadosProduto.tamanhos,
      });
    }
  }, []);

  const handleSubmit = formMethods.handleSubmit(
    async ({ valorTotal, ...data }) => {
      onModalSelectProdutoClose();

      if (possuiMovimentacaoFinanceira) {
        toast.warning(
          'Para adicionar um novo item é necessário remover o pagamento adicionado.'
        );
        return;
      }

      if (valorTotal <= 0) {
        toast.warning('Não é possível adicionar um item sem valor.');
        return;
      }

      if (operacaoItemEditando) {
        if (
          (data.descontoItem !== operacaoItemEditando.descontoItem ||
            data.tipoDescontoItem !== operacaoItemEditando.tipoDescontoItem) &&
          !permissaoConcederAcrescimosDescontos.permitido
        ) {
          setModalAutorizacaoDescontoAcrescimoIsOpen(true);

          return;
        }

        handlePutOperacaoItem({
          ...data,
          volumeUnitario: operacaoItemEditando.volumeUnitario,
        } as OperacaoItemObter);
      } else if (informacoesProdutoAtual) {
        if (
          data.descontoItem !== 0 &&
          !permissaoConcederAcrescimosDescontos.permitido
        ) {
          setModalAutorizacaoDescontoAcrescimoIsOpen(true);

          return;
        }

        const { nome: tamanhoNome = '' } =
          informacoesProdutoAtual.tamanhos &&
          informacoesProdutoAtual.tamanhos.length > 0
            ? informacoesProdutoAtual.tamanhos.find(
                (tamanho) => tamanho.id === produtoCorTamanhoId
              ) || {}
            : {};

        const dataToBeSendForApiChangeLocalStock = {
          conteudoCampo: localDeEstoqueSelecionado.id,
          campo: OperacaoCamposAlterarEnum.LOCAL_ESTOQUE,
          tela: LogAuditoriaTelaEnum.PDV,
          id: operacaoId,
        } as AlterarLocalEstoque;

        if (userChangedTheLocaleStock && operacaoId)
          await sendChangeLocalStock(dataToBeSendForApiChangeLocalStock);

        await handleCreateOperacaoItem({
          ...data,
          produto: informacoesProdutoAtual.nome || '',
          cor: informacoesProdutoAtual.cor || '',
          tamanho: tamanhoNome,
          foto: informacoesProdutoAtual.foto || '',
          volumeUnitario: informacoesProdutoAtual.volumeUnitario,
          solicitarInformacaoComplementar:
            informacoesProdutoAtual.solicitarInformacaoComplementar,
        } as OperacaoItemObter);
      }
    }
  );

  const handleConfirmarPermissaoDesconto = () => {
    const { valorTotal, ...data } = formMethods.getValues();

    if (operacaoItemEditando) {
      handlePutOperacaoItem({ ...data } as OperacaoItemObter);
    } else if (informacoesProdutoAtual) {
      const { nome: tamanhoNome = '' } =
        informacoesProdutoAtual.tamanhos &&
        informacoesProdutoAtual.tamanhos.length > 0
          ? informacoesProdutoAtual.tamanhos.find(
              (tamanho) => tamanho.id === produtoCorTamanhoId
            ) || {}
          : {};

      handleCreateOperacaoItem({
        ...data,
        produto: informacoesProdutoAtual.nome || '',
        cor: informacoesProdutoAtual.cor || '',
        tamanho: tamanhoNome,
        foto: informacoesProdutoAtual.foto || '',
        volumeUnitario: informacoesProdutoAtual.volumeUnitario,
        solicitarInformacaoComplementar:
          informacoesProdutoAtual.solicitarInformacaoComplementar,
      } as OperacaoItemObter);
    }
  };

  function handleDecrementarQuantidade() {
    const novaQuantidade = quantidade - 1;

    formMethods.setValue(
      'quantidade',
      novaQuantidade >= 1 ? novaQuantidade : 1
    );
  }

  function handleIncrementarQuantidade() {
    formMethods.setValue('quantidade', quantidade + 1);
  }

  const tamanhoSelectIsVisible = useMemo(() => {
    const tamanhosFiltered = informacoesProdutoAtual?.tamanhos?.filter(
      (item) => !item.padraoSistema
    );
    if (informacoesProdutoAtual && (tamanhosFiltered || []).length > 0)
      return true;

    return false;
  }, [informacoesProdutoAtual]);

  const latestProps = useRef({
    formMethods,
    tamanhoSelectIsVisible,
    chavePermissaoTemporaria,
    operacaoItemEditando,
    setFocus: formMethods.setFocus,
  });

  useEffect(() => {
    latestProps.current = {
      formMethods,
      tamanhoSelectIsVisible,
      chavePermissaoTemporaria,
      operacaoItemEditando,
      setFocus: formMethods.setFocus,
    };
  });

  useEffect(() => {
    if (informacoesProdutoAtual) {
      if (informacoesProdutoAtual.tamanhos.length === 1) {
        latestProps.current.formMethods.reset({
          ...defaultValues,
          volumeUnitario: informacoesProdutoAtual.volumeUnitario,
          ...(operacaoItemEditando
            ? {
                ...operacaoItemEditando,
                valorTotal: operacaoItemEditando.valorItemComDesconto,
                volumeUnitario: operacaoItemEditando.volumeUnitario,
              }
            : {}),
          produtoCorTamanhoId: informacoesProdutoAtual.tamanhos[0].id,
        });
      } else {
        const defaultProdutoCorTamanhoId = (() => {
          if (operacaoItemEditando?.tamanho) {
            return operacaoItemEditando.produtoCorTamanhoId;
          }
          if (
            informacoesProdutoAtual.tamanhos.length > 0 &&
            !informacoesProdutoAtual.tamanhos[0].padraoSistema
          ) {
            return informacoesProdutoAtual.tamanhos[0].id;
          }
          return defaultValues.produtoCorTamanhoId;
        })();

        latestProps.current.formMethods.reset({
          ...defaultValues,
          ...(operacaoItemEditando
            ? {
                ...operacaoItemEditando,
                valorTotal: operacaoItemEditando.valorItemComDesconto,
              }
            : {}),
          produtoCorTamanhoId: defaultProdutoCorTamanhoId,
        });
      }
    } else {
      if (isIOS() || reactDeviceDetectIsIOS) {
        if (document.activeElement) {
          (document.activeElement as HTMLInputElement).blur();
        }
      } else if (selectProdutoRef.current && !reactDeviceDetectIsIOS) {
        selectProdutoRef.current.focus();
      }

      latestProps.current.formMethods.reset(defaultValues);
    }
  }, [informacoesProdutoAtual, operacaoItemEditando]);

  const validateCasasDecimais = useCallback(async () => {
    let casasDecimaisDefault = casasDecimais;
    if (!casasDecimais?.casasDecimaisValor) {
      const newCasasDecimais = await obterCasasDecimais();

      if (!newCasasDecimais?.casasDecimaisValor) {
        toast.error('Erro ao obter casas decimais');
        return 2;
      }

      casasDecimaisDefault = newCasasDecimais;
    }

    return casasDecimaisDefault.casasDecimaisValor;
  }, [casasDecimais, obterCasasDecimais]);

  const handleObterPrecoProduto = useCallback(async () => {
    setIsLoading(true);

    if (!tabelaPrecoId) {
      return;
    }

    const casasDecimaisValor = await validateCasasDecimais();
    const precoProdutoValorUnitario = await obterPrecoProduto(
      produtoCorTamanhoId,
      tabelaPrecoId
    );
    const precoValorUnitarioFormatado =
      Number(precoProdutoValorUnitario?.toFixed(casasDecimaisValor)) || 0;

    latestProps.current.formMethods.setValue(
      'valorUnitario',
      precoValorUnitarioFormatado
    );

    latestProps.current.formMethods.setValue(
      'valorTotal',
      precoValorUnitarioFormatado
    );

    defaultValues.valorTotal = precoValorUnitarioFormatado;
    defaultValues.valorUnitario = precoValorUnitarioFormatado;

    setIsLoading(false);
  }, [produtoCorTamanhoId, tabelaPrecoId, validateCasasDecimais]);

  const validandoValoresProduto = useCallback(() => {
    if (produtoCorTamanhoId) {
      if (
        latestProps.current.operacaoItemEditando &&
        latestProps.current.operacaoItemEditando.produtoCorTamanhoId ===
          produtoCorTamanhoId
      ) {
        latestProps.current.formMethods.setValue(
          'valorUnitario',
          latestProps.current.operacaoItemEditando.valorUnitario.toFixed(
            casasDecimais.casasDecimaisValor
          )
        );

        return;
      }

      handleObterPrecoProduto();
    }
  }, [casasDecimais, handleObterPrecoProduto, produtoCorTamanhoId]);

  useEffect(() => {
    const valorItemSemDesconto = calcularValorItemSemDesconto(
      quantidade,
      valorUnitario
    );

    const valorDescontoItem = calcularDescontoItem(
      tipoDescontoItem,
      descontoItem,
      valorItemSemDesconto
    );

    if (valorItemSemDesconto < valorDescontoItem) {
      if (tipoDescontoItem === TipoValorEnum.PORCENTAGEM) {
        latestProps.current.formMethods.setValue('descontoItem', 100);
      } else {
        latestProps.current.formMethods.setValue(
          'descontoItem',
          valorItemSemDesconto.toFixed(2)
        );
      }

      return;
    }

    const valorItemComDesconto = calcularValorItemComDesconto(
      quantidade,
      valorUnitario,
      tipoDescontoItem,
      descontoItem
    );

    latestProps.current.formMethods.setValue(
      'valorTotal',
      valorItemComDesconto.toFixed(2)
    );
  }, [
    quantidade,
    valorUnitario,
    descontoItem,
    tipoDescontoItem,
    calcularValorItemComDesconto,
  ]);

  useEffect(() => {
    const valorItemComDesconto = calcularValorItemComDesconto(
      quantidade,
      valorUnitario,
      tipoDescontoItem,
      descontoItem
    );

    formMethods.setValue('valorTotal', valorItemComDesconto.toFixed(2));
  }, [
    quantidade,
    formMethods,
    valorUnitario,
    descontoItem,
    tipoDescontoItem,
    calcularValorItemComDesconto,
  ]);

  useEffect(() => {
    const handleObterInformacoesOperacaoItemEditando = async () => {
      if (operacaoItemEditando) {
        setIsLoading(true);

        const response = await api.get<
          void,
          ResponseApi<OperacaoItemObterInformacoesAlterar>
        >(
          ConstanteEnderecoWebservice.PEDIDOORCAMENTOVENDA_ITEM_OBTER_INFORMACOES_ALTERAR,
          {
            params: { id: operacaoItemEditando.id },
            ...getHeaderChaveTemporaria(
              latestProps.current.chavePermissaoTemporaria
            ),
          }
        );

        if (response) {
          if (response.avisos) {
            response.avisos.map((item: string) => toast.warning(item));
          }

          if (response.sucesso && response.dados) {
            latestProps.current.formMethods.reset({
              produtoCorTamanhoId: operacaoItemEditando.produtoCorTamanhoId,
              quantidade: operacaoItemEditando.quantidade,
              tipoDescontoItem: operacaoItemEditando.tipoDescontoItem,
              valorUnitario: operacaoItemEditando.valorUnitario,
              descontoItem: operacaoItemEditando.descontoItem,
              valorTotal: operacaoItemEditando.valorItemComDesconto,
            });
            setInformacoesProdutoAtual({
              nome: operacaoItemEditando.produto,
              adicionarItemAutomaticamente: false,
              volumeUnitario: !!operacaoItemEditando.valorUnitario,
              cor: operacaoItemEditando.cor || '',
              foto: response.dados.foto,
              permiteAlterarValorNaVenda:
                response.dados.permiteAlterarValorNaVenda,
              tamanhos: response.dados.tamanhos,
              solicitarInformacaoComplementar: false,
              itemConsignado: response.dados.itemConsignado,
              tipoProduto: response.dados.tipoProduto,
            });
          }
        }

        setIsLoading(false);
      }
    };

    if (operacaoItemEditando) {
      handleObterInformacoesOperacaoItemEditando();
    }
  }, [operacaoItemEditando]);

  useEffect(() => {
    (async () => {
      if (!operacaoIsLoading) {
        blurIOS(() => {
          if (selectProdutoRef.current) {
            selectProdutoRef.current.focus();
          }
        });
      }
    })();
  }, [operacaoIsLoading]);

  useEffect(
    function setFocusWhenChangingProduct() {
      if (informacoesProdutoAtual) {
        setTimeout(() => {
          try {
            if (latestProps.current.tamanhoSelectIsVisible) {
              latestProps.current.setFocus('produtoCorTamanhoId');
            } else if (!isMobile) {
              latestProps.current.setFocus('quantidade');
            }
          } catch {
            // continue regardless of error
          }
        }, 0);
      }
    },
    [informacoesProdutoAtual]
  );

  const handleProdutoAlterado = useCallback(() => {
    setProdutoFoiAlterado((valorAnterior) => [
      ...valorAnterior,
      { id: produtoCorTamanhoId },
    ]);
  }, [produtoCorTamanhoId]);

  useEffect(() => {
    const valueUltimoProdutoAdicionado =
      produtoFoiAlterado &&
      produtoFoiAlterado[produtoFoiAlterado.length - 1] &&
      produtoFoiAlterado[produtoFoiAlterado.length - 1].id
        ? produtoFoiAlterado[produtoFoiAlterado.length - 1].id
        : '';

    if (
      valueUltimoProdutoAdicionado ===
      formMethods.getValues().produtoCorTamanhoId
    ) {
      validandoValoresProduto();
    }
  }, [formMethods, produtoFoiAlterado, validandoValoresProduto]);

  const scale = (() => {
    if (operacaoItemEditando && operacaoItemEditando.volumeUnitario) {
      return 0;
    }

    if (operacaoItemEditando && !operacaoItemEditando.volumeUnitario) {
      return casasDecimais.casasDecimaisQuantidade;
    }

    if (informacoesProdutoAtual && informacoesProdutoAtual.volumeUnitario) {
      return 0;
    }

    return casasDecimais.casasDecimaisQuantidade;
  })();

  useEffect(() => {
    validandoValoresProduto();
  }, [validandoValoresProduto]);

  return (
    <>
      {viewIsLoading && <LoadingPadrao />}
      {operacaoItemEditando && isLargerThan900 && <Overlay />}
      <VStack
        bg="gray.50"
        spacing={6}
        pl={{ base: 6, md: 12 }}
        pr={6}
        py={6}
        w="full"
        sx={{ '& .chakra-fade': { w: 'full' } }}
        position="relative"
        zIndex={operacaoItemEditando && isLargerThan900 ? 'modal' : 'unset'}
      >
        <SelectProdutoProvider
          handleCreateOperacaoItem={handleCreateOperacaoItem}
          onChange={(novoProdutoSendoAdicionado: {
            value: string;
            label: string;
          }) => {
            getDadosDoProduto(novoProdutoSendoAdicionado as OptionType);

            onModalSelectProdutoClose();
          }}
        >
          <SelectProdutoContext.Consumer>
            {({ onScannedCodeBar, setInputValue }) => {
              return (
                <>
                  {asMobileView && (
                    <Flex
                      w="full"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Button
                        variant="link"
                        colorScheme="purple"
                        p="1"
                        py="0"
                        fontWeight="normal"
                        leftIcon={
                          <Icon as={SalvarInserirNovoIcon} boxSize="5" />
                        }
                        onClick={onModalSelectProdutoOpen}
                        isDisabled={possuiMovimentacaoFinanceira}
                      >
                        Adicionar produto
                      </Button>

                      <IconButton
                        aria-label="Leitor de códigos de barras"
                        icon={<Icon as={LeitorCodigoBarrasIcon} boxSize="6" />}
                        variant="link"
                        minW="0"
                        p="0"
                        borderRadius="sm"
                        onClick={onModalCodigoBarrasOpen}
                        isDisabled={possuiMovimentacaoFinanceira}
                      />

                      <ModalCodigoBarras
                        isOpen={isModalCodigoBarrasOpen}
                        onClose={onModalCodigoBarrasClose}
                        onCodigoBarrasScanned={async (codigoBarras) => {
                          const { hasOptions } = await onScannedCodeBar(
                            codigoBarras
                          );

                          if (hasOptions) {
                            setInputValue(codigoBarras);

                            onModalSelectProdutoOpen();
                            selectProdutoRef.current?.openMenu();
                          }

                          onModalCodigoBarrasClose();
                        }}
                      />
                    </Flex>
                  )}

                  <MobileSelectWrapperModal
                    asMobileView={asMobileView}
                    isOpen={isModalSelectProdutoOpen}
                    onClose={onModalSelectProdutoClose}
                    initialFocusRef={selectProdutoRef}
                    isLoading={viewIsLoading}
                  >
                    {() =>
                      operacaoItemEditando ? (
                        <CampoContainer
                          name="produtoDescricao"
                          id="produtoDescricao"
                        >
                          <Input
                            isDisabled
                            size={isLargerThan900 ? 'lg' : 'md'}
                            value={`${operacaoItemEditando.produto}${
                              operacaoItemEditando.cor
                                ? ` | ${operacaoItemEditando.cor}`
                                : ''
                            }`}
                            _disabled={{
                              background: 'gray.50',
                              boxShadow: '0px 0px 4px #00000029',
                            }}
                          />
                        </CampoContainer>
                      ) : (
                        <Flex width="full">
                          <Box
                            w={isLargerThan900 ? 'full' : 'calc(100% - 64px)'}
                          >
                            <SelectProduto
                              ref={selectProdutoRef}
                              value={informacoesProdutoAtual}
                              autoFocus={!asMobileView}
                              onClick={() => handleProdutoAlterado()}
                              mobileViewAlreadyWrapped={asMobileView}
                            />
                          </Box>
                          {!isLargerThan900 && (
                            <IconButton
                              aria-label="Leitor de códigos de barras"
                              icon={
                                <Icon as={LeitorCodigoBarrasIcon} boxSize="6" />
                              }
                              variant="link"
                              borderRadius="sm"
                              onClick={onModalCodigoBarrasOpen}
                              isDisabled={possuiMovimentacaoFinanceira}
                            />
                          )}
                        </Flex>
                      )
                    }
                  </MobileSelectWrapperModal>
                </>
              );
            }}
          </SelectProdutoContext.Consumer>
        </SelectProdutoProvider>

        <FormProvider {...formMethods}>
          <Fade
            in={!!informacoesProdutoAtual || !!operacaoItemEditando}
            unmountOnExit
          >
            <ModalAdicionarEditarItem
              asMobileView={asMobileView}
              title={
                informacoesProdutoAtual
                  ? `${informacoesProdutoAtual.nome}${
                      informacoesProdutoAtual.cor
                        ? ` - ${informacoesProdutoAtual.cor}`
                        : ''
                    }`
                  : operacaoItemEditando
                  ? `${operacaoItemEditando.produto}${
                      operacaoItemEditando.cor
                        ? ` - ${operacaoItemEditando.cor}`
                        : ''
                    }`
                  : ''
              }
              isOpen={!!informacoesProdutoAtual || !!operacaoItemEditando}
              onClose={() => {
                setOperacaoItemEditando(undefined);
                setInformacoesProdutoAtual(undefined);
              }}
            >
              <Box
                bg="white"
                h="100%"
                w="100%"
                borderRadius="md"
                boxShadow="0px 0px 4px 0px #00000029"
                p={{ base: 6, md: 8 }}
                position="relative"
              >
                <form onSubmit={handleSubmit}>
                  <ManterFoco
                    style={{
                      width: '100%',
                      height: '100%',
                    }}
                  >
                    <VStack spacing={6} w="full">
                      <Box h="100%">
                        <Grid
                          templateColumns={{
                            base: '1fr auto',
                            sm: '1fr 1fr auto',
                            md: 'auto 1fr auto',
                          }}
                          w="full"
                          h="fit-content"
                          gap={{ base: 4, lg: 3, xl: 6 }}
                          justifyItems="end"
                        >
                          <GridItem
                            colStart={asMobileView ? { base: 2, sm: 3 } : 1}
                            rowSpan={{
                              base: 2,
                              md: 5,
                            }}
                          >
                            <AspectRatio
                              w={{
                                base: '118px',
                                md: '130px',
                                lg: '150px',
                                xl: '315px',
                              }}
                              ratio={1}
                            >
                              <Flex
                                justifyContent="center"
                                alignItems="center"
                                minH="100%"
                                w="100%"
                                minW="106px"
                                maxW={{
                                  base: '118px',
                                  md: '130px',
                                  lg: '150px',
                                  xl: '315px',
                                }}
                                borderRadius="md"
                                border="1px"
                                borderColor="gray.200"
                                overflow="hidden"
                                mt={{ base: '18px', md: '0' }}
                              >
                                <AspectRatio w="100%" ratio={1}>
                                  {informacoesProdutoAtual &&
                                  informacoesProdutoAtual.foto ? (
                                    <Image
                                      boxSize="500px"
                                      objectFit="cover"
                                      src={informacoesProdutoAtual.foto || ''}
                                      alt={informacoesProdutoAtual.nome || ''}
                                    />
                                  ) : (
                                    <Flex
                                      h="full"
                                      alignItems="center"
                                      justifyContent="center"
                                      p={6}
                                    >
                                      <Icon
                                        as={ProdutoSemImagemIcon}
                                        color="gray.700"
                                        h="full"
                                        w="full"
                                        maxW={20}
                                      />
                                    </Flex>
                                  )}
                                </AspectRatio>
                              </Flex>
                            </AspectRatio>
                          </GridItem>

                          {tamanhoSelectIsVisible && (
                            <>
                              {!asMobileView && (
                                <Flex
                                  justifyContent="flex-end"
                                  alignItems="center"
                                >
                                  <Text
                                    as="label"
                                    fontSize={{
                                      base: 'xs',
                                      xl: 'md',
                                    }}
                                    mb={0}
                                    htmlFor="produtoCorTamanhoId"
                                  >
                                    Tamanho
                                  </Text>
                                </Flex>
                              )}

                              <GridItem
                                colStart={asMobileView ? 1 : 3}
                                rowStart={1}
                                w="full"
                              >
                                <Flex
                                  alignItems="center"
                                  w="full"
                                  minW={{
                                    base: '105px',
                                    md: '128px',
                                  }}
                                  maxW={
                                    asMobileView
                                      ? 'full'
                                      : { base: 'full', md: '180px' }
                                  }
                                >
                                  <SelectPadrao
                                    id="produtoCorTamanhoId"
                                    name="produtoCorTamanhoId"
                                    label={asMobileView ? 'Tamanho' : ''}
                                    options={
                                      informacoesProdutoAtual
                                        ? informacoesProdutoAtual.tamanhos.map(
                                            (tamanho) => ({
                                              label: tamanho.nome,
                                              value: tamanho.id,
                                            })
                                          )
                                        : []
                                    }
                                    placeholder="Selecionar"
                                    size={
                                      asMobileView
                                        ? 'lg'
                                        : !isLargerThan1800
                                        ? 'md'
                                        : 'lg'
                                    }
                                    isSearchable={false}
                                    styles={{
                                      valueContainer: (provided: any) => ({
                                        ...provided,
                                        fontSize: isLargerThan900
                                          ? fontSizes.md
                                          : fontSizes.sm,
                                      }),
                                    }}
                                    autoFocus
                                  />
                                </Flex>
                              </GridItem>
                            </>
                          )}
                          {!asMobileView && (
                            <Flex justifyContent="flex-end" alignItems="center">
                              <Text
                                as="label"
                                fontSize={{ base: 'xs', xl: 'md' }}
                                mb={0}
                                htmlFor="quantidade"
                                fontWeight="semibold"
                                color="gray.700"
                                lineHeight="none"
                              >
                                Quantidade
                              </Text>
                            </Flex>
                          )}
                          <GridItem
                            colStart={
                              asMobileView
                                ? tamanhoSelectIsVisible
                                  ? { base: 1, sm: 2 }
                                  : { base: 1, sm: 1 }
                                : 3
                            }
                            rowStart={
                              tamanhoSelectIsVisible
                                ? { base: 2, sm: 1, md: 2 }
                                : 1
                            }
                            w="full"
                          >
                            {asMobileView && (
                              <Text
                                as="label"
                                fontSize="sm"
                                mb={0}
                                htmlFor="quantidade"
                                fontWeight="semibold"
                                color="gray.700"
                                lineHeight="1"
                              >
                                Quantidade
                              </Text>
                            )}
                            <HStack
                              spacing="0.5"
                              alignItems="flex-end"
                              w="full"
                              minW={{ base: '105px', md: '128px' }}
                              maxW={{ base: 'full', md: '180px' }}
                              mb={asMobileView ? '2' : '0'}
                            >
                              {isLargerThan360 && (
                                <IconButton
                                  aria-label="Decrementar"
                                  icon={<Icon as={FiMinus} />}
                                  variant="outline"
                                  _focus={{ bg: 'transparent' }}
                                  _hover={{ bg: 'transparent' }}
                                  _active={{ bg: 'gray.100' }}
                                  borderRadius="md"
                                  size={
                                    asMobileView
                                      ? 'lg'
                                      : !isLargerThan1800
                                      ? 'md'
                                      : 'lg'
                                  }
                                  w="full"
                                  minW={{ base: '1px', sm: 9 }}
                                  maxW={
                                    asMobileView
                                      ? '10'
                                      : !isLargerThan1800
                                      ? '9'
                                      : '10'
                                  }
                                  onClick={handleDecrementarQuantidade}
                                  isDisabled={
                                    quantidade === 1 ||
                                    informacoesProdutoAtual?.itemConsignado
                                  }
                                />
                              )}

                              <NumberInput
                                id="quantidade"
                                isDisabled={
                                  informacoesProdutoAtual?.itemConsignado
                                }
                                name="quantidade"
                                errorMessageStyleProps={{
                                  whiteSpace: 'nowrap',
                                  transform: 'translateX(-42px)',
                                }}
                                pattern="^[0-9.,]+$"
                                scale={scale}
                                size={
                                  asMobileView
                                    ? 'lg'
                                    : !isLargerThan1800
                                    ? 'md'
                                    : 'lg'
                                }
                                fontSize={{ base: 'sm', md: 'md' }}
                                autoFocus={!tamanhoSelectIsVisible && !isMobile}
                                minW="80px"
                              />

                              {isLargerThan360 && (
                                <IconButton
                                  aria-label="Incrementar"
                                  icon={<Icon as={FiPlus} />}
                                  variant="outline"
                                  _focus={{ bg: 'transparent' }}
                                  isDisabled={
                                    informacoesProdutoAtual?.itemConsignado
                                  }
                                  _hover={{ bg: 'transparent' }}
                                  _active={{ bg: 'gray.100' }}
                                  borderRadius="md"
                                  size={
                                    asMobileView
                                      ? 'lg'
                                      : !isLargerThan1800
                                      ? 'md'
                                      : 'lg'
                                  }
                                  w="full"
                                  minW={{ base: '1px', sm: 9 }}
                                  maxW={
                                    asMobileView
                                      ? '10'
                                      : !isLargerThan1800
                                      ? '9'
                                      : '10'
                                  }
                                  onClick={handleIncrementarQuantidade}
                                />
                              )}
                            </HStack>
                          </GridItem>
                          {!asMobileView && (
                            <Flex
                              mt={
                                formMethods.formState.errors.quantidade ===
                                undefined
                                  ? ''
                                  : '10px'
                              }
                              justifyContent="flex-end"
                              alignItems="center"
                              minW="80px"
                            >
                              <Text
                                as="label"
                                fontSize={{ base: 'xs', xl: 'md' }}
                                mb={0}
                                htmlFor="valorUnitario"
                                whiteSpace="nowrap"
                                fontWeight="semibold"
                                color="gray.700"
                              >
                                Valor unitário
                              </Text>
                            </Flex>
                          )}
                          <GridItem
                            colSpan={{
                              base: tamanhoSelectIsVisible ? 2 : 1,
                              sm: 1,
                            }}
                            rowStart={
                              asMobileView
                                ? {
                                    base: tamanhoSelectIsVisible ? 3 : 2,
                                    sm: tamanhoSelectIsVisible ? 2 : 1,
                                  }
                                : undefined
                            }
                            w="full"
                          >
                            <Flex
                              mt={
                                formMethods.formState.errors.quantidade ===
                                undefined
                                  ? ''
                                  : '10px'
                              }
                              alignItems="center"
                              w="full"
                              minW={{ base: '105px', md: '128px' }}
                              maxW={{ base: 'full', md: '180px' }}
                            >
                              <InputNumber
                                id="valorUnitario"
                                name="valorUnitario"
                                label={asMobileView ? 'Valor unitário' : ''}
                                prefix="R$"
                                pattern="(^[\d-\)\(]+$)"
                                scale={casasDecimais?.casasDecimaisValor}
                                variant={
                                  naoPermitirAlterarValorNaVenda
                                    ? 'none'
                                    : 'outline'
                                }
                                bg={
                                  naoPermitirAlterarValorNaVenda
                                    ? 'gray.100'
                                    : 'white'
                                }
                                disabled={naoPermitirAlterarValorNaVenda}
                                size={
                                  asMobileView
                                    ? 'lg'
                                    : !isLargerThan1800
                                    ? 'md'
                                    : 'lg'
                                }
                                fontSize={{ base: 'sm', md: 'md' }}
                              />
                            </Flex>
                          </GridItem>
                          {!asMobileView && (
                            <Flex justifyContent="flex-end" alignItems="center">
                              <Text
                                as="label"
                                fontSize={{ base: 'xs', xl: 'md' }}
                                mb={0}
                                htmlFor="descontoItem"
                                fontWeight="semibold"
                                color="gray.700"
                              >
                                Desconto
                              </Text>
                            </Flex>
                          )}
                          <GridItem
                            position="relative"
                            colSpan={{ base: 2, sm: 1 }}
                            w="full"
                            mb={
                              valorDesconto === 0 && asMobileView ? '' : '10px'
                            }
                          >
                            <Flex
                              alignItems="center"
                              direction="column"
                              w="full"
                              minW={{ base: '105px', md: '128px' }}
                              maxW={{ base: 'full', md: '180px' }}
                            >
                              {asMobileView && (
                                <Flex
                                  justifyContent="flex-start"
                                  alignItems="center"
                                  w="full"
                                >
                                  <Text
                                    as="label"
                                    mb={0}
                                    fontSize="sm"
                                    htmlFor="descontoItem"
                                    fontWeight="semibold"
                                    color="gray.700"
                                  >
                                    Desconto
                                  </Text>
                                </Flex>
                              )}

                              <HStack spacing={2} w="full">
                                <Box
                                  sx={{
                                    '& div.react-select__control div.react-select__value-container div.react-select__single-value': {
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      h: 'full',
                                      ml: 0,
                                      mr: 0,
                                      maxW: 'none',
                                      position: 'sticky',
                                      top: 0,
                                      transform: 'none',
                                      fontSize: 'xs',
                                    },
                                  }}
                                >
                                  <ButtonItemDesconto
                                    id="tipoDescontoItem"
                                    name="tipoDescontoItem"
                                    size={
                                      asMobileView
                                        ? 'lg'
                                        : !isLargerThan1800
                                        ? 'md'
                                        : 'lg'
                                    }
                                    fontSize={{
                                      base: 'xs',
                                      xl: 'md',
                                    }}
                                    bg="white"
                                    variant="outline"
                                    borderColor="gray.200"
                                    _hover={{
                                      backgroundColor: 'none',
                                    }}
                                  />
                                </Box>
                                <InputNumber
                                  id="descontoItem"
                                  name="descontoItem"
                                  max={
                                    tipoDescontoItem ===
                                    TipoValorEnum.PORCENTAGEM
                                      ? 100
                                      : quantidade * valorUnitario
                                  }
                                  size={
                                    asMobileView
                                      ? 'lg'
                                      : !isLargerThan1800
                                      ? 'md'
                                      : 'lg'
                                  }
                                  pattern="(^[\d-\)\(]+$)"
                                  fontSize={{ base: 'sm', md: 'md' }}
                                />
                              </HStack>
                            </Flex>
                            {valorDesconto !== 0 && (
                              <Text
                                position="absolute"
                                textAlign="right"
                                fontSize="10px"
                                mt="5px"
                              >{`Valor do desconto: ${moneyMask(
                                valorDesconto,
                                true
                              )}`}</Text>
                            )}
                          </GridItem>

                          {!asMobileView && (
                            <Flex justifyContent="flex-end" alignItems="center">
                              <Text
                                as="label"
                                fontSize={{ base: 'xs', xl: 'md' }}
                                mb={0}
                                htmlFor="valorTotal"
                                fontWeight="semibold"
                                color="gray.700"
                              >
                                Valor total
                              </Text>
                            </Flex>
                          )}
                          <GridItem colSpan={{ base: 2, sm: 1 }} w="full">
                            <Flex
                              alignItems="center"
                              w="full"
                              minW={{ base: '105px', md: '128px' }}
                              maxW={{ base: 'full', md: '180px' }}
                            >
                              <NumberInput
                                id="valorTotal"
                                name="valorTotal"
                                label={asMobileView ? 'Valor total' : ''}
                                prefix="R$"
                                onChange={() => {}}
                                isDisabled
                                scale={2}
                                size={
                                  asMobileView
                                    ? 'lg'
                                    : !isLargerThan1800
                                    ? 'md'
                                    : 'lg'
                                }
                                fontSize={{ base: 'sm', md: 'md' }}
                              />
                            </Flex>
                          </GridItem>
                          {asMobileView && !operacaoItemEditando && (
                            <GridItem
                              colStart={{
                                base: 1,
                                sm: tamanhoSelectIsVisible ? 2 : 1,
                              }}
                              colSpan={tamanhoSelectIsVisible ? 2 : 3}
                              w="full"
                            >
                              <Flex
                                alignItems="flex-end"
                                w="full"
                                h="full"
                                pt={{ base: 4, sm: 0 }}
                              >
                                <ButtonDefault
                                  colorScheme="primary"
                                  width="full"
                                  color="white"
                                  isLoading={viewIsLoading}
                                  _focus={{
                                    bg: 'secondary.400',
                                    color: 'black',
                                  }}
                                  type="submit"
                                  onClick={() => {
                                    if (vendedor.nome === undefined) {
                                      toast.warning(
                                        'Nenhum vendedor foi selecionado'
                                      );
                                    }
                                  }}
                                  size={asMobileView ? 'lg' : 'md'}
                                >
                                  Confirmar item
                                </ButtonDefault>
                              </Flex>
                            </GridItem>
                          )}
                        </Grid>
                      </Box>

                      {!operacaoItemEditando && !asMobileView && (
                        <ButtonDefault
                          colorScheme="primary"
                          width="full"
                          type="submit"
                          isLoading={viewIsLoading}
                          color="white"
                          _focus={{
                            bg: 'secondary.400',
                            color: 'black',
                          }}
                          onClick={() => {
                            if (vendedor.nome === undefined) {
                              toast.warning('Nenhum vendedor foi selecionado');
                            }
                          }}
                          size={asMobileView ? 'lg' : 'md'}
                        >
                          Confirmar item
                        </ButtonDefault>
                      )}
                      {operacaoItemEditando && asMobileView && (
                        <Stack
                          direction={{ base: 'column', sm: 'row' }}
                          spacing="4"
                          w="full"
                        >
                          <Button
                            variant="outline"
                            onClick={() => {
                              setOperacaoItemEditando(undefined);
                              setInformacoesProdutoAtual(undefined);
                            }}
                            size="lg"
                            w="full"
                          >
                            Cancelar
                          </Button>
                          <Button
                            colorScheme="primary"
                            onClick={handleSubmit}
                            size="lg"
                            isLoading={viewIsLoading}
                            w="full"
                          >
                            Confirmar alteração
                          </Button>
                        </Stack>
                      )}
                    </VStack>
                  </ManterFoco>
                </form>
              </Box>
            </ModalAdicionarEditarItem>
          </Fade>
          {operacaoItemEditando && (
            <HStack spacing={3}>
              <Button
                variant="outline"
                onClick={() => {
                  setOperacaoItemEditando(undefined);
                  setInformacoesProdutoAtual(undefined);
                }}
                size={asMobileView ? 'lg' : 'md'}
              >
                Cancelar
              </Button>
              <Button
                colorScheme="primary"
                isLoading={viewIsLoading}
                onClick={handleSubmit}
                size={asMobileView ? 'lg' : 'md'}
              >
                Confirmar alteração
              </Button>
            </HStack>
          )}
        </FormProvider>
      </VStack>

      <ModalAutorizacaoFuncionalidade
        isOpen={modalAutorizacaoDescontoAcrescimoIsOpen}
        setIsOpen={setModalAutorizacaoDescontoAcrescimoIsOpen}
        autorizado={handleConfirmarPermissaoDesconto}
        titulo={
          ConstanteFuncionalidades.PDV_CONCEDER_ACRESCIMOS_DESCONTOS.titulo
        }
        descricao={
          ConstanteFuncionalidades.PDV_CONCEDER_ACRESCIMOS_DESCONTOS.descricao
        }
        tela={LogAuditoriaTelaEnum.PDV}
        permissoes={[
          ConstanteFuncionalidades.PDV_CONCEDER_ACRESCIMOS_DESCONTOS.codigo,
        ]}
      />
    </>
  );
};

export default AdicionarEditarItem;
