import { useState } from 'react';
import {
  Tabs,
  Flex,
  TabList,
  Tab,
  Box,
  Text,
  Icon,
  TabPanels,
  TabPanel,
} from '@chakra-ui/react';
import { FiChevronRight } from 'react-icons/fi';

import { styleTabSelected } from 'theme/components/tabs';

import { valueTabs } from '../../validationForms';

export const ItemEcommerce = () => {
  const [tabSelecionada, setTabSelecionada] = useState(0);

  return (
    <Tabs
      onChange={(index) => setTabSelecionada(index)}
      boxShadow="0px 0px 6px #00000034"
      bg="gray.50"
      borderRadius="5px"
      padding="0"
      variant="clean"
    >
      <Flex h="full">
        <TabList mt="16px" w="32%">
          <Box w="100%">
            {valueTabs.map((tabs, index) => (
              <Tab
                _selected={{
                  ...styleTabSelected,
                  background: 'gray.50',
                }}
                h="64px"
                key={tabs.label}
                w="100%"
              >
                <Flex
                  justifyContent="space-between"
                  w="100%"
                  alignItems="center"
                >
                  <Text
                    textDecoration={
                      tabSelecionada === index ? 'underline' : undefined
                    }
                    fontWeight={tabSelecionada === index ? 'bold' : undefined}
                    color={tabSelecionada === index ? 'violet.500' : 'black'}
                  >
                    {tabs.label}
                  </Text>
                  {tabSelecionada === index && (
                    <Icon color="violet.500" as={FiChevronRight} />
                  )}
                </Flex>
              </Tab>
            ))}
          </Box>
        </TabList>

        <TabPanels
          borderLeftWidth="1px"
          borderColor="#5502b260"
          bg="white"
          borderRadius="0 5px 5px 0"
          w="full"
          pb="10px"
        >
          {valueTabs.map((tabs) => (
            <TabPanel
              pb="10px"
              key={tabs.label}
              pl="35px"
              mt="6px"
              w="full"
              overflowY="auto"
              bg="white"
            >
              {tabs.component}
            </TabPanel>
          ))}
        </TabPanels>
      </Flex>
    </Tabs>
  );
};
