import React, { useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import {
  Flex,
  IconButton,
  Menu,
  MenuButton,
  useMediaQuery,
  Text,
  Icon,
  MenuList,
  MenuGroup,
  MenuDivider,
  MenuItem,
} from '@chakra-ui/react';
import { FiChevronDown } from 'react-icons/fi';
import { useFormContext } from 'react-hook-form';
import { GlobalHotKeys } from 'react-hotkeys';

import RegraLimiteCreditoEnum from 'constants/enum/regraLimiteCredito';
import { useOperacaoContext } from 'store/PDV/Operacao';

import SelectCliente, {
  SelectClienteRefInterface,
} from 'components/PDV/Select/SelectCliente';
import { CategoriaClienteIcon } from 'pages/PDV/Home/styles';
import ModalAlerta from 'components/PDV/Modal/ModalAlerta';
import NavHeaderMenu from 'components/PDV/Layout/NavHeader/Menu';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import { CadastrosClientesIcon } from 'icons';
import { MobileSelectWrapperModal } from 'components/update/Select/MobileSelect/MobileSelectWrapperModal';

const CadastroCliente = () => {
  const {
    handleChangeCliente,
    setOperacaoIsLoading,
    operacaoIsLoading,
  } = useOperacaoContext();
  const [showTrocarCliente, setShowTrocarCliente] = useState(false);
  const [
    showModalAlertaPossuiMovimentacao,
    setShowModalAlertaPossuiMovimentacao,
  ] = useState(false);

  const { getValues, watch, setValue } = useFormContext();
  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');
  const selectClienteRef = useRef<SelectClienteRefInterface>(
    {} as SelectClienteRefInterface
  );
  const [colorSelectedItem, setColorSelectedItem] = useState('');

  const clienteWatch = watch('cliente');
  const possuiMovimentacaoFinanceiraWatch = watch(
    'possuiMovimentacaoFinanceira'
  );

  const handleAtualizarClienteCadastrado = (nameCliente: string) => {
    setOperacaoIsLoading(true);
    setShowTrocarCliente(false);
    setValue('cliente', nameCliente);
    setOperacaoIsLoading(false);
  };

  useEffect(() => {
    if (clienteWatch) {
      const item = RegraLimiteCreditoEnum.properties.filter(
        (valor) => valor.value === clienteWatch.regraLimiteCredito
      )[0];

      setColorSelectedItem(item?.iconColor);
    }
  }, [clienteWatch]);

  const handlersHotKeys = {
    F3: (event: any) => {
      event.preventDefault();
      selectClienteRef.current.showInformacoesCliente();
    },
    F2: (event: any) => {
      event.preventDefault();

      if (!getValues('possuiMovimentacaoFinanceira')) {
        setShowTrocarCliente(true);
        selectClienteRef?.current?.focus();
      } else {
        setShowModalAlertaPossuiMovimentacao(true);
      }
    },
  };

  const keyMap = {
    F3: ['f3'],
    F2: ['f2'],
  };

  if (!isLargerThan900) {
    return (
      <>
        <NavHeaderMenu
          icon={CadastrosClientesIcon}
          title={clienteWatch?.nome || ''}
          color="secondary.400"
          items={[
            {
              value: 0,
              name: 'Trocar cliente',
              isDisabled: possuiMovimentacaoFinanceiraWatch,
              onClick: () => {
                if (!possuiMovimentacaoFinanceiraWatch) {
                  setShowTrocarCliente(true);
                } else {
                  setShowModalAlertaPossuiMovimentacao(true);
                }
              },
              closeMobileMenuOnClick: false,
            },
            {
              value: 1,
              name: 'Informações',
              onClick: () => {
                selectClienteRef.current.showInformacoesCliente();
              },
              closeMobileMenuOnClick: false,
            },
          ]}
          asAccordion
        />

        <MobileSelectWrapperModal
          isOpen={showTrocarCliente}
          onClose={() => {
            setShowTrocarCliente(false);
          }}
          asMobileView
        >
          {({ onClose }) => (
            <SelectCliente
              id="cliente"
              name="cliente"
              placeholder=""
              required
              size="md"
              habilitarMenu={false}
              handleAtualizarClienteCadastrado={
                handleAtualizarClienteCadastrado
              }
              handleClienteSelect={async (cliente, selecaoFinal = false) => {
                await handleChangeCliente(cliente);
                if (selecaoFinal) {
                  onClose();
                }
              }}
              ref={selectClienteRef}
              autoFocus
              defaultValue={getValues('cliente')}
              show={showTrocarCliente}
              width={isLargerThan900 ? 'lg' : '72'}
              disabled={possuiMovimentacaoFinanceiraWatch}
              blockMobileView
            />
          )}
        </MobileSelectWrapperModal>

        <SelectCliente
          id="cliente"
          name="cliente"
          handleAtualizarClienteCadastrado={handleAtualizarClienteCadastrado}
          placeholder=""
          required
          size="md"
          habilitarMenu={false}
          handleClienteSelect={async (cliente) => {
            await handleChangeCliente(cliente);
          }}
          ref={selectClienteRef}
          autoFocus
          defaultValue={getValues('cliente')}
          show={false}
          width={isLargerThan900 ? 'lg' : '72'}
          disabled={possuiMovimentacaoFinanceiraWatch}
          showMobileButton={false}
        />

        {showModalAlertaPossuiMovimentacao && (
          <ModalAlerta
            isOpen={showModalAlertaPossuiMovimentacao}
            setIsOpen={setShowModalAlertaPossuiMovimentacao}
            textoMensagem="Não é possível trocar o cliente de uma operação com movimentação financeira."
          />
        )}
      </>
    );
  }

  return (
    <>
      {!showTrocarCliente && (
        <GlobalHotKeys handlers={handlersHotKeys} keyMap={keyMap} />
      )}
      {operacaoIsLoading && <LoadingPadrao />}
      <Flex w="full">
        <Flex
          display={!showTrocarCliente ? 'flex' : 'none'}
          maxW={isLargerThan900 ? 'lg' : '72'}
        >
          <Menu placement="bottom-end" colorScheme="system" closeOnSelect>
            <MenuButton
              as={IconButton}
              borderRadius="md"
              icon={
                <>
                  <Text isTruncated color={colorSelectedItem}>
                    {clienteWatch?.nome}
                  </Text>
                  <Icon color={colorSelectedItem} as={FiChevronDown} />
                </>
              }
              variant="ghost"
            />
            <MenuList fontSize="sm">
              <MenuGroup
                title={clienteWatch?.regraLimiteCreditoDescricao}
                as={() => (
                  <Flex
                    color={colorSelectedItem}
                    alignItems="end"
                    paddingLeft={9}
                    paddingBottom={1}
                    paddingRight={3}
                  >
                    <CategoriaClienteIcon />
                    {clienteWatch?.regraLimiteCreditoDescricao}
                  </Flex>
                )}
              >
                <MenuDivider w="88%" margin="auto" borderColor="black" />
                <MenuItem
                  onClick={() => {
                    if (!possuiMovimentacaoFinanceiraWatch) {
                      setShowTrocarCliente(true);
                    } else {
                      setShowModalAlertaPossuiMovimentacao(true);
                    }
                  }}
                >
                  Trocar cliente
                </MenuItem>

                <MenuItem
                  onClick={() => {
                    if (selectClienteRef.current) {
                      selectClienteRef.current.showInformacoesCliente();
                    }
                  }}
                >
                  Informações
                </MenuItem>
              </MenuGroup>
            </MenuList>
          </Menu>
        </Flex>
        <MobileSelectWrapperModal
          isOpen={showTrocarCliente}
          onClose={() => {
            setShowTrocarCliente(false);
          }}
          asMobileView={isMobile}
          renderChildrenWhenClosed
        >
          {({ onClose }) => (
            <SelectCliente
              id="cliente"
              name="cliente"
              handleAtualizarClienteCadastrado={
                handleAtualizarClienteCadastrado
              }
              placeholder=""
              required
              size="md"
              habilitarMenu={false}
              handleClienteSelect={async (cliente, selecaoFinal = false) => {
                await handleChangeCliente(cliente);

                if (selecaoFinal) {
                  onClose();
                }
              }}
              ref={selectClienteRef}
              autoFocus
              defaultValue={getValues('cliente')}
              show={showTrocarCliente}
              width={isLargerThan900 ? 'lg' : '72'}
              disabled={possuiMovimentacaoFinanceiraWatch}
              blockMobileView
            />
          )}
        </MobileSelectWrapperModal>
      </Flex>
      {showModalAlertaPossuiMovimentacao && (
        <ModalAlerta
          isOpen={showModalAlertaPossuiMovimentacao}
          setIsOpen={setShowModalAlertaPossuiMovimentacao}
          textoMensagem="Não é possível trocar o cliente de uma operação com movimentação financeira."
        />
      )}
    </>
  );
};

export default CadastroCliente;
