import { useCallback, useState, useRef, useEffect } from 'react';
import {
  ModalProps,
  ModalContent,
  ModalBody,
  Button,
  useDisclosure,
  Text,
  Flex,
  ModalFooter,
  ModalCloseButton,
  ModalHeader,
  useMediaQuery,
  Icon,
  Divider,
  Box,
  IconButton,
} from '@chakra-ui/react';
import { FiMinus, FiPlus } from 'react-icons/fi';
import { create, InstanceProps } from 'react-modal-promise';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { DecimalMask } from 'helpers/format/fieldsMasks';
import { CasasDecimais } from 'store/Padronizacao/Padronizacao';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';

import ModalPadraoChakra from 'components/PDV/Modal/ModalPadraoChakra';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import { NumberInput } from 'components/update/Input/NumberInput';
import { SearchInput } from 'components/update/Input/SearchInput';
import VirtualizedList, {
  PaginationVirtualizedListProps,
} from 'components/update/VirtualizedList';
import { ModalCodigoBarras } from 'pages/PDV/Lancamento/AdicionarEditarItem/ModalCodigoBarras';
import { LeitorCodigoBarrasIcon } from 'icons';

type FormData = {
  codigoBarras?: string;
  quantidade: Record<string, number>;
};

type ListConferirItensResponseProps = {
  id: string;
  descricaoProdutoCorTamanho: string;
};

type ListaTodosProdutosConferidos = {
  id: string;
  quantidade: number;
  codigoBarrasInterno: string;
  sequenciaCodigoBarrasHexa: string;
  isChecked: boolean;
};

type ListConferirItensProps = {
  id: string;
  quantidade: number;
  codigoBarrasInterno: string;
  sequenciaCodigoBarrasHexa: string;
  isChecked: boolean;
  descricaoProdutoCorTamanho: string;
};

type ModalConferirItensProps = Omit<
  ModalProps,
  'children' | 'isOpen' | 'onClose'
> &
  InstanceProps<FormData> & {
    id: string;
    casasDecimais: CasasDecimais;
  };

export const ModalConferirItens = create<ModalConferirItensProps, FormData>(
  ({ onResolve, onReject, casasDecimais, id, ...rest }) => {
    const [listItensConferidos, setListItensConferidos] = useState<
      ListConferirItensProps[]
    >([]);
    const [listTodosItensConferidos, setListTodosItensConferidos] = useState<
      ListaTodosProdutosConferidos[]
    >([]);
    const [codigoBarrasVersaoMobile, setCodigoBarrasVersaoMobile] = useState(
      ''
    );
    const [isLoading, setIsLoading] = useState(false);

    const listTodosItensConferidosRef = useRef<ListaTodosProdutosConferidos[]>(
      listTodosItensConferidos
    );

    useEffect(() => {
      listTodosItensConferidosRef.current = listTodosItensConferidos;
    }, [listTodosItensConferidos]);

    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });
    const {
      isOpen: isModalCodigoBarrasOpen,
      onOpen: onModalCodigoBarrasOpen,
      onClose: onModalCodigoBarrasClose,
    } = useDisclosure();

    const [isLargerThan900] = useMediaQuery('(min-width: 900px)');
    const [isLargerThan700] = useMediaQuery('(min-width: 700px)');

    const formMethods = useForm<FormData>({
      defaultValues: {
        quantidade: { '': 0 },
      },
    });

    const { setValue, watch } = formMethods;

    const valorCodigoBarras = isLargerThan900
      ? watch('codigoBarras')
      : codigoBarrasVersaoMobile;

    const valorQuantidade = useCallback(() => {
      return watch('quantidade') || { '': 0 };
    }, [watch]);

    const quantidade = valorQuantidade();

    const valorQuantidadeTotalizada = Object.keys(quantidade).reduce(
      (acc, curr) => acc + quantidade[curr],
      0
    );
    const totalItensTransferidos = listTodosItensConferidos.reduce(
      (acc, curr) => acc + curr.quantidade,
      0
    );

    const handleAtualizarQuantidadeComCodigoBarras = useCallback(() => {
      listTodosItensConferidosRef.current.forEach((produto) => {
        const novaQuantidade = quantidade[produto.id];

        let value = novaQuantidade;
        if (
          produto.codigoBarrasInterno === valorCodigoBarras ||
          produto.sequenciaCodigoBarrasHexa === valorCodigoBarras
        ) {
          if (!produto.isChecked) {
            value = 1;
          } else {
            value = novaQuantidade + 1;
          }
        }

        setValue(`quantidade.${produto.id}`, value);
      });
    }, [quantidade, setValue, valorCodigoBarras]);

    const handleValidarProdutoCodigoBarras = useCallback(async () => {
      await handleAtualizarQuantidadeComCodigoBarras();

      setListTodosItensConferidos((produtoJaConferido) => {
        const indexCodigoBarras = produtoJaConferido.findIndex(
          (produto) =>
            produto.codigoBarrasInterno === valorCodigoBarras ||
            produto.sequenciaCodigoBarrasHexa === valorCodigoBarras
        );

        if (indexCodigoBarras > -1) {
          if (
            valorCodigoBarras ===
              produtoJaConferido[indexCodigoBarras].codigoBarrasInterno ||
            valorCodigoBarras ===
              produtoJaConferido[indexCodigoBarras].sequenciaCodigoBarrasHexa
          ) {
            produtoJaConferido.splice(indexCodigoBarras, 1, {
              ...produtoJaConferido[indexCodigoBarras],
              isChecked: true,
            });
          }
        }

        return [...produtoJaConferido];
      });
      setValue('codigoBarras', '');
    }, [handleAtualizarQuantidadeComCodigoBarras, setValue, valorCodigoBarras]);

    const adicionarItens = useCallback(
      async (dataPagination: PaginationVirtualizedListProps) => {
        setIsLoading(true);
        const response = await api.get<
          void,
          ResponseApi<{ registros: ListConferirItensResponseProps[] }>
        >(
          ConstanteEnderecoWebservice.TRANFERENCIA_ESTOQUE_LISTAR_ITENS_CONFERIR,
          {
            params: {
              ...dataPagination,
              operacaoOrigemId: id,
            },
          }
        );
        if (response) {
          setIsLoading(false);
          if (response.avisos) {
            response.avisos.forEach((item: string) => toast.warning(item));
          }

          if (response.sucesso && response.dados.registros.length > 0) {
            setListItensConferidos((prev) => [
              ...prev,
              ...(response.dados.registros || []).map((itemConferir) => {
                const valueItem = listTodosItensConferidosRef.current.find(
                  (listConferidos) => listConferidos.id === itemConferir.id
                );

                return {
                  ...valueItem,
                  ...itemConferir,
                } as ListConferirItensProps;
              }),
            ]);
          }
        }
      },
      [id]
    );

    function handleIncrementarQuantidade(idItem: string) {
      const novaQuantidade = quantidade[idItem];
      setValue(`quantidade.${idItem}`, novaQuantidade + 1);
    }

    function handleDecrementarQuantidade(idItem: string) {
      const novaQuantidade = quantidade[idItem] - 1;

      setValue(
        `quantidade.${idItem}`,
        novaQuantidade >= 1 ? novaQuantidade : 1
      );
    }

    useEffect(() => {
      async function obterTodosItensConferidos() {
        const response = await api.get<
          void,
          ResponseApi<ListaTodosProdutosConferidos[]>
        >(ConstanteEnderecoWebservice.TRANFERENCIA_ESTOQUE_CONFERIR, {
          params: {
            operacaoDestinoId: id,
          },
        });
        if (response) {
          if (response.avisos) {
            response.avisos.forEach((item: string) => toast.warning(item));
          }
          if (response.sucesso) {
            setListTodosItensConferidos(
              response.dados.map((itemConferido) => {
                setValue(`quantidade.${itemConferido.id}`, 0);
                return {
                  ...itemConferido,
                  isChecked: false,
                };
              })
            );
          }
        }
      }
      obterTodosItensConferidos();
    }, [id, setValue]);

    return (
      <ModalPadraoChakra
        isCentered={isLargerThan900}
        size={isLargerThan900 ? '4xl' : 'full'}
        {...rest}
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        autoFocus={false}
      >
        <ModalContent
          borderRadius={isLargerThan900 ? 'md' : '0'}
          marginBottom={isLargerThan900 ? '3.75rem' : '0'}
          marginTop={isLargerThan900 ? '3.75rem' : '0'}
          w={isLargerThan900 ? '960px' : 'full'}
          pl="10px"
          pt="10px"
          pb="10px"
          pr="10px"
        >
          <ModalHeader
            pl={['5px', 'undefined', 'undefined']}
            pr={['0', 'undefined', 'undefined']}
            pb="0"
          >
            <Text fontSize="16px" color="primary.50" fontWeight="semibold">
              Conferir itens
            </Text>
            <Text fontSize="12px">
              Confira os itens que foram transferidos para esse estoque
            </Text>
            <ModalCloseButton />
            <Divider mt="10px" mb="10px" />
          </ModalHeader>

          <FormProvider {...formMethods}>
            {isLoading && <LoadingPadrao />}
            <ModalBody
              pl={['5px', 'undefined', 'undefined']}
              pr={['0', 'undefined', 'undefined']}
              pt="10px"
            >
              {isLargerThan900 ? (
                <Box mb="10px">
                  <SearchInput
                    autoFocus
                    w={['full', 'full', '40%']}
                    fontSize="14px"
                    color="black"
                    isCodigoBarras
                    onEnterKeyPress={async () => {
                      await handleValidarProdutoCodigoBarras();
                    }}
                    placeholder="Código de barras"
                    isRequired
                    maxLength={100}
                    name="codigoBarras"
                  />
                </Box>
              ) : (
                <Flex
                  justifyContent="left"
                  onClick={onModalCodigoBarrasOpen}
                  w="200px"
                  mb="10px"
                  alignItems="center"
                  cursor="pointer"
                >
                  <IconButton
                    aria-label="Leitor de códigos de barras"
                    icon={<Icon as={LeitorCodigoBarrasIcon} boxSize="6" />}
                    variant="link"
                    minW="0"
                    color="purple.500"
                    p="0"
                    mr="10px"
                    borderRadius="sm"
                  />
                  <Text
                    textDecoration="underline"
                    color="purple.500"
                    fontSize="16px"
                    letterSpacing="1px"
                    display="inline-block"
                    _hover={{
                      textShadow: '0 0 .01px black',
                    }}
                  >
                    Ler código de barras
                  </Text>
                </Flex>
              )}

              <VirtualizedList
                containerHeight={isLargerThan900 ? 300 : 430}
                itemHeight={isLargerThan700 ? 55 : 73}
                colorBgGradient="white"
                items={listItensConferidos}
                handleOnClick={adicionarItens}
                quantidadeItems={listItensConferidos.length}
                key="virtualizedListConferirItens"
                render={(items, height) => (
                  <>
                    {items.map((itensConferidos) => {
                      const valueQuantidadeAdicionada =
                        quantidade[itensConferidos.id];
                      const valueQuantidadeItem = itensConferidos.quantidade;

                      const colorItem =
                        valueQuantidadeAdicionada === valueQuantidadeItem
                          ? 'green.100'
                          : valueQuantidadeAdicionada > valueQuantidadeItem
                          ? 'yellow.300'
                          : 'gray.50';

                      const valueQuantidade = quantidade[itensConferidos.id]
                        ? quantidade[itensConferidos.id]
                        : 0;

                      return (
                        <Flex
                          color="black"
                          flexDirection={['column', 'row', 'row']}
                          justifyContent="space-between"
                          alignItems={['left', 'center', 'center']}
                          mb="5px"
                          h={height}
                          bg={colorItem}
                          p="10px"
                          pl={['2px', '20px', '20px']}
                          pr={['10px', '20px', '20px']}
                          borderRadius="6px"
                          key={itensConferidos.id}
                        >
                          <Flex>
                            <Flex pl="10px" justifyContent="left">
                              {itensConferidos.descricaoProdutoCorTamanho}
                            </Flex>
                          </Flex>

                          <Box>
                            {itensConferidos.quantidade > 1 ? (
                              <Flex
                                w="full"
                                mt={['-10px', '0px', '0px']}
                                justifyContent={[
                                  'space-between',
                                  'undefined',
                                  'undefined',
                                ]}
                                alignItems="baseline"
                                pl={['10px', 'undefined', 'undefined']}
                              >
                                <Text color="primary.50" pr="25px">
                                  {DecimalMask(
                                    quantidade[itensConferidos.id] || 0,
                                    casasDecimais.casasDecimaisQuantidade
                                  )}
                                  /
                                  {DecimalMask(
                                    itensConferidos?.quantidade,
                                    casasDecimais.casasDecimaisQuantidade
                                  )}
                                </Text>
                                <Box>
                                  <Flex
                                    justifyContent="right"
                                    alignItems="right"
                                    w="100%"
                                  >
                                    <IconButton
                                      aria-label="Decrementar"
                                      icon={<Icon as={FiMinus} />}
                                      variant="outline"
                                      borderColor="gray.300"
                                      bg="white"
                                      onClick={() =>
                                        handleDecrementarQuantidade(
                                          itensConferidos.id
                                        )
                                      }
                                      _focus={{
                                        bg: 'white',
                                      }}
                                      _hover={{
                                        bg: 'white',
                                      }}
                                      _active={{
                                        bg: 'white',
                                      }}
                                      borderRadius="md"
                                      w="32px"
                                    />

                                    <Box>
                                      <NumberInput
                                        id="quantidade"
                                        name={`quantidade.${itensConferidos.id}`}
                                        ml="2px"
                                        mr="2px"
                                        scale={1}
                                        color="gray.600"
                                        fontSize={{
                                          base: 'sm',
                                          md: 'md',
                                        }}
                                        w="80px"
                                      />
                                    </Box>

                                    <IconButton
                                      aria-label="Incrementar"
                                      icon={<Icon as={FiPlus} />}
                                      variant="outline"
                                      borderColor="gray.300"
                                      bg="white"
                                      className="teste"
                                      _focus={{
                                        bg: 'white',
                                      }}
                                      _hover={{
                                        bg: 'white',
                                      }}
                                      _active={{
                                        bg: 'white',
                                      }}
                                      borderRadius="md"
                                      w="32px"
                                      onClick={() =>
                                        handleIncrementarQuantidade(
                                          itensConferidos.id
                                        )
                                      }
                                      minW={{
                                        base: '1px',
                                        sm: 9,
                                      }}
                                    />
                                  </Flex>
                                </Box>
                              </Flex>
                            ) : (
                              <Flex
                                alignItems={['left', 'center']}
                                w="120px"
                                mt={['-20px', '0px', '0px']}
                                pl={['10px', 'undefined', 'undefined']}
                                justifyContent={['left', 'center']}
                              >
                                <Text color="primary.50">
                                  {DecimalMask(
                                    itensConferidos?.quantidade,
                                    casasDecimais.casasDecimaisQuantidade
                                  )}
                                </Text>
                              </Flex>
                            )}
                          </Box>
                        </Flex>
                      );
                    })}
                  </>
                )}
              />

              <Flex
                alignItems="baseline"
                pt="10px"
                justifyContent="flex-end"
                pr="20px"
              >
                <Text>Total de produtos conferidos:</Text>
                <Text
                  fontSize="16px"
                  pl="8px"
                  color="primary.50"
                  fontWeight="bold"
                >
                  {DecimalMask(
                    valorQuantidadeTotalizada,
                    casasDecimais.casasDecimaisQuantidade
                  )}
                </Text>
              </Flex>
              {valorQuantidadeTotalizada > totalItensTransferidos && (
                <Flex
                  w="100%"
                  alignItems="baseline"
                  pt="10px"
                  pb="10px"
                  borderRadius="6px"
                  bg="yellow.300"
                  justifyContent="flex-end"
                  pr="10px"
                >
                  <Text>
                    A quantidade de itens conferidos ultrapassou a de itens
                    transferidos
                  </Text>
                </Flex>
              )}
              <ModalCodigoBarras
                isOpen={isModalCodigoBarrasOpen}
                onClose={onModalCodigoBarrasClose}
                onCodigoBarrasScanned={async (codigoBarras) => {
                  await setCodigoBarrasVersaoMobile('');
                  await setCodigoBarrasVersaoMobile(codigoBarras);

                  onModalCodigoBarrasClose();
                }}
              />
            </ModalBody>
            <ModalFooter flexDirection="column">
              <Divider mb="20px" />
              <Flex
                w="full"
                h="full"
                justifyContent="center"
                alignItems="baseline"
              >
                <Button
                  id="gerarCredito"
                  name="gerarCredito"
                  color="gray.300"
                  variant="outline"
                  borderRadius="20px"
                  fontSize="sm"
                  type="button"
                  onClick={() => onClose()}
                  h={isLargerThan900 ? '32px' : '40px'}
                  mr="24px"
                  _hover={{ bg: 'gray.50' }}
                  w="96px"
                >
                  Fechar
                </Button>
              </Flex>
            </ModalFooter>
          </FormProvider>
        </ModalContent>
      </ModalPadraoChakra>
    );
  }
);
