import { useEffect, useState, useRef, useCallback } from 'react';
import {
  GridItem,
  Box,
  Text,
  Icon,
  Flex,
  Button,
  useDisclosure,
  useMediaQuery,
  IconButton,
} from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { FormProvider, useForm } from 'react-hook-form';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { StatusPesquisaClientesFornecedor } from 'constants/enum/statusPesquisaClientesFornecedor';
import { usePadronizacaoContext } from 'store/Padronizacao/Padronizacao';
import { DecimalMask } from 'helpers/format/fieldsMasks';
import { formatOptionsSelectClient } from 'helpers/format/formatSelectClient';
import { formatDate } from 'helpers/format/formatStringDate';
import auth from 'modules/auth';
import ConstanteFuncionalidades from 'constants/permissoes';
import { useDebounce } from 'hooks/useDebounce';

import { FinanceiroHistoricoCliente, LeitorCodigoBarrasIcon } from 'icons';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import AsyncSelect from 'components/PDV/Select/AsyncSelectPadrao';
import ModalInformacoesCliente from 'components/PDV/Modal/ModalInformacoesCliente';
import { ListagemRecebimentoContas } from 'components/RecebimentoContasComponents/listagemRecebimentoContas';
import { BotoesParaEfetuarBaixa } from 'components/RecebimentoContasComponents/botoesParaEfetuarBaixa';
import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import {
  ObterContasEmAbertoResponse,
  FormData,
  formDefaultValues,
} from 'components/RecebimentoContasComponents/validationForm';
import ModalAlterarCliente from 'components/PDV/Modal/ModalAlterarCliente';
import { ModalCodigoBarras } from 'pages/PDV/Lancamento/AdicionarEditarItem/ModalCodigoBarras';
import Input from 'components/PDV/Input';

type FornecedorOptionResponse = {
  id: string;
  nome: string;
  endereco: string;
  codigo: number;
  cpfCnpj: string;
};

export function ListarRecebimentoContas() {
  const formMethods = useForm<FormData>({
    defaultValues: formDefaultValues,
  });

  const { casasDecimais } = usePadronizacaoContext();

  const [isLoading, setIsLoading] = useState(false);
  const [
    informacoesRecebimentoContas,
    setInformacoesRecebimentoContas,
  ] = useState<ObterContasEmAbertoResponse>();

  const [modalAlterarIsOpen, setModalAlterarIsOpen] = useState(false);
  const [idCliente, setIdCliente] = useState('');
  const [searchNumberOperation, setSearchNumberOperation] = useState('');
  const zerarMultaJuros = useRef<boolean>(false);

  const informacoesCliente = useRef<FornecedorOptionResponse[]>();
  const ultimoIdDoClienteSelecionado = useRef<string | null>(null);

  const { watch, setValue } = formMethods;

  const clienteFornecedorId = watch('clienteFornecedorId.value');

  const debouncedValue = useDebounce(searchNumberOperation, 500);

  const {
    isOpen: isModalCodigoBarrasOpen,
    onOpen: onModalCodigoBarrasOpen,
    onClose: onModalCodigoBarrasClose,
  } = useDisclosure();

  if (clienteFornecedorId !== ultimoIdDoClienteSelecionado.current) {
    ultimoIdDoClienteSelecionado.current = clienteFornecedorId;
    if (zerarMultaJuros.current) {
      zerarMultaJuros.current = false;
    }
  }

  const [isSmallerThan900] = useMediaQuery('(max-width: 900px)');

  const {
    isOpen: isOpenModalInformacoesCliente,
    onOpen: onOpenModalInformacoesCliente,
    onClose: onCloseModalInformacoesCliente,
  } = useDisclosure();

  const handleGetClientes = useCallback(async (inputValue?: string) => {
    const response = await api.get<
      void,
      ResponseApi<FornecedorOptionResponse[]>
    >(ConstanteEnderecoWebservice.CLIENTE_FORNECEDOR_LISTAR_SELECT, {
      params: {
        filtroTipoCadastroPessoa: StatusPesquisaClientesFornecedor.CLIENTES,
        cpfCnpjNomeApelidoCodigoExterno: inputValue,
      },
    });
    if (response.avisos) {
      response.avisos.forEach((aviso) => toast.warning(aviso));
    }

    if (response.sucesso && response.dados) {
      informacoesCliente.current = response.dados;
      const dados = response.dados.map((client) => {
        const option = formatOptionsSelectClient(client);
        return option;
      });
      return dados;
    }
    return [];
  }, []);

  const openUpdateCustomer = async (idClienteValue: string) => {
    setIdCliente(idClienteValue);

    if (
      auth.possuiPermissao(ConstanteFuncionalidades.CLIENTE_ALTERAR.codigo)
        .permitido
    ) {
      setModalAlterarIsOpen(true);
    }
  };

  const openEditar = () => {
    onCloseModalInformacoesCliente();
    openUpdateCustomer(clienteFornecedorId);
  };

  const updateCustomer = useCallback(
    async (newCustomer: { nome: string; id: string }) => {
      const customer = {
        value: newCustomer.id,
        label: newCustomer.nome,
      };
      setValue('clienteFornecedorId', customer);
    },
    [setValue]
  );

  const getRecebimentosFromCustomer = useCallback(
    async (clienteId?: string) => {
      setIsLoading(true);
      const cliente = watch('clienteFornecedorId');
      setValue('numeroOperacao', '');

      if (clienteId || cliente?.value) {
        const response = await api.get<
          void,
          ResponseApi<ObterContasEmAbertoResponse>
        >(
          ConstanteEnderecoWebservice.RECEBIMENTO_CONTAS_OBTER_CONTAS_EM_ABERTO,
          {
            params: {
              clienteFornecedorId: clienteId || cliente?.value,
            },
          }
        );

        if (response) {
          if (response.avisos) {
            response.avisos.map((item: string) => toast.warning(item));
          }

          if (response.sucesso) {
            if (response.dados) {
              setInformacoesRecebimentoContas(response.dados);
            } else {
              toast.warning('O cliente não possui contas em aberto.');
            }
          }
        }
      }
      setIsLoading(false);
    },
    [setValue, watch]
  );

  function onZerarMultaJuros() {
    zerarMultaJuros.current = true;
  }

  const handleInputNumberOperation = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    e.currentTarget.value = e.currentTarget.value.replace(/[^0-9]/g, '');
    setSearchNumberOperation(e.currentTarget.value);
  };

  const getRecebimentosFromNumberOperation = useCallback(async () => {
    setIsLoading(true);
    if (debouncedValue) {
      const response = await api.get<
        void,
        ResponseApi<ObterContasEmAbertoResponse>
      >(ConstanteEnderecoWebservice.RECEBIMENTO_CONTAS_OBTER_CONTAS_EM_ABERTO, {
        params: { identificador: debouncedValue },
      });

      setInformacoesRecebimentoContas(undefined);

      if (response) {
        if (response.avisos) {
          response.avisos.map((item: string) => toast.warning(item));
        }

        if (response.sucesso) {
          if (response.dados) {
            setInformacoesRecebimentoContas(response.dados);

            const { movimentacaoFinanceira } = response.dados || {};
            if (movimentacaoFinanceira && movimentacaoFinanceira.length > 0) {
              const cliente = {
                label: movimentacaoFinanceira[0].clienteFornecedorNome,
                value: movimentacaoFinanceira[0].clienteFornecedorId,
              };
              setValue('clienteFornecedorId', cliente);
            }
          }
        }
      }
    }
    setIsLoading(false);
  }, [debouncedValue, setValue]);

  useEffect(() => {
    getRecebimentosFromNumberOperation();
  }, [getRecebimentosFromNumberOperation]);

  return (
    <>
      <SimpleGridForm>
        <FormProvider {...formMethods}>
          <GridItem w="full" colSpan={{ base: 12, md: 12, lg: 7 }}>
            <Flex
              w="full"
              flexWrap="wrap"
              justifyContent="space-between"
              flexDir={isSmallerThan900 ? 'column' : 'row'}
            >
              <Box mt="18px" w={['100%', '100%', '67%', '55%']}>
                <AsyncSelect
                  id="clienteFornecedorId"
                  name="clienteFornecedorId"
                  placeholder="Informe o cliente"
                  required
                  handleGetOptions={handleGetClientes}
                  onOptionSelect={(option) => {
                    getRecebimentosFromCustomer(option.value as string);
                  }}
                  colSpan={12}
                  asControlledByObject
                  shouldAppearTheAddress
                />
              </Box>
              <Flex
                w={['100%', '100%', '30%', '40%']}
                borderBottom="1px solid #ccc"
                align="flex-end"
                mt={isSmallerThan900 ? '24px' : '0px'}
                _focusWithin={{ borderColor: 'primary.500' }}
              >
                <Input
                  border="none"
                  _focus={{
                    border: 'none',
                  }}
                  bg="transparent"
                  name="numeroOperacao"
                  label="Número da operação"
                  labelColor="black"
                  placeholder="Informe o número da operação"
                  onInput={handleInputNumberOperation}
                  color="black"
                  px="0"
                />
                {isSmallerThan900 && (
                  <>
                    <IconButton
                      aria-label="Leitor de códigos de barras"
                      icon={<Icon as={LeitorCodigoBarrasIcon} boxSize="6" />}
                      variant="link"
                      borderRadius="sm"
                      mb="12px"
                      onClick={onModalCodigoBarrasOpen}
                    />
                    <ModalCodigoBarras
                      isOpen={isModalCodigoBarrasOpen}
                      onClose={onModalCodigoBarrasClose}
                      onCodigoBarrasScanned={async (codigoBarras: string) => {
                        setSearchNumberOperation(codigoBarras);

                        onModalCodigoBarrasClose();
                      }}
                    />
                  </>
                )}
              </Flex>
            </Flex>
          </GridItem>

          {informacoesRecebimentoContas?.dataUltimoPagamento &&
          !isSmallerThan900 ? (
            <GridItem
              justifyContent="left"
              display="flex"
              alignItems="center"
              colSpan={{ base: 12, lg: 5 }}
              mt={{ base: 0, md: 0, lg: '24px' }}
            >
              <Button
                size="sm"
                variant="link"
                colorScheme="gray.700"
                leftIcon={
                  <Icon fontSize="md" as={FinanceiroHistoricoCliente} />
                }
                onClick={onOpenModalInformacoesCliente}
              >
                Último pagamento dia{' '}
                {formatDate(informacoesRecebimentoContas?.dataUltimoPagamento)}
              </Button>
              <Box
                ml="1"
                mt="1"
                justifyContent="flex-end"
                display="flex"
                alignItems="baseline"
              >
                <Text fontWeight="bold" size="sm">
                  -{' '}
                  {`R$ ${DecimalMask(
                    informacoesRecebimentoContas.valorUltimoPagamento,
                    2
                  )}`}
                </Text>
              </Box>
            </GridItem>
          ) : (
            <></>
          )}
        </FormProvider>
      </SimpleGridForm>
      {isLoading && <LoadingPadrao />}
      {clienteFornecedorId ? (
        <ListagemRecebimentoContas
          zerarMultaJuros={zerarMultaJuros}
          isSmallerThan1200={isSmallerThan900}
          setInformacoesRecebimentoContas={setInformacoesRecebimentoContas}
          informacoesRecebimentoContas={informacoesRecebimentoContas}
          clienteFornecedorId={clienteFornecedorId}
        />
      ) : (
        <>
          {!isSmallerThan900 && (
            <Box
              mt="20px"
              padding="15px"
              borderRadius="4px"
              bg="white"
              w="full"
            >
              Nenhum resultado foi encontrado
            </Box>
          )}
        </>
      )}
      <BotoesParaEfetuarBaixa
        isSmallerThan={isSmallerThan900}
        informacoesRecebimentoContas={informacoesRecebimentoContas}
        onZerarMultaEJuros={onZerarMultaJuros}
        zerarMultaJuros={zerarMultaJuros}
        clienteFornecedorId={clienteFornecedorId}
        informacoesCliente={informacoesCliente.current}
        casasDecimaisValor={casasDecimais.casasDecimaisValor}
        setInformacoesRecebimentoContas={setInformacoesRecebimentoContas}
        handleObterDetalhesRecebimentoContas={getRecebimentosFromCustomer}
      />

      {isOpenModalInformacoesCliente && (
        <ModalInformacoesCliente
          isOpen={isOpenModalInformacoesCliente}
          onClose={onCloseModalInformacoesCliente}
          idCliente={clienteFornecedorId || ''}
          openEditar={() => openEditar()}
        />
      )}
      <ModalAlterarCliente
        isOpen={modalAlterarIsOpen}
        setIsOpen={setModalAlterarIsOpen}
        clienteIdPdv={idCliente}
        alterarClienteCallback={updateCustomer}
        chavePermissaoTemporaria=""
      />
    </>
  );
}
