/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, GridItem, Td, Tr } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { useReactToPrint } from 'react-to-print';
import { toast } from 'react-toastify';

import auth from 'modules/auth';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import ConstanteFuncionalidades from 'constants/permissoes';
import ConstanteRotas, { SubstituirParametroRota } from 'constants/rotas';
import { formatQueryPagegTable } from 'helpers/format/formatQueryParams';
import { ImprimirPDF } from 'helpers/impressoes/imprimirPDF';
import { shallowEqual } from 'helpers/validation/shallowEqual';
import { formatDateHourMinute } from 'helpers/format/formatStringDate';

import { LupaIcon } from 'icons';
import InputDateRange from 'components/PDV/InputDateRange';
import { BuscaAvancadaButton } from 'components/update/BuscaAvancadaButton';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import { PaginationData } from 'components/update/Pagination';
import {
  ActionMenuItem,
  ActionsMenu,
} from 'components/update/Table/ActionsMenu';
import {
  PagedTable,
  PagedTableForwardRefData,
} from 'components/update/Table/PagedTable';
import { GridPaginadaRetorno } from 'components/Grid/Paginacao';
import { Link } from 'components/update/Link';
import ValidarPermissao from 'components/Validacao/ValidarPermissao';
import { ModalFechamentoCaixa } from 'components/update/Modal/ModalFechamentoCaixa';
import LogAuditoriaTelaEnum from 'constants/enum/logAuditoriaTela';
import { ModalConfirmacaoExcluir } from 'components/Modal/ModalConfirmacaoExcluir';
import { EmailModal } from 'components/PDV/CompartilharDocumentosFiscais/EmailModal';
import { ModalCompartilhar } from 'components/Modal/ModalCompartilhar';
import ImpressaoCupomFechamentoCaixa, {
  ImpressaoCupomFechamentoCaixaRefElement,
} from 'components/Impressao/impressaoFechamentoCaixa';

import ModalBuscaAvancadaControleCaixa from './ModalBuscaAvancada';
import { FormData, formDefaultValues } from './validationForm';
import { ListarControleCaixaProps, ObterCaixasProps } from './types';

interface CaixaOptionsProps {
  label: string;
  value: string;
}

const Listar = () => {
  const history = useHistory();
  const { userId } = auth.getDadosToken();

  const [itemsCount, setItemsCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [hasFilters, setHasFilters] = useState(false);
  const [caixasOptions, setCaixasOptions] = useState<CaixaOptionsProps[]>(
    [] as CaixaOptionsProps[]
  );
  const [currentCaixaId, setCurrentCaixaId] = useState<string | undefined>(
    undefined
  );
  const [columnsData, setColumnsData] = useState<ListarControleCaixaProps[]>(
    []
  );
  const [isModalBuscaAvancadaOpen, setIsModalBuscaAvancadaOpen] = useState(
    false
  );
  const [currentFilters, setCurrentFilters] = useState<FormData>(
    formDefaultValues
  );

  const pagedTableRef = useRef<PagedTableForwardRefData>(null);
  const bobinaContainerRef = useRef<HTMLDivElement>(null);
  const bobinaComponentToPrintRef = useRef<ImpressaoCupomFechamentoCaixaRefElement>(
    null
  );

  const imprimirRelatorioBobina = useReactToPrint({
    content: () => bobinaContainerRef.current,
  });

  const formMethods = useForm({
    defaultValues: formDefaultValues(),
  });

  const permissaoControleEstoqueVisualizar = auth.possuiPermissao(
    ConstanteFuncionalidades.CONTROLE_CAIXA_VISUALIZAR
  );

  const permissaoImprimirTodosCaixa = auth.possuiPermissao(
    ConstanteFuncionalidades.CONTROLE_CAIXA_IMPRIMIR_TODOS_CAIXAS
  );

  const permissaoVerTodosCaixas = auth.possuiPermissao(
    ConstanteFuncionalidades.CONTROLE_CAIXA_VER_TODOS_CAIXAS
  );

  const isAdministrador = auth.getIsAdministrador();

  const handleReset = () => {
    setCurrentFilters(formDefaultValues());
    setHasFilters(false);
    formMethods.reset(formDefaultValues());
  };

  const filtersSubmit = formMethods.handleSubmit((data) => {
    const filtersIsDirty = !shallowEqual(data, currentFilters);

    if (filtersIsDirty) {
      setCurrentFilters(data);
      setHasFilters(true);
    }
  });

  function handleSubmitModalBuscaAvancada(data: FormData) {
    formMethods.reset(data);
    filtersSubmit();
  }

  const loadColumnsData = useCallback(
    async (paginationData: PaginationData) => {
      setIsLoading(true);

      const params = {
        usuarioId: (currentFilters as any).usuarioId.value || '',
        contafinanceiraId: currentFilters.contafinanceiraId || '',
        dataInicioAbertura: currentFilters.dataInicioAbertura.toISOString(),
        dataFimAbertura: currentFilters.dataFimAbertura.toISOString(),
      };

      const response = await api.get<
        void,
        ResponseApi<GridPaginadaRetorno<ListarControleCaixaProps>>
      >(
        formatQueryPagegTable(
          ConstanteEnderecoWebservice.CONTROLE_CAIXA_LISTAR_PAGINADO,
          paginationData,
          params
        )
      );

      if (response) {
        if (response.sucesso) {
          setColumnsData(response.dados.registros || []);
          setItemsCount(response.dados.total || 0);
        }
      }

      setIsLoading(false);
    },
    [currentFilters]
  );

  const handleEnvioEmail = useCallback(async (idCaixaMovimentacao: string) => {
    const { sucesso } = await ModalConfirmacaoExcluir({
      title: 'Deseja incluir todas as movimentações do caixa?',
      text: ' ',
      cancelButtonText: 'Não',
      confirmButtonText: 'Sim, incluir',
    });

    EmailModal({
      caixaMovimentacao: {
        caixaMovimentacaoId: idCaixaMovimentacao,
        listarMovimentacoes: sucesso,
      },
    });
  }, []);

  const handleCompartilharRelatorioA4 = useCallback(
    async (idCaixaMovimentacao: string) => {
      const { sucesso } = await ModalConfirmacaoExcluir({
        title: 'Deseja imprimir todas as movimentações do caixa?',
        text: ' ',
        cancelButtonText: 'Não ',
        confirmButtonText: 'Sim, imprimir',
      });
      const response = await api.get<void, ResponseApi<string>>(
        ConstanteEnderecoWebservice.FECHAMENTO_CAIXA_IMPRESSAO_A4,
        {
          params: {
            caixaMovimentacaoId: idCaixaMovimentacao,
            listarMovimentacoes: sucesso,
          },
        }
      );

      if (response) {
        if (response.avisos) {
          response.avisos.forEach((item) => toast.warning(item));
        }
        if (response.sucesso && response.dados) {
          ImprimirPDF(response.dados, 'relatorioControleCaixa');
        }
      }
    },
    []
  );

  const handleCompartilharImpressao = useCallback(
    async (idCaixaMovimentacao: string) => {
      setCurrentCaixaId(idCaixaMovimentacao);
      ModalCompartilhar({
        items: [
          {
            titulo: 'Imprimir A4',
            onClickEmail: () => {
              handleEnvioEmail(idCaixaMovimentacao);
            },
            onClickImpressao: () => {
              handleCompartilharRelatorioA4(idCaixaMovimentacao);
            },
          },
          {
            titulo: 'Imprimir bobina',
            onClickImpressao: async () => {
              await bobinaComponentToPrintRef.current?.obterInformacoesImpressao();

              if (imprimirRelatorioBobina) {
                imprimirRelatorioBobina();
              }
            },
          },
        ],
      });
    },
    [handleCompartilharRelatorioA4, handleEnvioEmail, imprimirRelatorioBobina]
  );

  useEffect(() => {
    async function obterCaixas() {
      const response = await api.get<void, ResponseApi<ObterCaixasProps[]>>(
        ConstanteEnderecoWebservice.CONTA_FINANCEIRA_LISTAR_SELECT_CAIXA
      );

      if (response) {
        if (response.sucesso) {
          const caixas = response.dados.map(({ id, nome }) => ({
            label: nome,
            value: id,
          }));

          setCaixasOptions(caixas);
        }
      }
    }
    obterCaixas();
  }, []);

  function handlePushVisualizar(controleCaixaId: string) {
    let href = ConstanteRotas.CONTROLE_CAIXA;

    if (permissaoControleEstoqueVisualizar.permitido) {
      href = SubstituirParametroRota(
        ConstanteRotas.CONTROLE_CAIXA_DETALHES,
        'id',
        controleCaixaId
      );
    }

    history.push(href);
  }

  const handleFecharCaixa = useCallback(
    async (idCaixaMovimentacao: string) => {
      const { sucesso } = await ModalFechamentoCaixa({
        casasDecimaisValor: 2,
        idCaixaMovimentacao,
        tela: LogAuditoriaTelaEnum.CONTROLE_CAIXA,
      });

      if (sucesso) {
        await handleCompartilharImpressao(idCaixaMovimentacao);
        if (pagedTableRef.current) {
          pagedTableRef.current.reload();
        }
      }
    },
    [handleCompartilharImpressao]
  );

  const handleExcluirCaixa = useCallback(
    async (idCaixaMovimentacao: string) => {
      ModalConfirmacaoExcluir({
        title: 'Deseja excluir o caixa?',
        text: 'Ao excluir o caixa toda a conferência será perdida.',
        confirmButtonText: 'Sim!',
        cancelButtonText: 'Cancelar',
        callback: async (ok: boolean) => {
          if (ok) {
            const response = await api.delete<void, ResponseApi>(
              `${ConstanteEnderecoWebservice.CAIXA_MOVIMENTACAO_EXCLUIR}/${idCaixaMovimentacao}`
            );

            if (response) {
              if (response.avisos) {
                response.avisos.forEach((aviso) => toast.warning(aviso));
              }

              if (response.sucesso) {
                toast.success('O caixa foi excluído.');

                if (pagedTableRef.current) {
                  pagedTableRef.current.reload();
                }
              }
            }
          }
        },
      });
    },
    []
  );

  async function handleReabrirCaixa(caixaMovimentacaoId: string) {
    ModalConfirmacaoExcluir({
      title: 'Deseja reabrir o caixa?',
      text:
        'Ao reabrir o caixa toda a conferência será perdida. Deseja continuar?',
      confirmButtonText: 'Sim!',
      cancelButtonText: 'Cancelar',
      callback: async (ok: boolean) => {
        if (ok) {
          const response = await api.put<void, ResponseApi>(
            ConstanteEnderecoWebservice.CONTROLE_CAIXA_REABRIR_CAIXA,
            {},
            {
              params: { caixaMovimentacaoId },
            }
          );

          if (response) {
            if (response.avisos) {
              response.avisos.forEach((aviso) => toast.warning(aviso));
            }

            if (response.sucesso) {
              toast.success('O caixa foi reaberto.');

              if (pagedTableRef.current) {
                pagedTableRef.current.reload();
              }
            }
          }
        }
      },
    });
  }

  return (
    <>
      <SimpleGridForm>
        <FormProvider {...formMethods}>
          <GridItem colSpan={{ base: 12, lg: 2 }}>
            <Box maxW={{ base: 'full' }}>
              <InputDateRange
                borderColor="gray.100"
                borderRadius="md"
                name="data"
                startDateName="dataInicioAbertura"
                endDateName="dataFimAbertura"
                maxDate={new Date()}
                onConfirm={() => filtersSubmit()}
              />
            </Box>
          </GridItem>
          <GridItem colSpan={{ base: 12, sm: 6, lg: 6 }}>
            <Box maxW={{ base: 'full', sm: '240px' }}>
              <BuscaAvancadaButton
                setIsModalBuscaAvancadaOpen={setIsModalBuscaAvancadaOpen}
                hasFilters={hasFilters}
              />
            </Box>
          </GridItem>
        </FormProvider>
        <GridItem colSpan={12}>
          <PagedTable
            ref={pagedTableRef}
            isLoading={isLoading}
            loadColumnsData={loadColumnsData}
            itemsTotalCount={itemsCount}
            defaultKeyOrdered="dataHoraAbertura"
            defaultOrderDirection="desc"
            tableHeaders={[
              {
                key: 'dataHoraAbertura',
                content: 'Data de abertura',
                w: '150px',
              },
              {
                key: 'dataHoraFechamento',
                content: 'Data de fechamento',
                w: '300px',
              },
              {
                key: 'localOrigem',
                isOrderable: false,
                content: 'Caixa',
                w: '30%',
              },
              {
                key: 'localDestino',
                isOrderable: false,
                content: 'Usuário',
                w: '30%',
              },
              {
                key: 'situacao',
                w: '150px',
                content: 'Situação',
                isOrderable: false,
              },
              {
                key: 'acoes',
                w: '38px',
                content: 'Ações',
                isOrderable: false,
              },
            ]}
            renderTableRows={
              columnsData &&
              columnsData.map(
                ({
                  caixaMovimentacaoId,
                  dataHoraAbertura,
                  dataHoraFechamento,
                  contaFinanceiraNome,
                  usuarioAberturaId,
                  usuarioAberturaNome,
                  situacao,
                  reabrirCaixa,
                }) => {
                  const dropDownItens = ((): ActionMenuItem[] => {
                    const imprimirValoresCaixa =
                      situacao === 'Caixa fechado' &&
                      permissaoImprimirTodosCaixa.permitido;
                    if (usuarioAberturaId === userId || isAdministrador) {
                      if (dataHoraFechamento) {
                        if (reabrirCaixa) {
                          return [
                            {
                              content: 'Reabrir caixa',
                              onClick: () => {
                                handleReabrirCaixa(caixaMovimentacaoId);
                              },
                              funcionalidade:
                                ConstanteFuncionalidades.CONTROLE_CAIXA_REABRIR,
                            },
                            {
                              content: 'Ver detalhes',
                              onClick: () => {
                                handlePushVisualizar(caixaMovimentacaoId);
                              },
                              funcionalidade:
                                ConstanteFuncionalidades.CONTROLE_CAIXA_VISUALIZAR,
                            },
                            {
                              content: 'Compartilhar',
                              onClick: () => {
                                handleCompartilharImpressao(
                                  caixaMovimentacaoId
                                );
                              },
                              funcionalidade: '',
                            },
                            {
                              content: 'Excluir',
                              onClick: () => {
                                handleExcluirCaixa(caixaMovimentacaoId);
                              },
                              funcionalidade: '',
                            },
                          ];
                        }
                      } else {
                        return [
                          {
                            content: 'Ver detalhes',
                            onClick: () => {
                              handlePushVisualizar(caixaMovimentacaoId);
                            },
                            funcionalidade:
                              ConstanteFuncionalidades.CONTROLE_CAIXA_VISUALIZAR,
                          },
                          {
                            content: 'Fechar caixa',
                            onClick: () => {
                              handleFecharCaixa(caixaMovimentacaoId);
                            },
                            funcionalidade:
                              ConstanteFuncionalidades.CONTROLE_CAIXA_ABRIR_FECHAR,
                          },
                        ];
                      }
                    }
                    const newOptions = imprimirValoresCaixa
                      ? [
                          {
                            content: 'Ver detalhes',
                            onClick: () => {
                              handlePushVisualizar(caixaMovimentacaoId);
                            },
                            funcionalidade:
                              ConstanteFuncionalidades.CONTROLE_CAIXA_VISUALIZAR,
                          },
                          {
                            content: 'Compartilhar',
                            onClick: () => {
                              handleCompartilharImpressao(caixaMovimentacaoId);
                            },
                            funcionalidade: '',
                          },
                        ]
                      : [
                          {
                            content: 'Ver detalhes',
                            onClick: () => {
                              handlePushVisualizar(caixaMovimentacaoId);
                            },
                            funcionalidade:
                              ConstanteFuncionalidades.CONTROLE_CAIXA_VISUALIZAR,
                          },
                        ];
                    return newOptions;
                  })();

                  const dataHoraA = formatDateHourMinute(dataHoraAbertura);

                  const dataHoraF =
                    dataHoraFechamento !== null
                      ? formatDateHourMinute(dataHoraFechamento)
                      : null;
                  return (
                    <Tr>
                      <Td>
                        <div
                          style={{
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <ValidarPermissao
                            funcionalidade={
                              ConstanteFuncionalidades.CONTROLE_CAIXA_VISUALIZAR
                            }
                            tooltipWrap="hidden"
                          >
                            {({ permitido }) =>
                              permitido ? (
                                <div className="d-inline-flex">
                                  <LupaIcon
                                    style={{
                                      fontSize: ' 1.375rem',
                                      cursor: 'pointer',
                                      marginRight: '10px',
                                    }}
                                    onClick={() => {
                                      handlePushVisualizar(caixaMovimentacaoId);
                                    }}
                                  />
                                  <Link
                                    onClick={() => {
                                      handlePushVisualizar(caixaMovimentacaoId);
                                    }}
                                    href={SubstituirParametroRota(
                                      ConstanteRotas.CONTROLE_CAIXA_DETALHES,
                                      'id',
                                      caixaMovimentacaoId
                                    )}
                                  >
                                    {dataHoraA}
                                  </Link>
                                </div>
                              ) : (
                                dataHoraA
                              )
                            }
                          </ValidarPermissao>
                        </div>
                      </Td>
                      <Td>{dataHoraF}</Td>
                      <Td>{contaFinanceiraNome}</Td>
                      <Td>{usuarioAberturaNome}</Td>
                      <Td>{situacao}</Td>
                      <Td>
                        <ActionsMenu
                          items={dropDownItens}
                          isDisabled={dropDownItens.length === 0}
                        />
                      </Td>
                    </Tr>
                  );
                }
              )
            }
          />
        </GridItem>
      </SimpleGridForm>
      <ModalBuscaAvancadaControleCaixa
        isOpen={isModalBuscaAvancadaOpen}
        permissaoVerTodosCaixas={permissaoVerTodosCaixas}
        setIsOpen={setIsModalBuscaAvancadaOpen}
        currentFilters={currentFilters}
        handleSubmitModalBuscaAvancada={handleSubmitModalBuscaAvancada}
        handleReset={handleReset}
        caixasOptions={caixasOptions}
      />
      {currentCaixaId && (
        <Box display="none">
          <ImpressaoCupomFechamentoCaixa
            ref={bobinaComponentToPrintRef}
            containerRef={bobinaContainerRef}
            caixaMovimentacaoId={currentCaixaId}
          />
        </Box>
      )}
    </>
  );
};

export default Listar;
