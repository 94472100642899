import { createContext, useCallback, useContext, useState } from 'react';

import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { ResponseApi } from 'services/api';
import { sistemaApiKey } from 'services/sistemaApiKey';

import { InfoIntegracaoProps } from 'pages/Integracoes/LojaAplicativos/PdvAutonomo/PainelAdministrador/validationForms';

type PdvAutonomoProviderProps = {
  children: React.ReactNode;
};

type PdvAutonomoContextProps = {
  infoIntegracao: InfoIntegracaoProps | undefined;
  getIntegracao: (cadastrarIntegracao: () => Promise<void>) => Promise<void>;
  dataConfiguracao?: IntegracaoConfiguracoesProps;
};

type IntegracaoConfiguracoesProps = {
  IdentificacaoEtapas: number;
  DataScript: Date;
  DataHoraUltimoBackup: null | Date;
  UrlScript: null | string;
};

export const PdvAutonomoContext = createContext<PdvAutonomoContextProps>(
  {} as PdvAutonomoContextProps
);

export function PdvAutonomoProvider({
  children,
}: PdvAutonomoProviderProps): JSX.Element {
  const [infoIntegracao, setInfoIntegracao] = useState<InfoIntegracaoProps>();

  const dataPdvConfig = infoIntegracao?.configuracoes
    ? (JSON.parse(
        infoIntegracao?.configuracoes
      ) as IntegracaoConfiguracoesProps)
    : undefined;

  const dataConfiguracao = dataPdvConfig;

  const getIntegracao = useCallback(
    async (cadastrarIntegracao: () => Promise<void>) => {
      const response = await sistemaApiKey().get<
        void,
        ResponseApi<InfoIntegracaoProps>
      >(ConstanteEnderecoWebservice.PDV_AUTONOMO_OBTER_INTEGRACAO);

      if (response) {
        if (response.sucesso) {
          setInfoIntegracao(response.dados);
        } else {
          await cadastrarIntegracao();
        }
      }
    },
    []
  );

  return (
    <PdvAutonomoContext.Provider
      value={{
        infoIntegracao,
        getIntegracao,
        dataConfiguracao,
      }}
    >
      {children}
    </PdvAutonomoContext.Provider>
  );
}

export function usePdvAutonomoContext(): PdvAutonomoContextProps {
  const context = useContext(PdvAutonomoContext);

  if (!context)
    throw new Error(
      'PdvAutonomoContext must be used within a PdvAutonomoProvider.'
    );

  return context;
}
