import { useEffect } from 'react';
import { Flex, Box, Button, Icon, Text, GridItem } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { FiChevronLeft } from 'react-icons/fi';

import LoadingPadrao from 'components/Layout/Loading/LoadingPadrao';
import { SimpleGridForm } from 'components/update/Form/SimpleGridForm';
import { ContainerHeader } from 'pages/Integracoes/LojaAplicativos/Layout/ContainerHeader';

import { Imagens } from './areas/Imagens';
import { Menu } from './areas/Menu';
import { Produtos } from './areas/Produtos';
import { BarraInferior } from './areas/BarraInferior';
import { FluxoOperacional } from './areas/FluxoOperacional';
import { TelaFinal } from './areas/TelaFinal';
import { TelasSelecao } from './areas/TelasSelecao';
import { FormData } from './types';
import { useTemas } from './hooks';
import { yupResolver } from './formfields';

export const Temas = () => {
  const formMethods = useForm<FormData>({
    defaultValues: {
      requestCPF: true,
      askWhereToConsume: true,
      hasOrderCompletionMessage: true,
      orderCompletionMessage: '',
      homeImgUrl: '',
      bannerImgUrl: '',
      finalImgUrl: '',
      menuBackgroundColor: '#FFFFFF',
      mainColor: '#FFFFFF',
      forwardButtonBackgroundColor: '#FFFFFF',
      cartAreaBackgroundColor: '#FFFFFF',
      cartConfirmationButtonBackgroundColor: '#FFFFFF',
      itemSelectionColor: '#FFFFFF',
      paperSize: '57',
    },
    resolver: yupResolver,
  });

  const {
    isLoading,
    voltarParaConfiguracoesFrenteCaixa,
    salvarTema,
    buscarTemaSalvo,
    cadastrado,
    salvarImagem,
  } = useTemas({
    formMethods,
  });
  const { handleSubmit } = formMethods;

  const onSubmit = handleSubmit(async (data) => {
    const dataApi = {
      customTheme: {
        mainColor: data.mainColor,
        menuBackgroundColor: data.menuBackgroundColor,
        menuTextColor: data.menuTextColor,
        menuActionColor: data.menuActionColor,
        menuSeparatorColor: data.menuSeparatorColor,
        cartIconColor: data.cartIconColor,
        cartAreaBackgroundColor: data.cartAreaBackgroundColor,
        cartConfirmationButtonBackgroundColor:
          data.cartConfirmationButtonBackgroundColor,
        cartConfirmationButtonTextColor: data.cartConfirmationButtonTextColor,
        cartConfirmationButtonActionColor:
          data.cartConfirmationButtonActionColor,
        forwardButtonBackgroundColor: data.forwardButtonBackgroundColor,
        forwardButtonTextColor: data.forwardButtonTextColor,
        forwardButtonActionColor: data.forwardButtonActionColor,
        navigationButtonBackgroundColor: data.navigationButtonBackgroundColor,
        navigationButtonTextColor: data.navigationButtonTextColor,
        itemSelectionColor: data.itemSelectionColor,
        itemSelectionTextColor: data.itemSelectionTextColor,
        fontFamily: data.fontFamily,
        homeImgUrl: data.homeImgUrl,
        bannerImgUrl: data.bannerImgUrl,
        finalImgUrl: data.finalImgUrl,
      },
      settings: {
        paperSize: data.paperSize,
        requestCPF: data.requestCPF,
        askWhereToConsume: data.askWhereToConsume,
        hasOrderCompletionMessage: data.hasOrderCompletionMessage,
        orderCompletionMessage: data.orderCompletionMessage,
      },
    };
    await salvarTema(dataApi, cadastrado);
  });

  useEffect(() => {
    buscarTemaSalvo();
  }, [buscarTemaSalvo]);

  return (
    <FormProvider {...formMethods}>
      {isLoading && <LoadingPadrao />}
      <ContainerHeader bg="#FF005A" gap={2} color="white" alignItems="center">
        <Box cursor="pointer" onClick={voltarParaConfiguracoesFrenteCaixa}>
          <Icon fontSize="25px" as={FiChevronLeft} />
        </Box>
        <Text fontSize="16px" fontWeight="semibold">
          Auto Atendimento: Tema
        </Text>
      </ContainerHeader>
      <SimpleGridForm
        py={4}
        w="full"
        bg="white"
        borderRadius="base"
        boxShadow="0px 0px 6px #00000034"
        spacingX="24px"
        spacingY="28px"
        px="24px"
      >
        <Menu />
        <TelasSelecao />
        <BarraInferior />
        <FluxoOperacional />
        <TelaFinal />
        <Produtos />
        <Imagens salvarImagem={salvarImagem} />
        <GridItem colSpan={12}>
          <Flex
            w="full"
            justify="center"
            gap="24px"
            height={['fit-content', 'full', 'full', 'full', 'fit-content']}
          >
            <Button
              variant="outlineDefault"
              colorScheme="gray"
              w="96px"
              height="32px"
              onClick={voltarParaConfiguracoesFrenteCaixa}
            >
              Cancelar
            </Button>
            <Button
              variant="solid"
              colorScheme="aquamarine"
              w="120px"
              height="32px"
              onClick={onSubmit}
            >
              Salvar
            </Button>
          </Flex>
        </GridItem>
      </SimpleGridForm>
    </FormProvider>
  );
};
