import { Flex } from '@chakra-ui/react';
import React from 'react';

import ConstanteRotas, { ConstanteRotasAlternativas } from 'constants/rotas';
import ConstanteFuncionalidades from 'constants/permissoes';
import { useNavMenuContext } from 'store/NavMenu';
import PlanoContratacaoEnum from 'constants/enum/planoContratacao';
import { enumReferenciaServicoStargate } from 'constants/enum/referenciaServicoStargate';
import auth from 'modules/auth';

import { permissaoListarOperacao } from 'pages/Vendas/Operacoes/PermissaoListarOperacao';
import PermissaoRelatorioVendas from 'pages/Relatorio/Vendas/PermissaoRelatorioVendas';
import PermissaoRelatorioEstoque from 'pages/Relatorio/Estoque/PermissaoRelatorioEstoque';
import PermissaoRelatorioProdutosVendidos from 'pages/Produtos/Relatorios/ProdutosVendidos/PermissaoRelatorioProdutosVendidos';
import {
  CadastrosClientesIcon,
  CadastrosFornecedoresIcon,
  CadastrosVendedoresIcon,
  CadastrosTransportadorasIcon,
  CadastrosProdutosIcon,
  CadastroVendasIcon,
  EstoqueEntradaMercadoriaIcon,
  EstoqueMovimentacaoEstoqueIcon,
  EstoqueTransferenciaEstoqueIcon,
  EstoqueHistoricoMovimentacaoIcon,
  EstoqueConferirEstoqueIcon,
  FinanceiroContasReceberIcon,
  FinanceiroContasPagarIcon,
  FiscalNotaFiscalIcon,
  FiscalExportarXmlIcon,
  DashboardIcon,
  MenuPdvIcon,
  ConfiguracoesMinhasLojasIcon,
  MenuOperacaoIcon,
  MenuFatura,
  MenuExtrato,
  MenuControleDeCaixa,
  MenuClientes,
  MenuUsuarios,
  MenuProdutos,
  MenuFinanceiro,
  MenuFiscal,
  MenuParametros,
  ConfiguracoesImportarFornecedoresIcon,
  VouchersIcon,
  MetasIcon,
  SmartPosIconMenu,
  LojaAplicativoIcon,
  FinanceiroIcon,
} from 'icons';

import Nav from './Nav';
import NavItem from './Nav/NavItem';
import { TitleOptionsNav, SeparatorChakra as Separator } from './Nav/Separator';

const Submenu = () => {
  const { navMenuIsOpen } = useNavMenuContext();

  const validandoFuncionalidadeOperacao = permissaoListarOperacao();
  const validandoFuncionalidadeRelatorioVendas = PermissaoRelatorioVendas();
  const validandoFuncionalidadeRelatorioEstoque = PermissaoRelatorioEstoque();
  const validandoFuncionalidadeRelatorioProdutosVendidos = PermissaoRelatorioProdutosVendidos();

  const sistema = auth.getSistema();
  const planoAtual = auth.getPlano();
  const planoStart = PlanoContratacaoEnum.START;
  const planoPro = PlanoContratacaoEnum.PRO;

  const isPossuiPermissaoPlano =
    planoAtual === planoStart || planoAtual === planoPro;

  const possuiPermissaoServicoDashboard = auth?.possuiServico(
    enumReferenciaServicoStargate.MODULO_DASHBOARD
  )?.permitido;

  const possuiPermissaoSmartPos = auth.possuiServico(
    enumReferenciaServicoStargate.DISPOSITIVO_SMART_POS
  ).permitido;

  const possuiServicoTray = auth.possuiServico(
    enumReferenciaServicoStargate.INTEGRACAO_TRAY
  ).permitido;

  const possuiServicoPdvAutonomo = auth.possuiServico(
    enumReferenciaServicoStargate.DISPOSITIVO_PDV
  ).permitido;

  const possuiServicoZoop = auth.possuiServico(
    enumReferenciaServicoStargate.INTEGRACAO_ZOOP
  ).permitido;

  const possuiPermissaoVisualizarTransacoesZoop = auth.possuiPermissao(
    ConstanteFuncionalidades.VISUALIZAR_TRANSACOES_ZOOP
  ).permitido;

  const possuiPermissaoCredenciarZoop = auth.possuiPermissao(
    ConstanteFuncionalidades.CREDENCIAR_ZOOP
  ).permitido;

  const possuiServicoFrenteCaixa = auth.possuiServico(
    enumReferenciaServicoStargate.DISPOSITIVO_FRENTE_CAIXA
  ).permitido;

  const openDashboardSTI3 = () => {
    const url = sistema?.name
      ? `https://dashboard.${sistema.name}.app/`
      : 'https://dashboard.sti3.com.br/';
    window.open(url, '_blank')?.focus();
  };

  const possuiEcommerce = [possuiServicoTray].some(
    (servico) => servico === true
  );

  const possuiIntegracoesVenda =
    possuiServicoZoop || possuiServicoPdvAutonomo || possuiServicoFrenteCaixa;

  return (
    <Flex
      gridArea="NM"
      position="relative"
      flexDirection="column"
      p="0"
      ml="46px"
      h="100vh"
      zIndex="1020"
      overflow="visible"
      transition="margin-left 0.2s"
      marginLeft={navMenuIsOpen ? '150px' : '46px'}
    >
      <Nav menuKey="cadastros">
        <NavItem
          submenuKey="cadastros-clientes"
          title="Clientes"
          icon={CadastrosClientesIcon}
          path={ConstanteRotas.CLIENTE}
          funcionalidade={ConstanteFuncionalidades.CLIENTE_LISTAR}
        />
        <NavItem
          submenuKey="cadastros-fornecedores"
          title="Fornecedores"
          icon={CadastrosFornecedoresIcon}
          path={ConstanteRotas.FORNECEDOR}
          funcionalidade={ConstanteFuncionalidades.FORNECEDOR_LISTAR}
        />
        <NavItem
          submenuKey="cadastros-vendedores"
          title="Vendedores"
          icon={CadastrosVendedoresIcon}
          path={ConstanteRotas.VENDEDOR}
          funcionalidade={ConstanteFuncionalidades.VENDEDOR_LISTAR}
        />
        <NavItem
          submenuKey="cadastros-transportadoras"
          title="Transportadoras"
          icon={CadastrosTransportadorasIcon}
          path={ConstanteRotas.TRANSPORTADORA}
          funcionalidade={ConstanteFuncionalidades.TRANSPORTADORA_LISTAR}
        />
        <NavItem
          submenuKey="cadastros-produtos"
          title="Produtos"
          icon={CadastrosProdutosIcon}
          path={ConstanteRotas.PRODUTO}
          funcionalidade={ConstanteFuncionalidades.PRODUTO_LISTAR}
        />
      </Nav>

      <Nav menuKey="vendas">
        <NavItem
          submenuKey="vendas-pdv"
          title="PDV"
          icon={MenuPdvIcon}
          path="/pdv"
        />
        <NavItem
          submenuKey="vendas-operacoes"
          title="Operações"
          icon={MenuOperacaoIcon}
          path={ConstanteRotas.OPERACOES}
          funcionalidade={validandoFuncionalidadeOperacao}
          isDisabled={!validandoFuncionalidadeOperacao}
          blockTipoPermissao
        />
        <NavItem
          submenuKey="vendas-vouchers"
          title="Vouchers"
          icon={VouchersIcon}
          path={ConstanteRotas.LISTAR_VOUCHERS}
          funcionalidade={ConstanteFuncionalidades.LISTAR_VOUCHERS}
        />
        <NavItem
          submenuKey="metas-comissoes"
          title="Metas e Comissões"
          icon={MetasIcon}
          path={ConstanteRotas.METAS_E_COMISSOES}
          funcionalidade=""
          isDisabled={isPossuiPermissaoPlano}
        />
        {possuiPermissaoSmartPos && (
          <NavItem
            submenuKey="smart-pos"
            title="Smart POS"
            icon={SmartPosIconMenu}
            path={ConstanteRotas.SMART_POS_LISTAGEM}
            funcionalidade={ConstanteFuncionalidades.SMART_POS_HABILITAR}
            isDisabled={!possuiPermissaoSmartPos}
          />
        )}
      </Nav>

      <Nav menuKey="estoque">
        <NavItem
          submenuKey="estoque-entradaMercadoria"
          title="Entrada de Mercadoria"
          icon={EstoqueEntradaMercadoriaIcon}
          path={ConstanteRotas.ENTRADA_MERCADORIA}
          funcionalidade={ConstanteFuncionalidades.ENTRADA_MERCADORIA_LISTAR}
        />
        <NavItem
          submenuKey="estoque-movimentacaoEstoque"
          title="Movimentação de Estoque"
          icon={EstoqueMovimentacaoEstoqueIcon}
          path={ConstanteRotas.MOVIMENTACAO_ESTOQUE}
          funcionalidade={ConstanteFuncionalidades.MOVIMENTACAO_ESTOQUE_LISTAR}
        />
        <NavItem
          submenuKey="estoque-transferenciaEstoque"
          title="Transferência de Estoque"
          icon={EstoqueTransferenciaEstoqueIcon}
          path={ConstanteRotas.TRANSFERENCIA_ESTOQUE}
          funcionalidade={ConstanteFuncionalidades.TRANSFERENCIA_ESTOQUE_LISTAR}
        />
        <NavItem
          submenuKey="estoque-conferenciaEstoque"
          title="Conferência de Estoque"
          icon={EstoqueConferirEstoqueIcon}
          path={ConstanteRotas.CONFERENCIA_ESTOQUE}
          funcionalidade={ConstanteFuncionalidades.CONFERENCIA_ESTOQUE_INICIAR}
        />
        <Separator />
        <NavItem
          submenuKey="estoque-historicoProduto"
          title="Histórico de Produto"
          icon={EstoqueHistoricoMovimentacaoIcon}
          path={ConstanteRotas.HISTORICO_PRODUTO}
          funcionalidade={ConstanteFuncionalidades.HISTORICO_PRODUTO_LISTAR}
        />
      </Nav>

      <Nav menuKey="financeiro">
        <NavItem
          submenuKey="financeiro-contasReceber"
          title="Contas a Receber"
          icon={FinanceiroContasReceberIcon}
          path={ConstanteRotas.CONTAS_RECEBER}
          funcionalidade={ConstanteFuncionalidades.CONTAS_RECEBER_LISTAR}
        />
        <NavItem
          submenuKey="financeiro-contasPagar"
          title="Contas a Pagar"
          icon={FinanceiroContasPagarIcon}
          path={ConstanteRotas.CONTAS_PAGAR}
          funcionalidade={ConstanteFuncionalidades.CONTAS_PAGAR_LISTAR}
        />
        <NavItem
          submenuKey="financeiro-recebimentoContas"
          title="Recebimento de Contas"
          icon={FinanceiroContasPagarIcon}
          path={ConstanteRotas.RECEBIMENTO_CONTAS}
          funcionalidade={ConstanteFuncionalidades.RECEBIMENTO_CONTAS_LISTAR}
        />
        <NavItem
          submenuKey="financeiro-conciliacaoContas"
          title="Conciliação de Contas"
          icon={FinanceiroIcon}
          path={ConstanteRotas.CONCILIACAO_CONTAS}
          funcionalidade={ConstanteFuncionalidades.CONTAS_RECEBER_LISTAR}
        />
        <NavItem
          submenuKey="financeiro-extrato"
          title="Extrato"
          icon={MenuExtrato}
          path={ConstanteRotas.EXTRATOS}
          funcionalidade={ConstanteFuncionalidades.EXTRATO_LISTAR}
        />
        <NavItem
          submenuKey="financeiro-controleCaixa"
          title="Controle de Caixa"
          icon={MenuControleDeCaixa}
          path={ConstanteRotas.CONTROLE_CAIXA}
          funcionalidade={ConstanteFuncionalidades.CONTROLE_CAIXA_LISTAR}
        />
        <NavItem
          submenuKey="financeiro-faturas"
          title="Faturas"
          icon={MenuFatura}
          path={ConstanteRotas.FATURAS}
          funcionalidade={ConstanteFuncionalidades.FATURA_LISTAR}
        />
      </Nav>

      <Nav menuKey="fiscal">
        <NavItem
          submenuKey="fiscal-notaFiscal"
          title="Notas Fiscais"
          icon={FiscalNotaFiscalIcon}
          path={ConstanteRotas.NOTA_FISCAL}
          funcionalidade={ConstanteFuncionalidades.NOTA_FISCAL_EMITIR}
        />
        <NavItem
          submenuKey="fiscal-exportarXml"
          title="Exportar XML"
          icon={FiscalExportarXmlIcon}
          path={ConstanteRotas.EXPORTAR_XML}
          funcionalidade={
            ConstanteFuncionalidades.DOCUMENTO_FISCAL_EXPORTACAO_LISTAR
          }
        />
        <NavItem
          submenuKey="fiscal-manifestacaoDestinatario"
          title="Manifestação do Destinatário"
          icon={FiscalExportarXmlIcon}
          path={ConstanteRotas.MANIFESTACAO_DESTINATARIO}
          funcionalidade={
            ConstanteFuncionalidades.MANIFESTACAO_DESTINATARIO_VISUALIZAR
          }
        />
      </Nav>

      <Nav menuKey="relatorios">
        <TitleOptionsNav icon={MenuProdutos} title="Produtos" />
        <NavItem
          submenuKey="relatorios-produtos-vendidos"
          title="Produtos Vendidos"
          path={ConstanteRotas.RELATORIO_PRODUTOS_VENDIDOS}
          funcionalidade={validandoFuncionalidadeRelatorioProdutosVendidos}
        />
        <NavItem
          submenuKey="relatorios-produtos-preco"
          title="Produtos com Preço"
          path={ConstanteRotas.RELATORIO_PRODUTOS_COM_PRECO}
          funcionalidade={ConstanteFuncionalidades.RELATORIO_PRODUTOS_COM_PRECO}
        />
        <NavItem
          submenuKey="relatorios-catalogo-produtos"
          title="Catálogo de produtos"
          path={ConstanteRotas.RELATORIO_CATALOGO_PRODUTOS}
          funcionalidade={ConstanteFuncionalidades.RELATORIO_CATALOGO_PRODUTOS}
        />
        <NavItem
          submenuKey="relatorios-produtos-personalizados"
          title="Personalizados"
          path={ConstanteRotas.RELATORIO_PRODUTOS_PERSONALIZADOS}
          funcionalidade={
            ConstanteFuncionalidades.RELATORIO_PRODUTO_PERSONALIZADO
          }
        />
        <NavItem
          submenuKey="relatorios-inventario"
          title="Inventário"
          path={ConstanteRotas.RELATORIO_INVENTARIO}
          funcionalidade={ConstanteFuncionalidades.RELATORIO_INVENTARIO}
        />
        <NavItem
          submenuKey="relatorios-estoque"
          title="Estoque"
          path={ConstanteRotas.RELATORIO_ESTOQUE}
          funcionalidade={validandoFuncionalidadeRelatorioEstoque}
        />
        <Separator />
        <TitleOptionsNav icon={MenuClientes} title="Clientes" />
        <NavItem
          submenuKey="relatorios-clientes"
          title="Cadastro Completo"
          path={ConstanteRotas.RELATORIO_CADASTRO_COMPLETO}
          funcionalidade={
            ConstanteFuncionalidades.RELATORIO_CADASTRO_COMPLETO_CLIENTE
          }
        />
        <NavItem
          submenuKey="relatorios-clientes"
          title="Personalizados"
          path={ConstanteRotas.RELATORIO_CLIENTES}
          funcionalidade={
            ConstanteFuncionalidades.RELATORIO_CLIENTE_PERSONALIZADO
          }
        />
        <NavItem
          submenuKey="relatorio-informacoes-clientes"
          title="Informações dos clientes"
          funcionalidade={
            ConstanteFuncionalidades.RELATORIO_INFORMACOES_CLIENTE
          }
          path={ConstanteRotas.RELATORIO_INFORMACOES_CLIENTES}
        />
        <Separator />
        <TitleOptionsNav title="Vendas" icon={CadastroVendasIcon} />
        <NavItem
          submenuKey="relatorios-vendas"
          title="Vendas"
          path={ConstanteRotas.RELATORIO_DAS_VENDAS}
          funcionalidade={validandoFuncionalidadeRelatorioVendas}
        />
        <NavItem
          submenuKey="relatorios-curva-abc"
          title="Curva ABC"
          path={ConstanteRotas.RELATORIO_CURVA_ABC}
          funcionalidade={ConstanteFuncionalidades.RELATORIO_CURVA_ABC}
        />
        <Separator />
        <TitleOptionsNav title="Operações" icon={CadastroVendasIcon} />
        <NavItem
          submenuKey="relatorios-consignacao"
          title="Consignação "
          path={ConstanteRotas.RELATORIO_CONSIGNACAO}
          funcionalidade={ConstanteFuncionalidades.RELATORIO_CONSIGNACAO}
        />
      </Nav>

      <Nav menuKey="sti3pay">
        <NavItem
          submenuKey="sti3pay-simulacaoVendas"
          title="Simulação de Vendas"
          icon={DashboardIcon}
          path={ConstanteRotas.STI3PAY_SIMULACAO_VENDAS}
          funcionalidade={ConstanteFuncionalidades.PERMISSAO_INEXISTENTE}
        />
        <NavItem
          submenuKey="sti3pay-historico"
          title="Histórico"
          icon={DashboardIcon}
          path={ConstanteRotas.STI3PAY_HISTORICO}
          funcionalidade={ConstanteFuncionalidades.PERMISSAO_INEXISTENTE}
        />
      </Nav>

      <Nav menuKey="integracoes">
        <NavItem
          submenuKey="loja-aplicativo"
          title="Loja de aplicativos"
          icon={LojaAplicativoIcon}
          path={ConstanteRotas.LOJA_APLICATIVOS}
          funcionalidade=""
        />
        {possuiIntegracoesVenda && (
          <>
            <Separator title="Vendas" />
            {possuiServicoZoop && (
              <NavItem
                submenuKey="zoop-integracao"
                title="Zoop Recebimentos"
                path={ConstanteRotas.INTEGRACAO_ZOOP_GERAL}
                isDisabled={
                  !possuiPermissaoCredenciarZoop &&
                  !possuiPermissaoVisualizarTransacoesZoop
                }
              />
            )}
            {possuiServicoPdvAutonomo && (
              <NavItem
                submenuKey="pdv-autonomo"
                title="PDV Offline"
                path={ConstanteRotas.PDV_OFFLINE}
                funcionalidade=""
              />
            )}
            {possuiServicoFrenteCaixa && (
              <NavItem
                submenuKey="frente-caixa"
                title="Frente de Caixa"
                path={ConstanteRotas.FRENTE_CAIXA_PAINEL}
                funcionalidade={
                  ConstanteFuncionalidades.FRENTE_CAIXA_CONFIGURAR
                }
              />
            )}
          </>
        )}
        {possuiPermissaoServicoDashboard && (
          <>
            <Separator title="Gerencial" />
            <NavItem
              submenuKey="dashboard"
              title="Dashboard gerencial"
              icon={DashboardIcon}
              path=""
              funcionalidade=""
              handleClick={openDashboardSTI3}
              isDisabled={!possuiPermissaoServicoDashboard}
            />
          </>
        )}
        {possuiEcommerce && (
          <>
            <Separator title="E-commerce" />
            {possuiServicoTray && (
              <NavItem
                submenuKey="tray"
                title="Tray"
                path={ConstanteRotasAlternativas.TRAY_ETAPAS}
                funcionalidade=""
              />
            )}
          </>
        )}
      </Nav>

      <Nav menuKey="configuracoes">
        <TitleOptionsNav icon={ConfiguracoesMinhasLojasIcon} title="Empresas" />
        <NavItem
          submenuKey="configuracoes-minhasLojas"
          title="Minhas Lojas"
          path={ConstanteRotas.MINHAS_LOJAS}
          funcionalidade={ConstanteFuncionalidades.LOJA_LISTAR}
        />
        <NavItem
          submenuKey="configuracoes-multas-juros"
          title="Multas e Juros"
          path={ConstanteRotas.MULTAS_JUROS}
          funcionalidade={ConstanteFuncionalidades.MULTA_JUROS_ALTERAR}
        />

        <Separator />
        <TitleOptionsNav icon={MenuUsuarios} title="Usuários" />
        <NavItem
          submenuKey="configuracoes-usuarios"
          title="Usuários"
          path={ConstanteRotas.USUARIO}
          funcionalidade={ConstanteFuncionalidades.USUARIO_LISTAR}
        />
        <NavItem
          submenuKey="configuracoes-perfilUsuarios"
          title="Perfil de Usuários"
          path={ConstanteRotas.PERFIL_USUARIO}
          funcionalidade={ConstanteFuncionalidades.PERFIL_USUARIO_LISTAR}
        />
        <NavItem
          submenuKey="configuracoes-historicoAcoes"
          title="Histórico de Ações"
          path={ConstanteRotas.HISTORICO_ACOES}
          funcionalidade={ConstanteFuncionalidades.HISTORICO_ACOES_LISTAR}
        />

        <Separator />
        <TitleOptionsNav icon={MenuClientes} title="Clientes" />
        <NavItem
          submenuKey="configuracoes-categoriaClientes"
          title="Categoria de Clientes"
          path={ConstanteRotas.CATEGORIA_CLIENTE}
          funcionalidade={ConstanteFuncionalidades.CATEGORIA_CLIENTE_LISTAR}
        />
        <NavItem
          submenuKey="configuracoes-importarClientes"
          title="Importar Clientes"
          path={ConstanteRotas.IMPORTAR_CLIENTES}
          funcionalidade={ConstanteFuncionalidades.IMPORTACAO_CLIENTE_LISTAR}
        />

        <NavItem
          submenuKey="configuracoes-relatorio-personalizado-clientes"
          title="Relatórios Personalizados"
          path={ConstanteRotas.RELATORIO_CADASTRO_CLIENTES}
          funcionalidade={
            ConstanteFuncionalidades.RELATORIO_PERSONALIZADO_CLIENTES_CADASTRAR
          }
        />

        <Separator />
        <TitleOptionsNav
          icon={ConfiguracoesImportarFornecedoresIcon}
          title="Fornecedores"
        />
        <NavItem
          submenuKey="configuracoes-importarFornecedores"
          title="Importar Fornecedores"
          path={ConstanteRotas.IMPORTAR_FORNECEDORES}
          funcionalidade={ConstanteFuncionalidades.IMPORTACAO_FORNECEDOR_LISTAR}
        />

        <Separator />
        <TitleOptionsNav icon={MenuProdutos} title="Produtos" />
        <NavItem
          submenuKey="configuracoes-categoriaProdutos"
          title="Categoria de Produtos"
          path={ConstanteRotas.CATEGORIA_PRODUTO}
          funcionalidade={ConstanteFuncionalidades.CATEGORIA_PRODUTO_LISTAR}
        />
        <NavItem
          submenuKey="configuracoes-tabelaPrecos"
          title="Tabela de Preços"
          path={ConstanteRotas.TABELA_PRECOS}
          funcionalidade={ConstanteFuncionalidades.TABELA_PRECO_LISTAR}
        />
        {possuiServicoFrenteCaixa && (
          <NavItem
            submenuKey="configuracoes-promocao"
            title="Promoção"
            path={ConstanteRotas.PROMOCAO}
            funcionalidade={ConstanteFuncionalidades.PROMOCAO_CONFIGURAR}
          />
        )}
        <NavItem
          submenuKey="configuracoes-unidadesMedida"
          title="Unidades de Medida"
          path={ConstanteRotas.UNIDADE_MEDIDA}
          funcionalidade={ConstanteFuncionalidades.UNIDADE_MEDIDA_LISTAR}
        />
        <NavItem
          submenuKey="configuracoes-marcas"
          title="Marcas"
          path={ConstanteRotas.MARCA}
          funcionalidade={ConstanteFuncionalidades.MARCA_LISTAR}
        />
        <NavItem
          submenuKey="configuracoes-cores"
          title="Cores"
          path={ConstanteRotas.COR}
          funcionalidade={ConstanteFuncionalidades.COR_LISTAR}
        />
        <NavItem
          submenuKey="configuracoes-tamanhos"
          title="Tamanhos"
          path={ConstanteRotas.TAMANHO}
          funcionalidade={ConstanteFuncionalidades.TAMANHO_LISTAR}
        />
        <NavItem
          submenuKey="configuracoes-localEstoque"
          title="Local do Estoque"
          path={ConstanteRotas.LOCAL_ESTOQUE}
          funcionalidade={ConstanteFuncionalidades.LOCAL_ESTOQUE_LISTAR}
        />

        <NavItem
          submenuKey="configuracoes-etiqueta-personalizada"
          title="Etiquetas Personalizadas"
          path={ConstanteRotas.ETIQUETAS_PERSONALIZADAS}
          funcionalidade={
            ConstanteFuncionalidades.ETIQUETAS_PERSONALIZADAS_LISTAR
          }
        />

        <NavItem
          submenuKey="configuracoes-relatorios-produtos-personalizados"
          title="Relatórios Personalizados"
          path={ConstanteRotas.RELATORIO_PRODUTOS_CADASTRO}
          funcionalidade={
            ConstanteFuncionalidades.RELATORIO_PERSONALIZADO_PRODUTOS_LISTAR
          }
        />
        <NavItem
          submenuKey="configuracoes-importarProdutos"
          title="Importar Produtos"
          path={ConstanteRotas.IMPORTAR_PRODUTOS}
          funcionalidade={ConstanteFuncionalidades.IMPORTACAO_PRODUTO_LISTAR}
        />

        <Separator />
        <TitleOptionsNav icon={MenuFinanceiro} title="Financeiro" />
        <NavItem
          submenuKey="configuracoes-contasFinanceiras"
          title="Contas Financeiras"
          path={ConstanteRotas.CONTA_FINANCEIRA}
          funcionalidade={ConstanteFuncionalidades.CONTA_FINANCEIRA_LISTAR}
        />
        <NavItem
          submenuKey="configuracoes-planoContas"
          title="Plano de Contas"
          path={ConstanteRotas.PLANO_CONTAS}
          funcionalidade={ConstanteFuncionalidades.PLANO_CONTA_LISTAR}
        />
        <NavItem
          submenuKey="configuracoes-formaPagamento"
          title="Formas de Pagamento"
          path={ConstanteRotas.FORMA_PAGTO}
          funcionalidade={ConstanteFuncionalidades.FORMA_PAGTO_LISTAR}
        />
        <NavItem
          submenuKey="configuracoes-formaRecebimento"
          title="Formas de Recebimento"
          path={ConstanteRotas.FORMA_RECEB}
          funcionalidade={ConstanteFuncionalidades.FORMA_RECEB_LISTAR}
        />
        <NavItem
          submenuKey="configuracoes-credenciadoraCartao"
          title="Credenciadora de Cartão"
          path={ConstanteRotas.CREDENCIADORA_CARTAO}
          funcionalidade={ConstanteFuncionalidades.CREDENCIADORA_CARTAO_LISTAR}
        />
        <Separator />
        <TitleOptionsNav icon={MenuFiscal} title="Fiscal" />
        <NavItem
          submenuKey="configuracoes-regraFiscal"
          title="Regra Fiscal"
          path={ConstanteRotas.REGRA_FISCAL}
          funcionalidade={ConstanteFuncionalidades.REGRA_FISCAL_LISTAR}
        />
        <Separator />
        <TitleOptionsNav icon={MenuParametros} title="Parâmetros" />
        <NavItem
          submenuKey="configuracoes-camposPersonalizados"
          title="Campos Personalizados"
          path={ConstanteRotas.CAMPO_PERSONALIZADO}
          funcionalidade={ConstanteFuncionalidades.CAMPO_PERSONALIZADO_LISTAR}
        />
        <NavItem
          submenuKey="configuracoes-padronizacao"
          title="Padronização"
          path={ConstanteRotas.PADRONIZACAO}
          funcionalidade={ConstanteFuncionalidades.PADRONIZACAO_ALTERAR}
        />
      </Nav>
    </Flex>
  );
};

export default Submenu;
