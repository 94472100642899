import { IdentificacaoIntegracao } from 'constants/enum/IdentificacaoIntegracao';
import IdentificacaoTipoOperacaoEnum from 'constants/enum/identificacaoTipoOperacao';
import PlanoContratacaoEnum from 'constants/enum/planoContratacao';
import ConstanteFuncionalidades from 'constants/permissoes';
import { setDateMinHours, setDateMaxHours } from 'helpers/data/setHoursDate';
import auth from 'modules/auth';

export type ClienteId = {
  label: string | null;
  value: string | null;
};

export type FormData = {
  clienteId: ClienteId | string;
  status: number | null;
  numeroOperacao?: number | string;
  filtrarPeloCaixaAtual?: boolean;
  dataEmissaoInicio: Date;
  dataEmissaoFim: Date;
  possuiDevolucao: boolean;
  identificacaoTipoOperacao: number[] | [];
  origem?: number | null;
  contasFinanceirasId?: string[];
  isTodosCaixasSelecionados?: boolean;
};
export interface DocumentosFiscalProps {
  id: string;
  modeloFiscal: number;
  numeroFiscal: string;
  status: number;
}
export interface OperacaoProps {
  cliente: string;
  fantasia: string;
  modeloNumeroFiscais: Array<DocumentosFiscalProps>;
  id: string;
  identificacaoTipoOperacao: number;
  numeroOperacao: number;
  dataEmissao: string;
  origemEnum: number;
  status: number;
  valorTotal: number;
  possuiDevolucao: boolean;
  operacaoOrigemDevolucao: null;
  origem: string | null;
  contasFinanceirasId?: string[];
}

const planoAtual = auth.getPlano();

export const temPlanoStart = PlanoContratacaoEnum.START === planoAtual;

export const possuiPermissaoListarDevolucoes = auth.possuiPermissao(
  ConstanteFuncionalidades.LISTAR_TROCA_DEVOLUCAO
).permitido;
export const possuiPermissaoListarVendasPedidos = auth.possuiPermissao(
  ConstanteFuncionalidades.VENDASPEDIDOS_LISTAR
).permitido;
export const possuiPermissaoListarConsignacao = auth.possuiPermissao(
  ConstanteFuncionalidades.CONSIGNACAO_LISTAR
).permitido;
export const possuiPermissaoListarOrcamento = auth.possuiPermissao(
  ConstanteFuncionalidades.ORCAMENTO_LISTAR
).permitido;

export const getCamposDefaultIdentificacaoTipoOperacao = () => {
  const valoresVendasPedidos = [IdentificacaoTipoOperacaoEnum.VENDA];

  if (
    possuiPermissaoListarDevolucoes &&
    possuiPermissaoListarVendasPedidos &&
    !temPlanoStart
  ) {
    return [...valoresVendasPedidos, IdentificacaoTipoOperacaoEnum.DEVOLUCAO];
  }
  if (possuiPermissaoListarVendasPedidos) {
    return valoresVendasPedidos;
  }
  if (possuiPermissaoListarDevolucoes && !temPlanoStart) {
    return [IdentificacaoTipoOperacaoEnum.DEVOLUCAO];
  }

  return [];
};

export const formDefaultValues = (): FormData => ({
  clienteId: '',
  status: null,
  dataEmissaoInicio: setDateMinHours(new Date()),
  dataEmissaoFim: setDateMaxHours(new Date()),
  filtrarPeloCaixaAtual: true,
  possuiDevolucao: false,
  identificacaoTipoOperacao: getCamposDefaultIdentificacaoTipoOperacao(),
  origem: IdentificacaoIntegracao.TODAS,
  isTodosCaixasSelecionados: false,
  numeroOperacao: '',
});
