import {
  Flex,
  Icon,
  Button,
  Box,
  Divider,
  Stack,
  Text,
  IconButton,
  FormLabel,
  useBreakpointValue,
  useMediaQuery,
  useDisclosure,
} from '@chakra-ui/react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { RiShareLine } from 'react-icons/ri';

import auth from 'modules/auth';
import api, { ResponseApi } from 'services/api';
import { useSignalRContext } from 'store/SignalR';
import { useOperacoesFiltroContext } from 'store/Operacoes/OperacoesFiltros';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import ConstanteFuncionalidades from 'constants/permissoes';
import LogAuditoriaTelaEnum from 'constants/enum/logAuditoriaTela';
import {
  formatQueryObject,
  formatQueryPagegTable,
} from 'helpers/format/formatQueryParams';
import cancelarOperacao from 'helpers/api/Operacao/cancelarOperacao';
import obterConfigEmitirNFe from 'helpers/api/Loja/obterConfigEmitirNFe';
import { ImprimirPDF } from 'helpers/impressoes/imprimirPDF';
import { setDateMaxHours } from 'helpers/data/setHoursDate';
import obterInformacoesFinalizarVenda from 'helpers/api/Loja/obterInformacoesFinalizarVenda';
import { useDebounce } from 'hooks/useDebounce';

import ManterFoco from 'components/Geral/ManterFoco';
import { TextValor } from 'components/PDV/Text/TextValor';
import { EmailModal } from 'components/PDV/CompartilharDocumentosFiscais/EmailModal';
import { ModalCompartilhar } from 'components/Modal/ModalCompartilhar';
import ModalAutorizacaoFuncionalidade from 'components/Modal/ModalAutorizacaoFuncionalidade';
import ModalConfirmacao from 'components/PDV/Modal/ModalConfirmacao';
import ModalMotivoCancelamento from 'components/PDV/Modal/ModalMotivoCancelamento';
import InputDateRange from 'components/PDV/InputDateRange';
import { SelectMulti } from 'components/Select/SelectMultiCheckbox';
import { BuscaAvancadaButton } from 'components/update/BuscaAvancadaButton';
import {
  PagedTable,
  PagedTableForwardRefData,
} from 'components/update/Table/PagedTable';
import {
  GridPaginadaConsulta,
  GridPaginadaVendaRetorno,
} from 'components/GridVendas/Paginacao';
import { LeitorCodigoBarrasIcon } from 'icons';
import { ModalCodigoBarras } from 'pages/PDV/Lancamento/AdicionarEditarItem/ModalCodigoBarras';
import Input from 'components/PDV/Input';

import {
  formDefaultValues,
  OperacaoProps,
  FormData,
  ClienteId,
  possuiPermissaoListarConsignacao,
  possuiPermissaoListarDevolucoes,
  possuiPermissaoListarOrcamento,
  possuiPermissaoListarVendasPedidos,
  temPlanoStart,
} from './validationForm';
import ModalBuscaAvancada from './ModalBuscaAvancada';
import OperacoesItens from './OperacoesItens';

type CaixaAbertoProps = {
  id: string;
  nome: string;
};

type OpcoesTipoOperacaoProps = {
  label: string;
  value: number;
};

export const ListarOperacoes = () => {
  const caixaAbertoInformacoes = useRef({} as CaixaAbertoProps);
  const possuiCaixaAberto = useRef(false);
  const pagedTableRef = useRef<PagedTableForwardRefData>(null);
  const motivoCancelamento = useRef<string>();
  const motivoCancelamentoNotaFiscal = useRef<string>();

  const defaultValuesIniciais = formDefaultValues();
  const {
    contextFiltros,
    carregarDataContextRef,
    setContextFiltros,
  } = useOperacoesFiltroContext();

  const filtrosIniciais = useMemo(() => {
    return {
      ...defaultValuesIniciais,
      identificacaoTipoOperacao: carregarDataContextRef.current
        ? contextFiltros.identificacaoTipoOperacao
        : defaultValuesIniciais.identificacaoTipoOperacao,
      contasFinanceirasId: [caixaAbertoInformacoes.current?.id],
    };
  }, [carregarDataContextRef, contextFiltros, defaultValuesIniciais]);

  const formMethods = useForm({
    defaultValues: filtrosIniciais,
  });
  const { watch, reset, getValues, setValue } = formMethods;

  const [operacoesSelecionadasWatch] = watch(['identificacaoTipoOperacao']);

  const { hubConnection, joinGroup } = useSignalRContext();

  const isResponsiveVersion = useBreakpointValue({
    base: true,
    md: false,
    lg: false,
  });
  const [isSmallerThan860] = useMediaQuery('(max-width: 860px)');

  const [filtrosAtuais, setFiltrosAtuais] = useState<FormData>(filtrosIniciais);
  const [operacoes, setOperacoes] = useState<OperacaoProps[]>();
  const [totalOperacoes, setTotalOperacoes] = useState(0);
  const [valorTotalEfetuadas, setValorTotalEfetuadas] = useState(0);
  const [valorTotalCanceladas, setValorTotalCanceladas] = useState(0);
  const [isVenda, setIsVenda] = useState(false);
  const [operacaoId, setOperacaoId] = useState('');
  const [
    chaveTemporariaCancelamento,
    setChaveTemporariaCancelamento,
  ] = useState('');
  const [hasNotaFiscal, setHasNotaFiscal] = useState(false);
  const [podeEmitirNFe, setPodeEmitirNFe] = useState(false);
  const [podeEmitirNFCe, setPodeEmitirNFCe] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [buscaPeloCaixaJaFeita, setBuscaPeloCaixaJaFeita] = useState(false);
  const [hasFilters, setHasFilters] = useState(false);
  const [selectedOperationQuery, setStringComOperacoesEscolhidas] = useState(
    ''
  );

  const [isModalBuscaAvancadaOpen, setIsModalBuscaAvancadaOpen] = useState(
    false
  );
  const [
    modalMotivoCancelamentoIsOpen,
    setModalMotivoCancelamentoIsOpen,
  ] = useState(false);
  const [
    modalConfirmacaoCancelarIsOpen,
    setModalConfirmacaoCancelarIsOpen,
  ] = useState(false);
  const [
    modalAutorizacaoCancelarIsOpen,
    setModalAutorizacaoCancelarIsOpen,
  ] = useState(false);
  const [searchNumberOperation, setSearchNumberOperation] = useState('');
  const debouncedValue = useDebounce(searchNumberOperation, 500);

  const {
    isOpen: isModalCodigoBarrasOpen,
    onOpen: onModalCodigoBarrasOpen,
    onClose: onModalCodigoBarrasClose,
  } = useDisclosure();

  const hasItensInTable = operacoes && operacoes?.length > 0;
  const permissaoCancelarOperacao = auth.possuiPermissao(
    ConstanteFuncionalidades.PDV_CANCELAR.codigo
  );

  const filtersSubmit = formMethods.handleSubmit((data) => {
    const filtros = {
      ...data,
      identificacaoTipoOperacao: operacoesSelecionadasWatch,
    };
    setFiltrosAtuais(filtros);
    setContextFiltros(filtros);
  });

  const opcoesCampoTipoOperacao = () => {
    const opcoes = [] as OpcoesTipoOperacaoProps[];

    if (possuiPermissaoListarVendasPedidos) {
      opcoes.push({ label: 'Vendas', value: 1 });
      opcoes.push({ label: 'Pedidos', value: 2 });
    }
    if (possuiPermissaoListarOrcamento) {
      opcoes.push({ label: 'Orçamentos', value: 3 });
    }
    if (possuiPermissaoListarDevolucoes && !temPlanoStart) {
      opcoes.push({ label: 'Devoluções', value: 5 });
    }
    if (possuiPermissaoListarConsignacao && !temPlanoStart) {
      opcoes.push({ label: 'Consignações', value: 26 });
    }
    return opcoes;
  };

  const optionsTipoOperacao = opcoesCampoTipoOperacao();

  const handleSubmitModalBuscaAvancada = (data: FormData) => {
    reset(data);

    filtersSubmit();
  };

  const handleReset = useCallback(() => {
    reset(filtrosIniciais);
    setFiltrosAtuais(filtrosIniciais);
    setIsModalBuscaAvancadaOpen(false);
    setHasFilters(false);
  }, [filtrosIniciais, reset]);

  const handleInputNumberOperation = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    e.currentTarget.value = e.currentTarget.value.replace(/[^0-9]/g, '');
    setSearchNumberOperation(e.currentTarget.value);
  };

  const handleResetTableOrder = useCallback(() => {
    return pagedTableRef.current?.reload();
  }, []);

  const handleAplicarFiltrosSalvos = useCallback(() => {
    if (!carregarDataContextRef.current) return;

    if (
      carregarDataContextRef.current &&
      Object.keys(contextFiltros).length > 0
    ) {
      reset(contextFiltros);
    }

    carregarDataContextRef.current = false;
  }, [carregarDataContextRef, contextFiltros, reset]);

  const createContasFinanceirasFilterQuery = useCallback(() => {
    const naoExisteContaFinanceira =
      filtrosAtuais?.contasFinanceirasId?.every((item) => item === undefined) &&
      filtrosAtuais?.contasFinanceirasId.length > 0;

    const filtroContaFinanceira = naoExisteContaFinanceira
      ? [caixaAbertoInformacoes.current?.id]
      : filtrosAtuais?.contasFinanceirasId;

    let contaFinanceira = '';

    if (filtrosAtuais.isTodosCaixasSelecionados) {
      contaFinanceira = 'undefined';
    } else {
      (filtroContaFinanceira || []).forEach((tipoOperacao: string) => {
        contaFinanceira += `&contasFinanceirasId=${tipoOperacao}`;
      });
    }

    return contaFinanceira.includes('undefined') ? '' : contaFinanceira;
  }, [filtrosAtuais]);

  const paginationHandle = useCallback(
    async (paginationData: GridPaginadaConsulta) => {
      setIsLoading(true);
      let params;
      const filtros = {
        origem: filtrosAtuais.origem
          ? filtrosAtuais.origem
          : filtrosAtuais.origem === 0
          ? 0
          : '',
        clienteId: (filtrosAtuais.clienteId as ClienteId)?.value
          ? (filtrosAtuais.clienteId as ClienteId)?.value
          : '',
        status: filtrosAtuais.status ? filtrosAtuais.status : '',
        dataEmissaoInicio: filtrosAtuais.dataEmissaoInicio.toISOString(),
        dataEmissaoFim: setDateMaxHours(
          new Date(filtrosAtuais.dataEmissaoFim)
        ).toISOString(),
        possuiDevolucao: filtrosAtuais.possuiDevolucao,
      };

      const financeAccountsFilterQuery = createContasFinanceirasFilterQuery();

      if (
        filtrosAtuais.numeroOperacao === undefined ||
        filtrosAtuais.numeroOperacao === 0
      ) {
        params = {
          ...filtros,
        };
      } else {
        params = {
          ...filtros,
          numeroOperacao: filtrosAtuais.numeroOperacao,
        };
      }
      if (!selectedOperationQuery || !buscaPeloCaixaJaFeita) return;

      handleAplicarFiltrosSalvos();

      const response = await api.get<
        void,
        ResponseApi<GridPaginadaVendaRetorno<OperacaoProps>>
      >(
        `${formatQueryPagegTable(
          ConstanteEnderecoWebservice.PEDIDOORCAMENTOVENDA_LISTAR_PAGINADO_VENDAS,
          paginationData,
          params
        )}${selectedOperationQuery} ${financeAccountsFilterQuery}`
      );

      if (response) {
        if (response.avisos) {
          response.avisos.forEach((avisos) => toast.warning(avisos));
        }
        if (response.sucesso) {
          setTotalOperacoes(response.dados.totalOperacoes);
          setValorTotalEfetuadas(response.dados.valorTotalEfetuadas);
          setValorTotalCanceladas(response.dados.valorTotalCanceladas);
          setOperacoes(response.dados.registros);
        }
      }
      setIsLoading(false);
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      carregarDataContextRef,
      selectedOperationQuery,
      filtrosAtuais,
      buscaPeloCaixaJaFeita,
    ]
  );

  const handleCancelarOperacao = async (chavePermissaoTemporaria?: string) => {
    setIsLoading(true);

    await cancelarOperacao(
      {
        id: operacaoId || '',
        tela: LogAuditoriaTelaEnum.PDV,
        motivoCancelamento: motivoCancelamento.current,
        motivoCancelamentoNotaFiscal: motivoCancelamentoNotaFiscal.current,
      },
      hubConnection,
      joinGroup,
      () => {
        motivoCancelamento.current = undefined;
        motivoCancelamentoNotaFiscal.current = undefined;

        handleResetTableOrder();
        setIsLoading(false);
      },
      () => {
        setIsLoading(false);
      },
      chavePermissaoTemporaria
    );
  };

  const handleCancelar = () => {
    if (operacaoId) {
      if (permissaoCancelarOperacao.permitido) {
        if (isVenda) {
          setModalMotivoCancelamentoIsOpen(true);
        } else {
          handleCancelarOperacao();
        }
      } else {
        setModalAutorizacaoCancelarIsOpen(true);
      }
    }
  };

  const handleConfirmarMotivoCancelamento = (
    motivo: string,
    motivoNotaFiscal?: string
  ) => {
    motivoCancelamento.current = motivo;
    motivoCancelamentoNotaFiscal.current = motivoNotaFiscal;

    handleCancelarOperacao(chaveTemporariaCancelamento ?? undefined);
    setModalMotivoCancelamentoIsOpen(false);
  };

  const handleConfirmarPermissaoCancelar = (
    chavePermissaoTemporaria: string
  ) => {
    if (operacaoId) {
      if (isVenda) {
        setModalMotivoCancelamentoIsOpen(true);
        setChaveTemporariaCancelamento(chavePermissaoTemporaria);
      } else {
        handleCancelarOperacao(chavePermissaoTemporaria);
      }
    }
  };

  const formatFiltersForSharing = useCallback(() => {
    const filtros = {
      clienteId: (filtrosAtuais.clienteId as ClienteId)?.value
        ? (filtrosAtuais.clienteId as ClienteId)?.value
        : '',
      status: filtrosAtuais.status ? filtrosAtuais.status : '',
      dataEmissaoInicio: filtrosAtuais.dataEmissaoInicio.toISOString(),
      dataEmissaoFim: filtrosAtuais.dataEmissaoFim.toISOString(),
      possuiDevolucao: filtrosAtuais.possuiDevolucao,
      origem: filtrosAtuais.origem
        ? filtrosAtuais.origem
        : filtrosAtuais.origem === 0
        ? 0
        : '',
    };

    if (filtrosAtuais.numeroOperacao) {
      return {
        ...filtros,
        numeroOperacao: filtrosAtuais.numeroOperacao,
      };
    }

    return filtros;
  }, [filtrosAtuais]);

  const handlePrintRelatorio = useCallback(async () => {
    const filters = formatFiltersForSharing();
    const financeAccountsFilterQuery = createContasFinanceirasFilterQuery();
    const response = await api.get<void, ResponseApi<any | undefined>>(
      `${
        ConstanteEnderecoWebservice.PEDIDO_ORCAMENTO_VENDA
      }?${formatQueryObject(
        filters
      )}${selectedOperationQuery}${financeAccountsFilterQuery}`
    );
    if (response) {
      if (response?.avisos) {
        response.avisos.forEach((item: string) => toast.warning(item));
      }
      if (response?.sucesso && response?.dados) {
        ImprimirPDF(response.dados, 'relatorioOperacoes');
      }
    }
    setIsLoading(false);
  }, [
    formatFiltersForSharing,
    createContasFinanceirasFilterQuery,
    selectedOperationQuery,
  ]);

  const sendEmail = useCallback(
    async (emails: string[]) => {
      const filters = formatFiltersForSharing();
      const financeAccountsFilterQuery = createContasFinanceirasFilterQuery();

      const response = await api.post<void, ResponseApi<boolean>>(
        `${
          ConstanteEnderecoWebservice.ENVIAR_EMAIL_RELATORIO_LISTAGEM_OPERACOES
        }?${new URLSearchParams(
          Object(filters)
        ).toString()}${selectedOperationQuery}${financeAccountsFilterQuery}`,
        emails
      );
      if (response) {
        if (response?.sucesso) {
          toast.success('O e-mail foi enviado com sucesso.');
        }
        if (response?.avisos) {
          response.avisos.forEach((aviso) => toast.warning(aviso));
        }
      }
    },
    [
      formatFiltersForSharing,
      selectedOperationQuery,
      createContasFinanceirasFilterQuery,
    ]
  );

  const handleSendEmail = useCallback(async () => {
    await EmailModal({
      getEmail: async (emails: string[]) => {
        sendEmail(emails);
      },
    });
  }, [sendEmail]);

  const handlePrintOperation = useCallback(() => {
    ModalCompartilhar({
      items: [
        {
          titulo: 'Imprimir relatório operações',
          onClickEmail: (onClose) => {
            handleSendEmail();
            onClose();
          },
          onClickImpressao: (onClose) => {
            handlePrintRelatorio();
            onClose();
          },
        },
      ],
    });
  }, [handleSendEmail, handlePrintRelatorio]);

  const getCaixaAberto = useCallback(async () => {
    const response = await api.get<void, ResponseApi<CaixaAbertoProps>>(
      ConstanteEnderecoWebservice.CAIXA_ABERTO_CONTA_FINANCEIRA
    );

    if (response) {
      if (response.avisos) {
        response.avisos.forEach((avisos) => toast.warning(avisos));
      }
      if (response.sucesso) {
        caixaAbertoInformacoes.current = response.dados;

        if (carregarDataContextRef.current) {
          setValue(
            'contasFinanceirasId',
            contextFiltros?.contasFinanceirasId || []
          );
        } else {
          setValue(
            'contasFinanceirasId',
            response.dados?.id ? [response.dados?.id] : []
          );
        }
        possuiCaixaAberto.current = true;

        setBuscaPeloCaixaJaFeita(true);
      }
    }
  }, [carregarDataContextRef, contextFiltros, setValue]);

  const getPodeEmitirNFCe = useCallback(async () => {
    const data = await obterInformacoesFinalizarVenda();

    setPodeEmitirNFCe(!!data?.emitirNFCe);
  }, []);

  const handleIsVenda = useCallback((value: boolean) => {
    setIsVenda(value);
  }, []);

  const handleOperacaoId = useCallback((value: string) => {
    setOperacaoId(value);
  }, []);

  const handleHasNotaFiscal = useCallback((value: boolean) => {
    setHasNotaFiscal(value);
  }, []);

  const handleModalConfirmacaoCancelarIsOpen = useCallback((value: boolean) => {
    setModalConfirmacaoCancelarIsOpen(value);
  }, []);

  useEffect(() => {
    let valorTipoOperacao = '';

    if (!carregarDataContextRef.current) {
      operacoesSelecionadasWatch?.forEach((tipoOperacao: number) => {
        valorTipoOperacao += `&identificacaoTipoOperacao=${tipoOperacao}`;
        setStringComOperacoesEscolhidas(valorTipoOperacao);
      });
      const filtros = getValues();
      setContextFiltros(filtros);
    }
  }, [
    operacoesSelecionadasWatch,
    carregarDataContextRef,
    setContextFiltros,
    getValues,
  ]);

  useEffect(() => {
    let valorTipoOperacao = '';
    if (carregarDataContextRef.current) {
      contextFiltros.identificacaoTipoOperacao?.forEach(
        (tipoOperacao: number) => {
          valorTipoOperacao += `&identificacaoTipoOperacao=${tipoOperacao}`;
          setStringComOperacoesEscolhidas(valorTipoOperacao);
        }
      );
    }
  }, [carregarDataContextRef, contextFiltros.identificacaoTipoOperacao]);

  useEffect(() => {
    if (!operacoesSelecionadasWatch?.length) {
      setStringComOperacoesEscolhidas('');
      setIsLoading(true);
      setHasFilters(false);
      setTotalOperacoes(0);
      setValorTotalEfetuadas(0);
      setValorTotalCanceladas(0);
      setOperacoes(undefined);

      setIsLoading(false);
    } else {
      const filtros = getValues();

      const filtersIsDirty =
        JSON.stringify({
          ...filtros,
          dataEmissaoInicio: filtros.dataEmissaoInicio,
          dataEmissaoFim: filtros.dataEmissaoFim,
          identificacaoTipoOperacao: operacoesSelecionadasWatch.sort(),
        }) !==
        JSON.stringify({
          ...filtrosIniciais,
          dataEmissaoInicio: filtrosIniciais?.dataEmissaoInicio,
          dataEmissaoFim: filtrosIniciais?.dataEmissaoFim,
          identificacaoTipoOperacao: filtrosIniciais?.identificacaoTipoOperacao.sort(),
        });

      if (filtersIsDirty) {
        setHasFilters(true);
      } else {
        setHasFilters(false);
      }
    }
  }, [filtrosIniciais, operacoesSelecionadasWatch, getValues]);

  useEffect(() => {
    if (possuiCaixaAberto.current === false) {
      getCaixaAberto();
    }
  }, [setValue, carregarDataContextRef, contextFiltros, getCaixaAberto]);

  useEffect(() => {
    async function obterPermissaoEmitirNFe() {
      const data = await obterConfigEmitirNFe();
      setPodeEmitirNFe(!!data);
    }
    obterPermissaoEmitirNFe();
  }, []);

  useEffect(() => {
    if (
      carregarDataContextRef.current &&
      Object.keys(contextFiltros).length > 0
    ) {
      setHasFilters(true);
      setFiltrosAtuais(contextFiltros);
    }
  }, [carregarDataContextRef, contextFiltros]);

  useEffect(() => {
    getPodeEmitirNFCe();
  }, [getPodeEmitirNFCe]);

  useEffect(() => {
    setFiltrosAtuais((prev) => ({
      ...prev,
      numeroOperacao: debouncedValue as number,
    }));
  }, [debouncedValue]);

  return (
    <>
      <Box>
        <ManterFoco blockTab={isLoading}>
          <FormProvider {...formMethods}>
            <Stack w="full" direction={isSmallerThan860 ? 'column' : 'row'}>
              <Box w={isSmallerThan860 ? 'full' : '495px'}>
                <SelectMulti
                  name="identificacaoTipoOperacao"
                  id="identificacaoTipoOperacao"
                  placeholder="Escolha quais operações deseja visualizar"
                  variant="outline"
                  options={optionsTipoOperacao}
                  isMulti
                  isSearchable={false}
                  closeMenuOnSelect={false}
                  isDisabled={isLoading}
                />
              </Box>

              <BuscaAvancadaButton
                borderRadius="full"
                colorScheme={hasFilters ? 'blue' : 'gray'}
                variant={hasFilters ? 'solid' : 'outlineDefault'}
                setIsModalBuscaAvancadaOpen={setIsModalBuscaAvancadaOpen}
                hasFilters={hasFilters}
                bg="transparent"
                padding="10px 24px"
                height="36px"
                justifyContent="center"
                borderColor="gray.200"
                minW={['165px', '165px', '165px', '188px']}
                width={['full', '50%', '165px', '188px']}
              >
                {hasFilters ? 'Filtro selecionado' : 'Busca avançada'}
              </BuscaAvancadaButton>
              <Button
                borderRadius="full"
                bg="transparent"
                padding="10px 24px"
                height="36px"
                justifyContent="center"
                colorScheme="gray"
                variant="outlineDefault"
                borderColor="gray.200"
                minW={['165px', '165px', '165px', '188px']}
                width={['full', '50%', '165px', '188px']}
                onClick={handlePrintOperation}
                disabled={!hasItensInTable}
                _hover={{
                  color: 'white',
                  bg: 'gray.500',
                  _disabled: {
                    color: 'gray.400',
                    bg: 'transparent',
                  },
                }}
              >
                <Flex w="210px" justifyContent="center" alignItems="center">
                  <Icon mr="8px" as={RiShareLine} fontSize="md" />
                  <Text>Compartilhar</Text>
                </Flex>
              </Button>
            </Stack>

            <Flex
              width="100%"
              mt={{ base: 3, sm: 6, md: 8 }}
              minH={{ base: '200px', md: '16', lg: '16' }}
              p="4"
              gap="24px"
              flexWrap="wrap"
              justifyContent={['center', 'center', 'center', 'flex-start']}
              bg="primary.700"
              borderRadius="md"
            >
              <Flex
                w={['100%', '100%', '100%', 'auto']}
                gap={['24px', '24px', '12px', '24px']}
                align="center"
                justifyContent="center"
                flexDir={['column', 'column', 'row', 'row']}
              >
                <Box minW={{ base: 'full', md: '50%', lg: '72' }}>
                  <FormLabel mb="0" fontSize="sm" color="white">
                    Período
                  </FormLabel>
                  <InputDateRange
                    borderColor="gray.100"
                    borderRadius="md"
                    name="dataEmissao"
                    onConfirm={() => filtersSubmit()}
                    startDateName="dataEmissaoInicio"
                    endDateName="dataEmissaoFim"
                    maxDate={new Date()}
                  />
                </Box>
                <Flex
                  mt="3px"
                  _focusWithin={{ borderColor: '#96cd00' }}
                  minW={{ base: 'full', md: '50%', lg: '64' }}
                >
                  <Input
                    placeholder="Informe o número da operação"
                    bg="white"
                    name="numeroOperacao"
                    transition="background-color 0s 6000000s, color 0s 6000000s !important"
                    label="Número da operação"
                    fontSizeLabel="14px"
                    labelColor="white"
                    helperText="Digite o número da operação ou utilize o leitor de código de barras."
                    helperTextIconBg="#96cd00"
                    helperTextIconColor="black"
                    onInput={handleInputNumberOperation}
                    color="black"
                    px="0"
                    pl={['4px', '4px', '8px', '16px']}
                    borderColor="white"
                    _focus={{ border: 'none' }}
                    borderRightRadius={isSmallerThan860 ? '0' : 'md'}
                  />
                  {isSmallerThan860 && (
                    <Box
                      bg="white"
                      h="36px"
                      borderRadius="md"
                      borderLeftRadius="0"
                      borderLeft="0px"
                      mt="18px"
                    >
                      <IconButton
                        aria-label="Leitor de códigos de barras"
                        icon={<Icon as={LeitorCodigoBarrasIcon} boxSize="6" />}
                        variant="link"
                        borderRadius="sm"
                        borderLeft="0px"
                        _focus={{ border: 'none' }}
                        mt="6px"
                        onClick={onModalCodigoBarrasOpen}
                      />
                      <ModalCodigoBarras
                        isOpen={isModalCodigoBarrasOpen}
                        onClose={onModalCodigoBarrasClose}
                        onCodigoBarrasScanned={async (codigoBarras: string) => {
                          setSearchNumberOperation(codigoBarras);

                          onModalCodigoBarrasClose();
                        }}
                      />
                    </Box>
                  )}
                </Flex>
              </Flex>

              <Flex
                direction={{ base: 'column', md: 'row', lg: 'row' }}
                mt="16px"
                justify="center"
                w={['100%', '100%', '100%', 'auto']}
                gap={['24px', '24px', '12px', '24px']}
                align="center"
              >
                <Flex
                  maxW="400px"
                  alignItems="center"
                  gap="4px"
                  justifyContent="center"
                  direction={{
                    base: 'column',
                    md: 'column',
                    lg: 'column',
                    xl: 'row',
                  }}
                >
                  <Text
                    color="white"
                    fontSize="sm"
                    mr={{ base: '0', md: '1', lg: '1' }}
                    mb={{ base: '1', md: '0', lg: '0' }}
                    fontWeight="medium"
                  >
                    Quantidade de operações:
                  </Text>
                  <Text
                    fontWeight="semibold"
                    color="white"
                    fontSize="xl"
                    lineHeight="none"
                  >
                    {totalOperacoes}
                  </Text>
                </Flex>

                <Divider
                  color="gray.700"
                  orientation={!isResponsiveVersion ? 'vertical' : 'horizontal'}
                  h={{ base: '1px', md: '12', lg: '12' }}
                  w={{ base: 'full', md: '1', lg: '1' }}
                />

                <Flex
                  maxW="400px"
                  alignItems="center"
                  justifyContent="center"
                  gap="4px"
                  direction={{
                    base: 'column',
                    md: 'column',
                    lg: 'column',
                    xl: 'row',
                  }}
                >
                  <Text
                    color="white"
                    fontSize="sm"
                    fontWeight="medium"
                    mr={{ base: '0', md: '1', lg: '1' }}
                    mb={{ base: '1', md: '0', lg: '0' }}
                  >
                    Valor total:
                  </Text>
                  <TextValor
                    casasDecimais={2}
                    valor={valorTotalEfetuadas}
                    color="secondary.300"
                    fontSize="xl"
                    fontWeight="semibold"
                    symbolFontSize="xs"
                    symbolFontWeight="semibold"
                  />
                </Flex>

                <Divider
                  color="gray.700"
                  orientation={!isResponsiveVersion ? 'vertical' : 'horizontal'}
                  h={{ base: '1px', md: '12', lg: '12' }}
                  w={{ base: 'full', md: '1', lg: '1' }}
                />

                <Flex
                  maxW="400px"
                  gap="4px"
                  alignItems="center"
                  justifyContent="center"
                  direction={{
                    base: 'column',
                    md: 'column',
                    lg: 'column',
                    xl: 'row',
                  }}
                >
                  <Text
                    color="white"
                    fontSize="sm"
                    fontWeight="medium"
                    mr={{ base: '0', md: '1', lg: '1' }}
                    mb={{ base: '1', md: '0', lg: '0' }}
                  >
                    Total canceladas:
                  </Text>
                  <TextValor
                    casasDecimais={2}
                    valor={
                      valorTotalCanceladas < 0
                        ? valorTotalCanceladas * -1
                        : valorTotalCanceladas
                    }
                    color="red.300"
                    fontSize="xl"
                    fontWeight="semibold"
                    symbolFontSize="xs"
                    symbolFontWeight="semibold"
                  />
                </Flex>
              </Flex>
            </Flex>
          </FormProvider>
          <PagedTable
            ref={pagedTableRef}
            variant="simple-card"
            bg="transparent"
            boxShadow="none"
            size="sm"
            mt="3"
            paginationHasDivider={false}
            sx={{
              '& tr > th': { borderBottom: 'none' },
              '& td:only-child': { bg: 'white', h: '60px', border: 'none' },
              '& tr': { boxShadow: 'none' },
            }}
            itemsTotalCount={totalOperacoes}
            loadColumnsData={paginationHandle}
            isLoading={isLoading}
            tableHeaders={[
              {
                content: 'Data/Hora',
                key: 'dataEmissao',
                isOrderable: true,
                w: '180px',
                textAlign: 'left',
              },
              {
                content: 'Operação',
                key: 'operacao',
                isOrderable: false,
                w: '100px',
              },
              {
                content: 'Número',
                key: 'numeroOperacao',
                isOrderable: true,
                w: '100px',
              },
              {
                content: 'Nome do cliente',
                key: 'cliente',
                isOrderable: false,
                w: 'auto',
              },
              {
                content: 'Situação',
                key: 'situacao',
                isOrderable: false,
                w: '200px',
              },
              {
                content: 'Valor total',
                key: 'valor',
                isOrderable: false,
                w: '1px',
                textAlign: 'right',
              },
              {
                content: 'Ações',
                key: 'Acoes',
                isOrderable: false,
                w: '38px',
              },
            ]}
            defaultKeyOrdered="numeroOperacao"
            defaultOrderDirection="desc"
            renderTableRows={
              operacoes &&
              operacoes.map((operacao: OperacaoProps, index) => {
                return (
                  <OperacoesItens
                    key={operacao.id}
                    index={index}
                    operacao={operacao}
                    podeEmitirNFe={podeEmitirNFe}
                    podeEmitirNFCe={podeEmitirNFCe}
                    handleResetTableOrder={handleResetTableOrder}
                    setIsLoading={setIsLoading}
                    handleIsVenda={handleIsVenda}
                    handleOperacaoId={handleOperacaoId}
                    handleHasNotaFiscal={handleHasNotaFiscal}
                    handleModalConfirmacaoCancelarIsOpen={
                      handleModalConfirmacaoCancelarIsOpen
                    }
                  />
                );
              })
            }
          />
        </ManterFoco>
      </Box>
      <ModalConfirmacao
        isOpen={modalConfirmacaoCancelarIsOpen}
        setIsOpen={setModalConfirmacaoCancelarIsOpen}
        onConfirm={handleCancelar}
        textoCabecalho="Deseja cancelar esta operação?"
        textoMensagem="Se continuar cancelará toda a operação, e esta ação não poderá ser desfeita. Deseja continuar?"
        textoCancelar="Não, voltar!"
      />

      <ModalAutorizacaoFuncionalidade
        isOpen={modalAutorizacaoCancelarIsOpen}
        setIsOpen={setModalAutorizacaoCancelarIsOpen}
        autorizado={handleConfirmarPermissaoCancelar}
        titulo={ConstanteFuncionalidades.PDV_CANCELAR.titulo}
        descricao={ConstanteFuncionalidades.PDV_CANCELAR.descricao}
        tela={LogAuditoriaTelaEnum.PDV}
        permissoes={[ConstanteFuncionalidades.PDV_CANCELAR.codigo]}
        color="red.500"
      />

      <ModalMotivoCancelamento
        isOpen={modalMotivoCancelamentoIsOpen}
        onClose={() => {
          setModalMotivoCancelamentoIsOpen(false);
        }}
        onSubmit={handleConfirmarMotivoCancelamento}
        hasNotaFiscal={hasNotaFiscal}
      />
      <ModalBuscaAvancada
        isOpen={isModalBuscaAvancadaOpen}
        onClose={() => {
          setIsModalBuscaAvancadaOpen(false);
        }}
        opcoesTipoOperacao={optionsTipoOperacao}
        onClear={handleReset}
        handleSubmitModalBuscaAvancada={handleSubmitModalBuscaAvancada}
        existeCaixaAberto={possuiCaixaAberto.current}
        filtros={watch()}
      />
    </>
  );
};
