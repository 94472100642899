const MeioPagamentoFiscalEnum = {
  Dinheiro: 1,
  Cheque: 2,
  CartaoCredito: 3,
  CartaoDebito: 4,
  CreditoLoja: 5,
  ValeAlimentacao: 11,
  ValePresente: 12,
  ValeCombustivel: 13,
  BoletoBancario: 15,
  DepositoBancario: 16,
  PagamentoInstantaneo: 17,
  TransferenciaBancaria: 18,
  ProgramaFidelidade: 19,
  PagamentoInstantaneoEstatico: 20,
  CreditoEmLoja: 21,
  PagamentoEletronicoNaoInformado: 22,
  SemPagamento: 90,
  Outros: 99,

  properties: {
    1: { name: 'Dinheiro', value: 1 },
    2: { name: 'Cheque', value: 2 },
    3: { name: 'Cartão de crédito', value: 3 },
    4: { name: 'Cartão de débito', value: 4 },
    5: { name: 'Crédito loja', value: 5 },
    11: { name: 'Vale alimentação', value: 11 },
    12: { name: 'Vale presente', value: 12 },
    15: { name: 'Boleto bancário', value: 15 },
    16: { name: 'Depósito bancário', value: 16 },
    17: { name: 'Pagamento instantâneo', value: 17 },
    18: { name: 'Transferência bancária', value: 18 },
    19: { name: 'Programa de fidelidade', value: 19 },
    90: { name: 'Sem pagamento', value: 90 },
    99: { name: 'Outros', value: 99 },
  },
};

export default MeioPagamentoFiscalEnum;
