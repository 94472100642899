const ConstanteEnderecoWebservice = {
  AUTENTICACAO_LOGIN: 'autenticacao/login',
  AUTENTICACAO_LOGOFF: 'autenticacao/logoff',
  AUTENTICACAO_LOGOUT_USER: 'autenticacao/logout-user',
  AUTENTICACAO_RECUPERAR_SENHA: 'autenticacao/recuperar-senha/',
  AUTENTICACAO_ALTERAR_SENHA: 'autenticacao/alterar-senha',
  AUTENTICACAO_REFRESH_TOKEN: 'autenticacao/refresh-token',
  AUTENTICACAO_VALIDAR_PERMISSAO_USUARIO:
    'autenticacao/validar-permissao-usuario',
  AUTENTICACAO_VALIDAR_USUARIO_LOGADO: 'autenticacao/validar-usuario-logado',

  CAIXA_MOVIMENTACAO_ABRIR_CAIXA: 'caixamovimentacao/abrir-caixa',
  CAIXA_MOVIMENTACAO_EXCLUIR: 'caixamovimentacao',
  CAIXA_MOVIMENTACAO_LISTAR_ICONES: 'caixamovimentacao/listar-icones',
  CAIXA_ABERTO_CONTA_FINANCEIRA: 'contafinanceira/obter-caixa-aberto-usuario',
  OBTER_CAIXA_ABERTO_USUARIO_LOGADO:
    'contafinanceira/obter-caixa-aberto-usuario-logado',
  REPROCESSAR_INTEGRACAO: 'integracao/reprocessar-pedido',
  OBTER_CLIENTE_PENDENCIA_INTEGRACAO:
    'integracao/obter-lista-cliente-cpf-pendencia',
  CLIENTES_INFORMACOES: 'V2/relatorios/clientes',

  CATEGORIA_CLIENTE_CADASTRAR: 'categoriacliente/cadastrar',
  CATEGORIA_CLIENTE_LISTAR_PAGINADO: 'categoriacliente/listar-paginado',
  CATEGORIA_CLIENTE_EXCLUIR: 'categoriacliente/excluir',
  CATEGORIA_CLIENTE_ALTERAR: 'categoriacliente/alterar',
  CATEGORIA_CLIENTE_OBTER: 'categoriacliente/obter',
  CATEGORIA_CLIENTE_INATIVAR: 'categoriacliente/inativar',
  CATEGORIA_CLIENTE_SELECT: 'categoriacliente/listar-select',

  RELATORIO_PERSONALIZADO_CLIENTES_EXCLUIR: 'relatoriocliente/excluir',
  RELATORIO_PERSONALIZADO_CLIENTES_ALTERAR: 'relatoriocliente/alterar',
  RELATORIO_PERSONALIZADO_CLIENTES_OBTER: 'relatoriocliente/obter',
  RELATORIO_PERSONALIZADO_CLIENTES_CADASTRAR: 'relatoriocliente/cadastrar',
  RELATORIO_PERSONALIZADO_CLIENTES_LISTAR:
    'relatoriocliente/listar-relatoriospersonalizados',
  RELATORIO_PERSONALIZADO_CLIENTES_LISTAR_PAGINADO:
    'relatoriocliente/listar-paginado',
  RELATORIO_CONSIGNACAO: 'relatorioOperacao/relatorio-consignacao',
  RELATORIO_VENDAS_POR_DIA: 'relatorioVendas/relatorio-vendas-por-dia',
  RELATORIO_VENDAS_POR_FORMA_RECEBIMENTO:
    'relatorioVendas/relatorio-vendas-por-forma-recebimento',
  RELATORIO_VENDAS_TOTALIZADAS_VENDEDORES:
    'relatorioVendas/relatorio-vendas-totalizadas-vendedores',
  RELATORIO_VENDAS_SIMPLIFICADAS:
    'relatorioVendas/relatorio-vendas-simplificadas',

  RELATORIO_ESTOQUE_SEM_PRECO: 'relatorioEstoque/relatorio-estoque-sem-preco',
  RELATORIO_ESTOQUE_PRECO_VENDA:
    'relatorioEstoque/relatorio-estoque-preco-venda',
  RELATORIO_ESTOQUE_PRECO_CUSTO:
    'relatorioEstoque/relatorio-estoque-preco-custo',

  RELATORIO_CSV_ESTOQUE_SEM_PRECO:
    'relatorioEstoque/relatorio-csv-estoque-sem-preco',
  RELATORIO_CSV_ESTOQUE_PRECO_VENDA:
    'relatorioEstoque/relatorio-csv-estoque-preco-venda',
  RELATORIO_CSV_ESTOQUE_PRECO_CUSTO:
    'relatorioEstoque/relatorio-csv-estoque-preco-custo',

  RELATORIO_CATALOGO_PRODUTOS: 'catalogoProdutos/gerar-relatorio-catalogo',

  CLIENTE_RELATORIO_CADASTRO_COMPLETO:
    'relatoriocliente/gerar-relatorio-cadastro-completo',
  CLIENTE_RELATORIO_PERSONALIZADO:
    'relatoriocliente/gerar-relatorio-personalizado',

  CLIENTE_MULTIEMPRESA_VALIDAR_DOMINIO: 'clientemultiempresa/validar-dominio',
  CLIENTE_MULTIEMPRESA_RECUPERAR_DOMINIO:
    'clientemultiempresa/recuperar-dominio/',
  CLIENTE_MULTIEMPRESA_VALIDAR_VERSAO_BANCO_DADOS:
    'clientemultiempresa/validar-versao-banco-dados',

  OBTER_ABERTURA_CAIXA: 'caixaMovimentacao/obter-informacoes-abertura-caixa',

  PEDIDO_ORCAMENTO_VENDA: 'pedidoorcamentovenda/relatorio-listagem',
  ENVIAR_EMAIL_RELATORIO_LISTAGEM_OPERACOES:
    'pedidoorcamentovenda/enviar-email-relatorio-listagem',
  ULTIMO_PEDIDO_CADASTRADO: 'PedidoOrcamentoVenda/obter-ultimo-pedido-efetuado',

  IMPORTAR_CADASTRAR: 'importacao/cadastrar',
  IMPORTAR_ALTERAR: 'importacao/alterar',
  IMPORTAR_DOWNLOAD: 'importacao/download',
  IMPORTAR_LISTAR_PAGINADO: 'importacao/listar-paginado',
  IMPORTAR_LISTAR_COLUNAS: 'importacao/listar-colunas',
  IMPORTAR_LISTAR_COLUNAS_PARA_IMPORTACAO:
    'importacao/listar-colunas-para-importacao',
  IMPORTAR_DOWNLOAD_CSV_EXEMPLO: 'importacao/download-csv-exemplo',

  ETIQUETA_PERSONALIZADA_LISTAR_SELECT: 'etiquetaPersonalizada/listar-select',
  ETIQUETAS_PERSONALIZADAS_LISTAR_PAGINADO:
    'etiquetapersonalizada/listar-paginado',
  ETIQUETAS_PERSONALIZADAS_CADASTRAR: 'etiquetapersonalizada/cadastrar',
  ETIQUETAS_PERSONALIZADAS_EXCLUIR: 'etiquetaPersonalizada/excluir',
  ETIQUETA_PERSONALIZADA_OBTER: 'etiquetaPersonalizada/obter',
  ETIQUETA_PERSONALIZADA_ALTERAR: 'etiquetaPersonalizada/alterar',
  ETIQUETA_PERSONALIZADA_INATIVAR: 'etiquetaPersonalizada/inativar',
  ETIQUETA_PERSONALIZADA_IMPRIMIR: 'etiquetaPersonalizada/imprimir-etiquetas',
  ETIQUETAS_PERSONALIZADAS_PESQUISAR_POR_NOME:
    'EtiquetaPersonalizada/pesquisa-produto-etiqueta',
  ETIQUETA_PERSONALIZADA_PESQUISAR_POR_DATA: 'etiquetaPersonalizada/pesquisar',
  ETIQUETA_DESPACHO: 'etiquetaDespacho/a4',

  CONTA_FINANCEIRA_LISTAR_SELECT_CONTA_BANCOS:
    'contafinanceira/listar-select-conta-bancos',
  CONTA_FINANCEIRA_LISTAR_SELECT: 'contafinanceira/listar-select',
  CONTA_FINANCEIRA_CONSULTAR_CONTAS_CAIXA_COFRE_POR_LOJA:
    'contaFinanceira/consultar-contas-caixa-cofre-por-loja',
  CONTA_FINANCEIRA_ALTERAR_STONE: 'contaFinanceira/alterar-stone',
  CONTA_FINANCEIRA_LISTAR_POR_LOJA: 'contaFinanceira/listar-por-loja',
  LISTAR_LOJA_COM_CONTAS_FINANCEIRAS:
    'loja/listar-lojas-com-contas-financeiras',
  CONTA_FINANCEIRA_LISTAR_CONTAS_POR_LOJA:
    'contaFinanceira/listar-contas-por-loja',
  CONTA_FINANCEIRA_LISTAR_ICONES: 'contafinanceira/listar-icones',

  COR_LISTAR_SELECT: 'cor/listar-select',
  COR_CADASTRAR: 'cor/cadastrar',
  COR_EXCLUIR: 'cor/excluir',
  COR_LISTAR_PAGINADO: 'cor/listar-paginado',
  COR_OBTER: 'cor/obter',
  COR_ALTERAR: 'cor/alterar',
  COR_INATIVAR: 'cor/inativar',
  COR_CADASTRAR_COR_MODAL: 'cor/cadastrar-cor-modal',

  TAMANHO_LISTAR_SELECT: 'tamanho/listar-select',
  TAMANHO_LISTAR: 'tamanho/listar',
  TAMANHO_CADASTRAR: 'tamanho/cadastrar',
  TAMANHO_EXCLUIR: 'tamanho/excluir',
  TAMANHO_ALTERAR: 'tamanho/alterar',
  TAMANHO_OBTER: 'tamanho/obter',
  TAMANHO_INATIVAR: 'tamanho/inativar',
  TAMANHO_ALTERAR_SEQUENCIA_ORDENACAO: 'tamanho/alterar-sequencia-ordenacao',
  TAMANHO_CADASTRAR_TAMANHO_MODAL: 'tamanho/cadastrar-tamanho-modal',

  COR_SELECT_LISTAR_PAGINADO: 'V2/cor/select-paginado',

  TAMANHO_SELECT_LISTAR_PAGINADO: 'V2/tamanho/select-paginado',

  HISTORICO_EVENTO: 'historicoEvento/',

  CATEGORIA_PRODUTO_CADASTRAR: 'categoriaproduto/cadastrar',
  CATEGORIA_PRODUTO_EXCLUIR: 'categoriaproduto/excluir',
  CATEGORIA_PRODUTO_ALTERAR: 'categoriaproduto/alterar',
  CATEGORIA_PRODUTO_OBTER: 'categoriaproduto/obter',
  CATEGORIA_PRODUTO_INATIVAR: 'categoriaproduto/inativar',
  CATEGORIA_PRODUTO_LISTAR: 'categoriaproduto/listar',
  CATEGORIA_PRODUTO_LISTAR_SELECT: 'categoriaproduto/listar-select',
  CATEGORIA_PRODUTO_INTEGRACAO_SELECT:
    'integracaoCaixaMovelCategoriaProduto/listar-select',
  LISTAR_PRODUTO_CORES: 'listar-produto-cores',
  COMPLEMENTO_OBTER: 'V2/CategoriaProduto/obter/complemento',
  COMPLEMENTO_EXCLUIR: 'V2/CategoriaProduto/excluir/complemento',
  COMPLEMENTO_CADASTRAR: 'V2/CategoriaProduto/cadastrar/complemento',

  OBSERVACAO_LISTAR_SELECT: 'V2/produto-observacao/listar-select',
  OBSERVACAO_CADASTRAR: 'V2/produto-observacao',
  OBSERVACAO_OBTER: 'V2/CategoriaProduto/obter',
  OBSERVACAO_VINCULAR: 'v2/categoriaproduto/cadastrar/observacao',
  OBSERVACAO_EXCLUIR: 'V2/CategoriaProduto/excluir/observacao',

  IMPRESSORA_LISTAR_SELECT: 'impressora/select',
  IMPRESSORA: 'v2/impressora',

  TABELA_PRECO_OBTER_PRODUTO_COR_TAMANHO:
    'produtoCorTamanho/obter-Produto-Cor-Tamanho-Descricao',

  LISTAR_SELECT_FICHA_TECNICA: 'V2/FichaTecnica/produtos',

  MARCA_LISTAR_SELECT: 'marca/listar-select',
  MARCA_LISTAR_PAGINADO: 'marca/listar-paginado',
  MARCA_CADASTRAR: 'marca/cadastrar',
  MARCA_EXCLUIR: 'marca/excluir',
  MARCA_ALTERAR: 'marca/alterar',
  MARCA_OBTER: 'marca/obter',
  MARCA_INATIVAR: 'marca/inativar',

  TAG_LISTAR_SELECT: 'tag/listar-select',
  TAG_CADASTRAR: 'tag/cadastrar',

  CAMPO_PERSONALIZADO_LISTAR: 'campopersonalizado/listar',
  CAMPO_PERSONALIZADO_LISTARPORTIPOCADASTRO:
    'campopersonalizado/listar-por-tipocadastro',

  CAMPO_PERSONALIZADO_LISTAR_SELECT: 'campopersonalizado/listar-select',
  CAMPO_PERSONALIZADO_LISTAR_PAGINADO: 'campopersonalizado/listar-paginado',
  CAMPO_PERSONALIZADO_CADASTRAR: 'campopersonalizado/cadastrar',
  CAMPO_PERSONALIZADO_EXCLUIR: 'campopersonalizado/excluir',
  CAMPO_PERSONALIZADO_ALTERAR: 'campopersonalizado/alterar',
  CAMPO_PERSONALIZADO_OBTER: 'campopersonalizado/obter',
  CAMPO_PERSONALIZADO_INATIVAR: 'campopersonalizado/inativar',

  LISTAR_PRODUTOS_CONSIGNADOS: 'pedidoOrcamentoVenda/listar-itens-consignados',
  DEVOLVER_PRODUTOS_CONSIGNADOS: 'consignacao/devolver-produtos-consignados',
  VENDER_PRODUTOS_CONSIGNADOS: 'consignacao/gerar-venda-consignacao',

  PRODUTO_CADASTRAR: 'produto/cadastrar',
  PRODUTO_OBTER: 'produto/obter',
  PRODUTO_OBTER_VARIACOES: 'produto/obter-variacoes-produto',
  PRODUTO_ALTERAR: 'produto/alterar',
  PRODUTO_EXCLUIR_VARIACOES_COR: 'produto/excluir-variacoes-cor',
  PRODUTO_EXCLUIR_VARIACOES_TAMANHO: 'produto/excluir-variacoes-tamanho',
  PRODUTO_LISTAR_PAGINADO: 'produto/listar-paginado',
  PRODUTO_EXCLUIR: 'produto/excluir',
  PRODUTO_INATIVAR: 'produto/inativar',
  PRODUTO_OBTER_SALDO_TOTAL: 'produto/obter-saldo-total-produto',
  PRODUTO_OBTER_SALDO_VARIACOES: 'produto/obter-saldo-variacoes',
  PRODUTO_LISTAR_CORES: 'produtoCor/listar-por-produto',
  PRODUTO_COR_LISTAR_PAGINADO: 'produtoCor/listar-paginado',

  INTEGRACAO_CATEGORIA_PRODUTO_ENVIAR:
    'integracaoCaixaMovelCategoriaProduto/enviar-categorias',
  OBTER_IMAGEM_SMART_POS: 'integracaoCaixaMovel/obter-imagem-impressao',
  INTEGRACAO_SMART_POS_DELETAR: 'integracaoCaixaMovel',
  OBTER_INTEGRACAO_SMART_POS: 'integracaoCaixaMovel/obter',
  INTEGRACAO_ATIVAR: 'Integracao/ativar',

  PRODUTO_COR_LISTAR_SELECT_ITENS_KIT: 'produtocor/listar-select-itens-kit',

  PRODUTO_LISTAR_SELECT: 'produto/listar-select',
  TABELA_PRECO_LISTAR_POR_LOJA: 'v2/tabelapreco/loja',
  TABELA_PRECO_OBTER_PRODUTOS_COR_TAMANHO_PARA_EXPORTAR:
    'tabelapreco/obter-produtos-para-exportar',
  TABELA_PRECO_LISTAR_PAGINADO_LISTAGEM_PRODUTOS_COR_TAMANHO:
    'tabelaPreco/listar-paginado-tabela-preco-produto-cor-tamanho',
  TABELA_PRECO_IMPORTAR_PRODUTOS_LISTAGEM_PRODUTOS_COR_TAMANHO:
    'tabelaPreco/importar',
  TABELA_PRECO_CADASTRAR_PRODUTO_LISTAGEM_PRODUTOS_COR_TAMANHO:
    'tabelaPreco/cadastrar-produto',
  TABELA_PRECO_ALTERAR_PRODUTO_LISTAGEM_PRODUTOS_COR_TAMANHO:
    'tabelaPreco/alterar-tabela-preco-produto-cor-tamanho',
  TABELA_PRECO_EXCLUIR_PRODUTO_LISTAGEM_PRODUTOS_COR_TAMANHO:
    'tabelaPreco/excluir-tabela-preco-produto-cor-tamanho',

  TABELA_PRECO_OBTER_PRODUTOS_SIMPLES_PARA_EXPORTAR:
    'v2/tabelaPrecoProduto/obter-produtos-simples-para-exportar',
  TABELA_PRECO_LISTAR_PAGINADO_LISTAGEM_PRODUTOS_SIMPLES:
    'v2/tabelaPrecoProduto/listar-paginado-tabela-preco-produto-simples',
  TABELA_PRECO_IMPORTAR_PRODUTOS_LISTAGEM_PRODUTOS_SIMPLES:
    'v2/tabelaPrecoProduto/importar-tabela-produto-simples',
  TABELA_PRECO_CADASTRAR_PRODUTO_LISTAGEM_PRODUTOS_SIMPLES:
    'v2/tabelaPrecoProduto/cadastrar-produto-simples',
  TABELA_PRECO_ALTERAR_PRODUTO_LISTAGEM_PRECOS_SIMPLES:
    'v2/tabelaPrecoProduto/alterar-tabela-preco-produto-simples',
  TABELA_PRECO_EXCLUIR_PRODUTO_LISTAGEM_PRECOS_SIMPLES:
    'v2/tabelaPrecoProduto/excluir-tabela-preco-produto-simples',

  PRODUTO_COR_LISTAR_SELECT_IMPRESSAO_ETIQUETA:
    'produtocor/listar-select-impressao-etiqueta',
  PRODUTO_COR_LISTAR_SELECT: 'produtoCor/listar-select',

  PRODUTO_COR_TAMANHO_OBTER_TAMANHOS: 'produtocortamanho/obter-tamanhos',
  PRODUTO_COR_TAMANHO_OBTER_INFORMACOES_NOTA_FISCAL:
    'produtocortamanho/obter-informacoes-para-nota-fiscal',
  PRODUTO_COR_TAMANHO_LISTAR_SELECT_NOTA_FISCAL:
    'produtocortamanho/listar-select',
  PRODUTO_COR_TAMANHO_LISTAR_SELECT_TAMANHO:
    'produtocortamanho/listar-select-tamanho-kits',
  PRODUTO_COR_TAMANHO_LISTAR_SELECT_PDV: 'produtocortamanho/listar-select-pdv',
  OBTER_PRODUTO_PDV: 'pesquisaProduto/obter-pdv',
  PRODUTO_OBTER_ADICIONAR_AUTOMATICAMENTE:
    'pesquisaProduto/obter-adicionar-automaticamente',
  PRODUTO_COR_TAMANHO_LISTAR_SELECT_MOVIMENTACAO_TRANSFERENCIA_ESTOQUE:
    'produtocortamanho/listar-select-movimentacao-transferencia-estoque',
  TRANFERENCIA_ESTOQUE_LISTAR_ITENS_CONFERIR:
    'transferenciaEstoque/listar-descricao-itens-conferencia',
  TRANFERENCIA_ESTOQUE_CONFERIR:
    'transferenciaEstoque/listar-id-itens-conferencia',
  PRODUTO_COR_TAMANHO_LISTAR_SELECT_CONFERENCIA_ESTOQUE:
    'produtocortamanho/listar-select-conferencia-estoque',
  PRODUTO_COR_TAMANHO_LISTAR_SELECT_ENTRADA_MERCADORIA:
    'produtocortamanho/listar-select-entrada-mercadoria',
  PRODUTO_COR_TAMANHO_OBTER_OPCAO_SELECT_ENTRADA_MERCADORIA:
    'produtocortamanho/obter-opcao-select-entrada-mercadoria',
  ENTRADA_DE_PRODUTO_LISTAR_MANIFESTO:
    'entradamercadoria/listar-notas-manifesto',
  LISTAR_SELECT_PRODUTO: 'pesquisaProduto/listar-select-produto',

  PRODUTO_COR_TAMANHO_OBTER_POR_EAN: 'v2/produtos/produtoCorTamanhos/ean',
  PRODUTO_COR_TAMANHO_OBTER_POR_CODIGO_EXTERNO:
    'v2/produtos/produtoCorTamanhos/codigo-externo',

  PRODUTO_COR_TAMANHO_ALTERAR_EAN:
    'v2/produtos/produtoCorTamanhos/{variacaoId}/ean/{codigo}',
  PRODUTO_COR_TAMANHO_ALTERAR_CODIGO_EXTERNO:
    'v2/produtos/produtoCorTamanhos/{variacaoId}/codigo-externo/{codigo}',

  VENDEDOR_CADASTRAR: 'vendedor/cadastrar',
  VENDEDOR_OBTER: 'vendedor/obter',
  VENDEDOR_ALTERAR: 'vendedor/alterar',
  VENDEDOR_LISTAR_PAGINADO: 'vendedor/listar-paginado',
  VENDEDOR_EXCLUIR: 'vendedor/excluir',
  VENDEDOR_INATIVAR: 'vendedor/inativar',
  VENDEDOR_LISTAR_SELECT_POR_LOJA: 'vendedor/listar-select-vendedor-por-loja',
  VENDEDOR_LISTAR_SELECT_POR_LOJA_POR_PERIODO:
    'vendedor/listar-select-vendedor-por-loja-por-periodo',
  VENDEDOR_LISTAR_SELECT_PDV: 'vendedor/listar-select-pdv',

  CONTA_FINANCEIRA_SELECT: 'contafinanceira/listar-select',
  CONTA_FINANCEIRA_CADASTRAR: 'contafinanceira/cadastrar',
  CONTA_FINANCEIRA_EXCLUIR: 'contafinanceira/excluir',
  CONTA_FINANCEIRA_LISTAR_PAGINADO: 'contafinanceira/listar-paginado',
  CONTA_FINANCEIRA_OBTER: 'contafinanceira/obter',
  CONTA_FINANCEIRA_ALTERAR: 'contafinanceira/alterar',
  CONTA_FINANCEIRA_INATIVAR: 'contafinanceira/inativar',
  CONTA_FINANCEIRA_OBTER_CAIXA_ABERTO_LOGADO:
    'contafinanceira/obter-caixa-aberto-usuario-logado',
  CONTA_FINANCEIRA_LISTAR_SELECT_CAIXA: 'contafinanceira/listar-select-caixas',
  CONTA_FINANCEIRA_LISTAR_SELECT_CAIXA_TODAS_LOJAS:
    'contafinanceira/listar-select-caixas-lojas',
  CONTA_FINANCEIRA_LISTAR_CONTAS_FINANCEIRAS_MENOS_CAIXAS:
    'contafinanceira/listar-contas-financeiras-menos-caixas',
  CONTA_FINANCEIRA_OBTER_NOME: 'contafinanceira/obter-nome',

  PLANO_CONTA_CADASTRAR: 'planoconta/cadastrar',
  PLANO_CONTA_EXCLUIR: 'planoconta/excluir',
  PLANO_CONTA_ALTERAR: 'planoconta/alterar',
  PLANO_CONTA_INATIVAR: 'planoconta/inativar',
  PLANO_CONTA_LISTAR: 'planoconta/listar',
  PLANO_CONTA_LISTAR_SELECT: 'planoconta/listar-select',

  FORMA_PAGTO_LISTAR_SELECT: 'formapagamento/listar-select',
  FORMA_PAGTO_LISTAR_PAGINADO: 'formapagamento/listar-paginado',
  FORMA_PAGTO_CADASTRAR: 'formapagamento/cadastrar',
  FORMA_PAGTO_EXCLUIR: 'formapagamento/excluir',
  FORMA_PAGTO_ALTERAR: 'formapagamento/alterar',
  FORMA_PAGTO_OBTER: 'formapagamento/obter',
  FORMA_PAGTO_INATIVAR: 'formapagamento/inativar',
  FORMA_PAGTO_ATIVAR: 'formapagamento/ativar',

  LOJA_LISTAR_LOJAVENDEDOR: 'loja/listar-loja-vendedor',
  LISTAR_LOJA: 'loja/listar',
  OBTER_LOJAS_TABELA_PRECO: 'loja/listar-loja-tabela-preco',
  LOJA_LISTAR_TRANSFERENCIA_ESTOQUE: 'loja/listar-loja-transferencia-estoque',
  LOJA_OBTER_REGRAFISCAL_LOJA: 'loja/obter-regra-fiscal-loja',
  LOJA_LISTAR_LOJAUSUARIO: 'loja/listar-loja-usuario',
  LOJA_LISTAR_COM_ENDERECO_E_LOCAL_ESTOQUE:
    'loja/listar-loja-com-endereco-e-local-estoque',
  LOJA_OBTER_NUMEROS_SERIE: 'loja/obter-numeros-serie',
  LOJA_OBTER_INFORMACOES_FISCAIS: 'loja/obter-informacoes-fiscais',
  LOJA_LISTAR_PAGINADO: 'loja/listar-paginado',
  LOJA_CADASTRAR: 'loja/cadastrar',
  LOJA_EXCLUIR: 'loja/excluir',
  LOJA_ALTERAR: 'loja/alterar',
  LOJA_OBTER: 'loja/obter',
  LOJA_INATIVAR: 'loja/inativar',
  LOJA_OBTER_CIDADE: 'loja/obter-cidade',
  LOJA_OBTER_EMAIL_CONTABILISTA: 'loja/obter-email-contabilista',
  LOJA_OBTER_INFORMACOES_FINALIZAR_VENDA:
    'loja/obter-informacoes-finalizar-venda',
  LOJA_OBTER_CONFIG_EMITIR_NFE: 'loja/obter-config-emitir-nfe',
  LOJA_OBTER_TIPO_AMBIENTE_FISCAL: 'loja/obter-tipo-ambiente-fiscal',
  LOJA_GERAR_PIN_A3: 'loja/gerar-pin-a3',
  LOJA_LISTAR_TODOS_ATIVOS: 'loja/listar-todos-ativos',
  LOJA_OBTER_VENCIMENTO_CERTIFICADO: 'loja/obter-vencimento-certificado',

  TRANSPORTADORA_CADASTRAR: 'transportadora/cadastrar',
  TRANSPORTADORA_OBTER: 'transportadora/obter',
  TRANSPORTADORA_ALTERAR: 'transportadora/alterar',
  TRANSPORTADORA_LISTAR_PAGINADO: 'transportadora/listar-paginado',
  TRANSPORTADORA_EXCLUIR: 'transportadora/excluir',
  TRANSPORTADORA_INATIVAR: 'transportadora/inativar',
  TRANSPORTADORA_LISTAR_SELECT_NOTA_FISCAL:
    'transportadora/listar-select-nota-fiscal',
  TRANSPORTADORA_LISTAR_SELECT_ENTRADA_MERCADORIA:
    'transportadora/listar-select-entrada-mercadoria',
  TRANSPORTADORA_CADASTRAR_TRANSPORTADORA_MODAL:
    'transportadora/cadastrar-transportadora-modal',

  CIDADE_LISTAR_CACHE: 'cidade/listar-cache',
  CIDADE_OBTER_CACHE: 'cidade/obter-cache',

  PAIS_LISTAR_CACHE: 'pais/listar-cache',
  PAIS_OBTER_CACHE: 'pais/obter-cache',

  UNIDADE_MEDIDA_CADASTRAR: 'unidademedida/cadastrar',
  UNIDADE_MEDIDA_OBTER: 'unidademedida/obter',
  UNIDADE_MEDIDA_ALTERAR: 'unidademedida/alterar',
  UNIDADE_MEDIDA_LISTAR_PAGINADO: 'unidademedida/listar-paginado',
  UNIDADE_MEDIDA_LISTAR_SELECT: 'unidademedida/listar-select',
  UNIDADE_MEDIDA_EXCLUIR: 'unidademedida/excluir',
  UNIDADE_MEDIDA_INATIVAR: 'unidademedida/inativar',

  LOG_AUDITORIA_LISTAR_PAGINADO: 'logauditoria/listar-paginado',
  LOG_AUDITORIA_OBTER: 'logauditoria/obter',
  LOG_AUDITORIA_LISTAR_USUARIOS: 'logauditoria/listar-usuarios-log',
  LOG_AUDITORIA_LISTAR_DESCRICAO_TELAS: 'logauditoria/listar-descricao-telas',

  CLIENTE_LISTAR_PAGINADO: 'clientefornecedor/listar-paginado-cliente',
  FORNECEDOR_LISTAR_PAGINADO: 'clientefornecedor/listar-paginado-fornecedor',

  CLIENTE_CADASTRAR: 'clientefornecedor/cadastrar-cliente',
  FORNECEDOR_CADASTRAR: 'clientefornecedor/cadastrar-fornecedor',
  CLIENTE_CADASTRAR_FORA_PDV: 'clienteFornecedor/cadastrar-cliente-modal',
  FORNECEDOR_CADASTRAR_MODAL: 'clienteFornecedor/cadastrar-fornecedor-modal',

  CLIENTE_ALTERAR: 'clientefornecedor/alterar-cliente',
  FORNECEDOR_ALTERAR: 'clientefornecedor/alterar-fornecedor',

  CLIENTE_OBTER: 'clientefornecedor/obter-cliente',
  CLIENTE_FORNECEDOR_VALIDAR_CPF_CNPJ: 'clienteFornecedor/validar-cpf-cnpj',
  FORNECEDOR_OBTER: 'clientefornecedor/obter-fornecedor',

  CLIENTE_FORNECEDOR_OBTER_INFORMACOES_PDV:
    'clientefornecedor/obter-cliente-informacoes-pdv',

  CLIENTE_EXCLUIR: 'clientefornecedor/excluir-cliente',
  CLIENTE_ANONIMIZAR: 'clientefornecedor/anonimizar-cliente',
  CLIENTE_INATIVAR: 'clientefornecedor/inativar-cliente',
  FORNECEDOR_EXCLUIR: 'clientefornecedor/excluir-fornecedor',
  FORNECEDOR_INATIVAR: 'clientefornecedor/inativar-fornecedor',
  CLIENTE_FORNECEDOR_EXCLUIR: 'clientefornecedor/excluir',
  CLIENTE_FORNECEDOR_INATIVAR: 'clientefornecedor/inativar',
  CLIENTE_FORNECEDOR_LISTAR_SELECT: 'clientefornecedor/listar-select',
  CLIENTE_FORNECEDOR_LISTAR_SELECT_NOTA_FISCAL:
    'clientefornecedor/listar-select-nota-fiscal',
  CLIENTE_FORNECEDOR_LISTAR_SELECT_PDV: 'clientefornecedor/listar-select-pdv',
  CLIENTE_FORNECEDOR_OBTER_PADRAO_SISTEMA:
    'clientefornecedor/obter-padrao-sistema',
  CLIENTE_FORNECEDOR_LISTAR_SELECT_CONSULTA_OPERACOES:
    'clientefornecedor/listar-select-consulta-operacoes',
  CONTA_FINANCEIRA_LISTAR_CAIXA_ATIVO: 'contaFinanceira/listar-caixas-ativos',

  TABELA_PRECO_LISTAR_POR_NOME: 'tabelapreco/listar-por-nome',
  TABELA_PRECO_LISTAR_TABELAS_PRECO: 'tabelapreco/listar-tabelas-preco',
  TABELA_PRECO_LISTAR_PAGINADO: 'tabelapreco/listar-paginado',
  TABELA_PRECO_OBTER_PRECO_PRODUTO:
    'V2/TabelaPreco/id/produtoCorTamanho/variacaoId/preco',
  TABELA_PRECO_EXCLUIR: 'tabelapreco/excluir',
  TABELA_PRECO_INATIVAR: 'tabelapreco/inativar',
  TABELA_PRECO_ALTERAR: 'tabelapreco/alterar',
  TABELA_PRECO_CADASTRAR: 'tabelapreco/cadastrar',
  TABELA_PRECO_OBTER: 'tabelapreco/obter',

  NOTA_FISCAL_CADASTRAR: 'notafiscal/cadastrar',
  NOTA_FISCAL_LISTAR_PAGINADO: 'v2/notas-fiscais',
  NOTA_FISCAL_EXCLUIR: 'notafiscal/excluir',
  NOTA_FISCAL_ALTERAR_STATUS_RETORNO: 'notafiscal/alterar-status-retorno',
  NOTA_FISCAL_DUPLICAR: 'notafiscal/duplicar',
  NOTA_FISCAL_ALTERAR: 'notafiscal/alterar',
  NOTA_FISCAL_ALTERAR_PDV: 'notafiscal/alterar-pdv',
  NOTA_FISCAL_OBTER: 'notafiscal/obter',
  NOTA_FISCAL_OBTER_INFORMACOES_OPERACAO:
    'notafiscal/obter-informacoes-operacao',
  NOTA_FISCAL_INUTILIZAR: 'notafiscal/inutilizar',
  NOTA_FISCAL_EVENTO: 'notafiscal/evento',
  NOTA_FISCAL_OBTER_INFO_CARTA_CORRECAO:
    'notafiscal/obter-info-criar-carta-correcao',
  NOTA_FISCAL_CALCULAR_TOTAIS: 'notafiscal/calcular-totais',
  NOTA_FISCAL_CALCULAR_VALORES_ITENS: 'notafiscal/calcular-valores-item',
  NOTA_FISCAL_LISTAR_ARQUIVOS_XML: 'notafiscal/listar-arquivos-xml',
  NOTA_FISCAL_TRANSMITIR: 'notafiscal/transmitir',
  NOTA_FISCAL_OBTER_STATUS: 'notafiscal/status',
  NOTA_FISCAL_CONSULTAR_PROTOCOLO: 'notafiscal/consultar-protocolo',
  NOTA_FISCAL_DANFE: 'notafiscal/danfe',
  NOTA_FISCAL_ENVIAR_EMAIL: 'notafiscal/enviar-email-nota-fiscal',
  NOTA_FISCAL_IMPRIMIR_NFCE: 'notafiscal/imprimir-nfce',
  CONTAS_RECEBER_ENVIAR_EMAIL: 'contasreceber/enviar-email',
  NOTA_FICAL_IMPRIMIR_RELATORIO_LISTAGEM: 'notafiscal/relatorio-listagem',
  NOTA_FICAL_IMPRIMIR_EMAIL: 'notafiscal/enviar-email-relatorio-listagem',
  NOTA_FISCAL_IMPRIMIR_CARTA_CORRECAO: 'notafiscal/imprimir-carta-correcao',

  PERFIL_USUARIO_CADASTRAR: 'perfilusuario/cadastrar',
  PERFIL_USUARIO_EXCLUIR: 'perfilusuario/excluir',
  PERFIL_USUARIO_LISTAR_PAGINADO: 'perfilusuario/listar-paginado',
  PERFIL_USUARIO_OBTER: 'perfilusuario/obter',
  PERFIL_USUARIO_ALTERAR: 'perfilusuario/alterar',
  PERFIL_USUARIO_SELECT: 'perfilusuario/listar-select',
  PERFIL_USUARIO_INATIVAR: 'perfilusuario/inativar',
  PERFIL_USUARIO_LISTAR_CATEGORIA_PERMISSOES:
    'perfilusuario/listar-categoria-permissoes',

  MOVIMENTACAO_FINANCEIRA_VALIDAR_CONTAS_EM_ABERTO:
    'movimentacaofinanceira/validar-contas-em-aberto',
  MOVIMENTACAO_FINANCEIRA_INFORMACOES_FISCAIS:
    'MovimentacaoFinanceira/agrupamento/identificadorAgrupamento/alterar-informacoes-fiscais',
  MOVIMENTACAO_FINANCEIRA_CADASTRAR: 'movimentacaofinanceira/cadastrar',
  MOVIMENTACAO_FINANCEIRA_EXCLUIR: 'movimentacaofinanceira/excluir',
  MOVIMENTACAO_FINANCEIRA_OBTER_PARCELAS:
    'movimentacaofinanceira/obter-parcelas',
  MOVIMENTACAO_FINANCEIRA_ALTERAR_VALOR_VENCIMENTO:
    'movimentacaofinanceira/alterar-valor-vencimento',
  MOVIMENTACAO_FINANCEIRA_VALIDAR_LIMITE_CREDITO:
    'movimentacaoFinanceira/validar-limite-credito',

  CADASTRAR_VALOR_META_GERAL: 'lojaMetaComissao/cadastrar-meta-geral',
  ALTERAR_VALOR_META_GERAL: 'lojaMetaComissao/alterar-meta-geral',
  OBTER_VALOR_META_GERAL: 'lojaMetaComissao/obter-meta-geral',
  LISTAR_VENDEDORES_META_COMISSOES: 'vendedor/listar-vendedores-metas',
  OBTER_CADASTRO_METAS_COMISSAO_REGRA_REPASSE:
    'lojaMetaComissao/obter-regra-repasse',
  OBTER_FAIXA_PREMIACAO_VENDEDOR: 'lojaFaixaMeta/obter',
  OBTER_CADASTRO_PREMIACAO_VENDEDOR:
    'lojaMetaComissao/obter-tipo-pagamento-comissao',

  LISTAR_VENDEDORES_METAS_COM_FAIXA:
    'vendedor/listar-vendedores-faixas-comissao',

  CADASTRAR_VENDEDORES_METAS_COM_FAIXA:
    'vendedorComissao/cadastrar-comissao-vendedores',
  CADASTRAR_LISTA_COMISSAO_VENDEDOR: 'vendedorMeta/cadastrar',
  CADASTRAR_META_COMISSAO_REGRA_REPASSE:
    'lojaMetaComissao/alterar-regra-repasse',
  CADASTRAR_PREMIACAO_VENDEDOR:
    'lojaMetaComissao/alterar-tipo-pagamento-comissao',
  CADASTRAR_FAIXA_PREMIACAO_VENDEDOR: 'lojaFaixaMeta/cadastrar',
  EXCLUIR_CONFIGURACAO_METAS_COMISSOES: 'lojaMetaComissao/excluir',

  RELATORIO_HISTORICO_COMISSAO_METAS_COMISSOES:
    'relatorioMetaComissao/obter-historico-comissao-paginado',
  RELATORIO_METAS_RESUMO_DAS_LOJAS:
    'relatorioMetaComissao/obter-resumo-loja-vendedor',
  RELATORIO_METAS_COMISSOES_OBTER_PERFORMANCE_VENDEDOR:
    'relatorioMetaComissao/obter-performance-vendedores',
  RELATORIO_METAS_CALCULO_COMISSAO:
    'relatorioMetaComissao/obter-calculo-comissao',
  RELATORIO_METAS_COMISSAO_IMPRIMIR_RESUMO_LOJA:
    'relatorioMetaComissao/impressao-relatorio-resumo-loja-meta-comissao',
  RELATORIO_METAS_COMISSAO_EMAIL_RESUMO_LOJA:
    'relatorioMetaComissao/enviar-email-relatorio-resumo-loja-meta-comissao',
  RELATORIO_METAS_COMISSAO_IMPRIMIR_RESUMO_VENDEDOR:
    'relatorioMetaComissao/impressao-relatorio-resumo-vendedor-meta-comissao',
  RELATORIO_METAS_COMISSAO_EMAIL_RESUMO_VENDEDOR:
    'relatorioMetaComissao/enviar-email-relatorio-resumo-vendedor-meta-comissao',
  RELATORIO_METAS_COMISSAO_HISTORICO_COMISSAO_IMPRIMIR:
    'relatorioMetaComissao/impressao-relatorio-historico-comissao-loja',
  RELATORIO_METAS_COMISSAO_HISTORICO_COMISSAO_EMAIL:
    'relatorioMetaComissao/enviar-email-relatorio-historico-comissao-loja',

  TOTALIZADORES_META_COMISSAO:
    'lojaMetaComissaoPorDia/obter-totalizadores-meta-loja',
  FATURAMENTO_POR_VENDEDOR_COMISSAO:
    'vendedorMetaComissaoPorMes/obter-faturamento-vendedores',
  PERFORMANCE_POR_VENDEDOR:
    'vendedorMetaComissaoPorMes/obter-performance-vendedores',
  META_COMISSAO_POR_DIA:
    'lojaMetaComissaoPorDia/obter-grafico-meta-loja-por-dia',
  PERFORMANCE_VENDAS_PESSOAL: 'vendedorMetaComissaoPorMes/obter',

  OBTER_VENDEDOR_VINCULADO: 'usuario/obter-vendedor-usuario',

  LISTAR_PAGINADO_HISTORICO_VENDA_METAS:
    'relatorioVendas/listar-paginado-historico-venda',
  ENVIAR_EMAIL_METAS_HISTORICO_VENDAS:
    'relatorioVendas/enviar-email-historico-venda',
  IMPRIMIR_HISTORICO_VENDAS:
    'relatorioVendas/relatorio-listagem-historico-venda',

  USUARIO_CADASTRAR: 'usuario/cadastrar',
  USUARIO_EXCLUIR: 'usuario/excluir',
  USUARIO_LISTAR_PAGINADO: 'usuario/listar-paginado',
  USUARIO_OBTER: 'usuario/obter',
  USUARIO_ALTERAR: 'usuario/alterar',
  USUARIO_INATIVAR: 'usuario/inativar',
  USUARIO_LISTAR_TODOS_ATIVOS: 'usuario/listar-todos-ativos',

  USUARIO_OBTER_QUANTIDADE_USUARIOS_ATIVOS:
    'usuario/obter-quantidade-usuarios-ativos',
  USUARIO_OBTER_QUANTIDADE_LICENCAS:
    'usuario/obter-quantidade-licencas-usuario',

  BASE_LEGAL_SELECT: 'baselegal/listar-select',

  REGRA_FISCAL_ITEM_LISTAR_SELECT_NOTA_FISCAL:
    'regrafiscalitem/listar-select-nota-fiscal',

  NCM_LISTAR_SELECT: 'ncm/listar-select',

  REGRA_FISCAL_LISTAR_SELECT: 'regrafiscal/listar-select',
  REGRA_FISCAL_LISTAR_PAGINADO: 'regrafiscal/listar-paginado',
  REGRA_FISCAL_EXCLUIR: 'regrafiscal/excluir',
  REGRA_FISCAL_INATIVAR: 'regrafiscal/inativar',
  REGRA_FISCAL_CADASTRAR: 'regrafiscal/cadastrar',
  REGRA_FISCAL_ALTERAR: 'regrafiscal/alterar',
  REGRA_FISCAL_OBTER: 'regrafiscal/obter',

  ANP_LISTAR_SELECT: 'anp/listar-select',

  FORMA_PAGAMENTO_LISTAR_SELECT_NOTA_FISCAL:
    'formaPagamento/listar-select-nota-fiscal',
  FORMA_PAGAMENTO_LISTAR_SELECT_PDV: 'formaPagamento/listar-select-pdv',
  FORMA_PAGAMENTO_LISTAR_SELECT_PAGAMENTO:
    'formaPagamento/listar-select-pagamento',
  FORMA_RECEBIMENTO_LISTAR_SELECT_RECEBIMENTO:
    'formaPagamentoRecebimento/listar-select-recebimento',
  FORMA_RECEBIMENTO_LISTAR_SELECT_BAIXA:
    'formaPagamentoRecebimento/listar-select-recebimento-baixa',

  FORMA_RECEBIMENTO_PARCELA_EXCLUIR: 'FormaPagamentoRecebimentoParcela/excluir',
  FORMA_RECEBIMENTO_PARCELA_CADASTRAR:
    'FormaPagamentoRecebimentoParcela/cadastrar',
  FORMA_RECEBIMENTO_PARCELA_ALTERAR: 'FormaPagamentoRecebimentoParcela/alterar',

  FORMA_PAGAMENTO_RECEBIMENTO_PARCELA_LISTAR_SELECT_PDV:
    'formaPagamentoRecebimentoParcela/listar-select-pdv',

  FORMA_PAGAMENTO_RECEBIMENTO_LISTAR_FORMAS_RECEBIMENTO_FISICAS:
    'formaPagamentoRecebimento/listar-formas-recebimento-fisicas',

  FORMA_PAGAMENTO_RECEBIMENTO_LISTAR_RECEBIMENTOS:
    'formapagamentorecebimento/listar-select-recebimento',

  PADRONIZACAO_ALTERAR: 'padronizacao/alterar',
  PADRONIZACAO_OBTER: 'padronizacao/obter',
  PADRONIZACAO_OBTER_CASAS_DECIMAIS: 'padronizacao/obter-casas-decimais',
  PADRONIZACAO_MULTAS_JUROS_OBTER: 'lojamultaJuros/obter',
  PADRONIZACAO_MULTAS_JUROS_ALTERAR: 'lojamultaJuros/alterar',

  PEDIDOORCAMENTOVENDA_OBTER_QUANTIDADE_PEDIDOS_DIA:
    'pedidoorcamentovenda/obter-quantidade-pedidos-dia',
  PEDIDOORCAMENTOVENDA_LISTAR_PEDIDOS_DIA:
    'pedidoorcamentovenda/listar-pedidos-dia',
  PEDIDOORCAMENTOVENDA_LISTAR_PAGINADO: 'pedidoorcamentovenda/listar-paginado',
  PEDIDOORCAMENTOVENDA_LISTAR_PAGINADO_VENDAS:
    'pedidoorcamentovenda/listar-paginado-vendas',
  PEDIDOORCAMENTOVENDA_OBTER_COM_ITENS: 'pedidoorcamentovenda/obter-com-itens',
  PEDIDOORCAMENTOVENDA_OBTER_COM_PAGAMENTOS:
    'pedidoorcamentovenda/obter-com-pagamentos',
  PEDIDOORCAMENTOVENDA_OBTER_DETALHADO: 'pedidoorcamentovenda/obter-detalhado',
  PEDIDOORCAMENTOVENDA_OBTER_PARA_FINALIZAR:
    'pedidoorcamentovenda/obter-para-finalizar',
  PEDIDOORCAMENTOVENDA_OBTER_PARA_COMPARTILHAR:
    'pedidoorcamentovenda/obter-para-compartilhar',
  PEDIDOORCAMENTOVENDA_ALTERAR: 'pedidoorcamentovenda/alterar',
  PEDIDOORCAMENTOVENDA_CANCELAR: 'pedidoorcamentovenda/cancelar',
  PEDIDOORCAMENTOVENDA_CADASTRAR: 'pedidoorcamentovenda/cadastrar',
  PEDIDOORCAMENTOVENDA_DUPLICAR: 'pedidoorcamentovenda/duplicar',
  PEDIDOORCAMENTOVENDA_ATUALIZAR_TOTAIS:
    'pedidoorcamentovenda/atualizar-totais',
  PEDIDOORCAMENTOVENDA_FINALIZAR_VENDA: 'pedidoorcamentovenda/finalizar-venda',
  PEDIDOORCAMENTOVENDA_GERAR_NOTA_FISCAL:
    'pedidoorcamentovenda/gerar-nota-fiscal',
  PEDIDOORCAMENTOVENDA_OBTER_NOTA_FISCAL_ALTERAR:
    'pedidoorcamentovenda/obter-nota-fiscal-alterar',
  PEDIDOORCAMENTOVENDA_OBTER_PARA_IMPRIMIR:
    'pedidoorcamentovenda/obter-para-imprimir',
  PEDIDOORCAMENTOVENDA_ENVIAR_EMAIL_CUPOM_NAO_FISCAL:
    'pedidoorcamentovenda/enviar-email-cupom-nao-fiscal',
  PEDIDOORCAMENTOVENDA_ALTERAR_OPERACAO_TABELA_PRECO:
    'v2/Operacao/id/tabelaPreco',
  CONTAS_RECEBER_OBTER_PARA_IMPRIMIR: 'contasreceber/obter-para-imprimir',
  CONTAS_RECEBER_OBTER_TOTALIZADORES:
    'contasreceber/obter-totalizadores-lista-paginada',
  PEDIDOORCAMENTOVENDA_OBTER_PARA_IMPRIMIR_CARNE:
    'pedidoorcamentovenda/obter-para-imprimir-carne',

  PEDIDOORCAMENTOVENDA_ITEM_CADASTRAR: 'pedidoorcamentovendaitem/cadastrar',
  PEDIDOORCAMENTOVENDA_ITEM_EXCLUIR: 'pedidoorcamentovendaitem/excluir',
  PEDIDOORCAMENTOVENDA_ITEM_ALTERAR: 'pedidoorcamentovendaitem/alterar',
  PEDIDOORCAMENTOVENDA_ITEM_OBTER_INFORMACOES_ALTERAR:
    'pedidoorcamentovendaitem/obter-informacoes-alterar',
  PEDIDOORCAMENTOVENDA_ITEM_ALTERAR_INFORMACOES_COMPLEMENTARES_ITEM:
    'pedidoorcamentovendaitem/alterar-informacoes-complementares-item',

  CERTIFICADODIGITAL_UPLOAD: 'certificadoDigital/upload',
  CERTIFICADODIGITAL_VALIDARSALVAR: 'certificadoDigital/ValidarSalvar',
  CERTIFICADODIGITAL_OBTER: 'certificadoDigital/obter',

  DOCUMENTO_FISCAL_EXPORTACAO_OBTER: 'documentofiscalexportacao/obter',
  DOCUMENTO_FISCAL_EXPORTACAO_LISTAR_ARQUIVOS: 'v2/DocumentoFiscal/Exportacao',
  DOCUMENTO_FISCAL_EXPORTACAO_LISTAR_PAGINADO:
    'documentofiscalexportacao/listar-paginado',
  DOCUMENTO_FISCAL_EXPORTACAO_EXCLUIR: 'documentofiscalexportacao/excluir',
  DOCUMENTO_FISCAL_EXPORTACAO_REENVIAR_EMAIL:
    'documentofiscalexportacao/reenviar-email',
  DOCUMENTO_FISCAL_EXPORTACAO_CADASTRAR: 'V2/DocumentoFiscal/Exportacao',

  DASHBOARD_OBTER_ULTIMOS_DOZE_MESES: 'dashboard/obter-ultimos-doze-meses',
  DASHBOARD_MES_ATUAL: 'dashboard/obter-mes-atual',

  BANCO_LISTAR: 'banco/listar',

  PDV_AUTONOMO_DESISTIR_INTEGRACAO: 'pdvAutonomo/desistir',
  PDV_AUTONOMO_OBTER_INTEGRACAO: 'pdvAutonomo/obter-integracao',
  PDV_AUTONOMO_OBTER_DISPOSITIVO: 'pdvAutonomo/obter-lista-dispositivo',
  PDV_AUTONOMO_CADASTRAR_INTEGRACAO: 'pdvAutonomo/cadastrar-integracao',
  PDV_AUTONOMO_INATIVAR: 'pdvAutonomo/inativar',
  PDV_AUTONOMO_ATIVAR: 'pdvAutonomo/ativar',
  PDV_AUTONOMO_OBTER_TABELA_PRECO:
    'pdvAutonomo/obter-configuracao-tabela-preco-para-alterar',
  PDV_AUTONOMO_CADASTRAR_TABELA_PRECO:
    'pdvAutonomo/alterar-configuracao-tabela-preco',
  PDV_AUTONOMO_EXPORTAR: 'pdvAutonomo/exportar',
  PDV_AUTONOMO_GERAR_SCRIPT: 'pdvAutonomo/gerar-script',
  PDV_AUTONOMO_BAIXAR_SCRIPT: 'pdvAutonomo/obter-url-script',
  PDV_AUTONOMO_QUANTIDADE_DISPOSITIVO:
    'pdvAutonomo/obter-quantidade-ativa-dispositivo',
  PDV_AUTONOMO_GERAR_PIN: 'pdvAutonomo/gerar-pin',
  PDV_AUTONOMO_OBTER_CONFIGURACAO:
    'pdvAutonomo/obter-configuracao-para-alterar',
  PDV_AUTONOMO_ALTERAR_CONFIGURACAO: 'pdvAutonomo/alterar-configuracao',
  PDV_ALTERAR_CONFIGURACAO_TEF: 'pdvAutonomo/alterar-configuracao-tef',
  PDV_AUTONOMO_OBTER_FORMA_RECEBIMENTO:
    'pdvAutonomo/obter-configuracao-tef-para-alterar',
  PDV_AUTONOMO_LISTAR_CREDENCIAL_CARTAO:
    'pdvAutonomo/obter-lista-credenciadora-cartao',

  FRENTE_CAIXA_DESISTIR_INTEGRACAO: 'frenteCaixa/desistir',
  FRENTE_CAIXA_CADASTRAR_INTEGRACAO: 'frentecaixa/cadastrar-integracao',
  FRENTE_CAIXA_INATIVAR: 'frenteCaixa/inativar',
  FRENTE_CAIXA_OBTER_CONFIGURACAO:
    'frenteCaixa/obter-configuracao-para-alterar',
  FRENTE_CAIXA_ALTERAR_CONFIGURACAO: 'frenteCaixa/alterar-configuracao',
  FRENTE_CAIXA_LISTAR_CREDENCIAL_CARTAO:
    'frenteCaixa/obter-lista-credenciadora-cartao',
  FRENTE_CAIXA_OBTER_FORMA_RECEBIMENTO:
    'frenteCaixa/obter-configuracao-tef-para-alterar',
  FRENTE_CAIXA_CONFIGURACAO_TEF: 'frenteCaixa/alterar-configuracao-tef',
  FRENTE_CAIXA_ATIVAR: 'frenteCaixa/ativar',
  FRENTE_CAIXA_GERAR_PIN: 'frenteCaixa/gerar-pin',
  FRENTE_CAIXA_OBTER_DISPOSITIVO: 'frenteCaixa/obter-lista-dispositivo',
  FRENTE_CAIXA_QUANTIDADE_DISPOSITIVO:
    'frenteCaixa/obter-quantidade-ativa-dispositivo',
  FRENTE_CAIXA_EXPORTAR: 'frenteCaixa/exportar',
  FRENTE_CAIXA_BAIXAR_SCRIPT: 'frenteCaixa/obter-url-script',
  FRENTE_CAIXA_GERAR_SCRIPT: 'frenteCaixa/gerar-script',
  FRENTE_CAIXA_OBTER_INTEGRACAO: 'frenteCaixa/obter-integracao',

  FRENTE_CAIXA_INTERMEDIADOR_CADASTRAR: 'marketplace/cadastrar',
  FRENTE_CAIXA_INTERMEDIADOR_OBTER: 'marketplace/obter',
  FRENTE_CAIXA_INTERMEDIADOR_EXCLUIR: 'marketplace/excluir',
  FRENTE_CAIXA_INTERMEDIADOR_ALTERAR: 'marketplace/alterar',
  FRENTE_CAIXA_INTERMEDIADOR_LISTAR_PAGINADO: 'marketplace/listar-paginado',

  SETOR_ENTREGA: '/V2/SetorEntrega',
  SETOR_ENTREGA_SELECT_PAGINADO: 'v2/lojas/id/setores-entrega',

  DEPARTAMENTO: 'V2/departamento',
  DEPARTAMENTO_LISTAR_SELECT: 'v2/departamento/select',

  DEPARTAMENTO_IMPRESSORA: 'V2/departamento/impressora',

  ENTREGADOR: 'v2/entregador',

  GERENCIADOR_IMPRESSAO: 'v2/gerenciadorimpressao',
  GERENCIADOR_IMPRESSAO_CONFIGURACAO: 'v2/configuracaoGerenciadorImpressao',

  FRENTE_CAIXA_RELATORIO_PERSONALIZADO_CADASTRAR:
    'relatorioPersonalizado/cadastrar',
  FRENTE_CAIXA_RELATORIO_PERSONALIZADO_OBTER: 'relatorioPersonalizado/obter',
  FRENTE_CAIXA_RELATORIO_PERSONALIZADO_EXCLUIR:
    'relatorioPersonalizado/excluir',
  FRENTE_CAIXA_RELATORIO_PERSONALIZADO_INATIVAR:
    'relatorioPersonalizado/inativar',
  FRENTE_CAIXA_RELATORIO_PERSONALIZADO_ALTERAR:
    'relatorioPersonalizado/alterar',
  FRENTE_CAIXA_RELATORIO_PERSONALIZADO_LISTAR_PAGINADO:
    'relatorioPersonalizado/listar-paginado',

  LOCAL_ESTOQUE_LISTAR_PAGINADO: 'localestoque/listar-paginado',
  LOCAL_ESTOQUE_EXCLUIR: 'localestoque/excluir',
  LOCAL_ESTOQUE_INATIVAR: 'localestoque/inativar',
  LOCAL_ESTOQUE_CADASTRAR: 'localestoque/cadastrar',
  LOCAL_ESTOQUE_ALTERAR: 'localestoque/alterar',
  LOCAL_ESTOQUE_OBTER: 'localestoque/obter',
  LOCAL_ESTOQUE_OBTER_PADRAO_SISTEMA: 'localestoque/obter-padrao-sistema',
  LOCAL_ESTOQUE_LISTAR_SELECT: 'localestoque/listar-select',
  LOCAL_ESTOQUE_LISTAR_PDV: 'LocalEstoque/listar-select-pdv',

  MOVIMENTACAO_ESTOQUE_LISTAR_PAGINADO: 'movimentacaoestoque/listar-paginado',
  MOVIMENTACAO_ESTOQUE_LISTAR_ITENS_PAGINADO:
    'movimentacaoestoque/listar-itens-paginado',
  MOVIMENTACAO_ESTOQUE_CADASTRAR: 'movimentacaoestoque/cadastrar',
  MOVIMENTACAO_ESTOQUE_ALTERAR: 'movimentacaoestoque/alterar',
  MOVIMENTACAO_ESTOQUE_CANCELAR: 'movimentacaoestoque/cancelar',
  MOVIMENTACAO_ESTOQUE_DUPLICAR: 'movimentacaoestoque/duplicar',
  MOVIMENTACAO_ESTOQUE_OBTER: 'movimentacaoestoque/obter',

  TRANSFERENCIA_ESTOQUE_LISTAR_PAGINADO: 'transferenciaestoque/listar-paginado',
  TRANSFERENCIA_ESTOQUE_LISTAR_ITENS_PAGINADO:
    'transferenciaestoque/listar-itens-paginado',
  TRANSFERENCIA_ESTOQUE_CADASTRAR: 'transferenciaestoque/cadastrar',
  TRANSFERENCIA_ESTOQUE_ALTERAR: 'transferenciaestoque/alterar',
  TRANSFERENCIA_ESTOQUE_ALTERAR_ITENS_DIVERGENTES:
    'transferenciaestoque/alterar-itens-divergentes',
  TRANSFERENCIA_ESTOQUE_ALTERAR_STATUS: 'transferenciaestoque/alterar-status',
  TRANSFERENCIA_ESTOQUE_OBTER: 'transferenciaestoque/obter',
  TRANSFERENCIA_ESTOQUE_LISTAR_DIVERGENCIAS_ESTOQUE:
    'transferenciaestoque/listar-divergencias-estoque',

  HISTORICO_PRODUTO_LISTAR_PAGINADO: 'historicoproduto/listar-paginado',

  CONFERENCIA_ESTOQUE_VERIFICAR_CONFERENCIA_EXISTENTE:
    'conferenciaestoque/verificar-conferencia-existente',
  CONFERENCIA_ESTOQUE_CADASTRAR: 'conferenciaestoque/cadastrar',
  CONFERENCIA_ESTOQUE_CADASTRAR_NOVOS_FILTROS:
    'conferenciaestoque/cadastrar-novos-filtros',
  CONFERENCIA_ESTOQUE_EXCLUIR: 'conferenciaestoque/excluir',
  CONFERENCIA_ESTOQUE_EXCLUIR_ITEM: 'conferenciaestoque/excluir-item',
  CONFERENCIA_ESTOQUE_OBTER_CONFERENCIA: 'conferenciaestoque/obter-conferencia',
  CONFERENCIA_ESTOQUE_OBTER_LISTAR_ITENS_PAGINADO:
    'conferenciaestoque/listar-itens-paginado',
  CONFERENCIA_ESTOQUE_CADASTRAR_ITENS: 'conferenciaestoque/cadastrar-itens',
  CONFERENCIA_ESTOQUE_CADASTRAR_ITENS_POR_CODIGO:
    'conferenciaestoque/cadastrar-itens-por-codigo',
  CONFERENCIA_ESTOQUE_ALTERAR_ITEM: 'conferenciaestoque/alterar-item',
  CONFERENCIA_ESTOQUE_OBTER_DIVERGENCIAS:
    'conferenciaestoque/obter-divergencias',
  CONFERENCIA_ESTOQUE_CORRECAO: 'conferenciaestoque/correcao',
  CONFERENCIA_ESTOQUE_EXPORTAR_PRODUTOS: 'conferenciaestoque/exportar-produtos',

  ENTRADA_MERCADORIA_CADASTRAR_ENTRADA_MANUAL:
    'entradamercadoria/cadastrar-entrada-manual',
  ENTRADA_MERCADORIA_IMPRESSAO: 'entradaMercadoria/impressao-a4',
  ENTRADA_MERCADORIA_LISTAR_ITENS_PAGINADOS:
    'entradamercadoria/listar-itens-paginados',
  ENTRADA_MERCADORIA_OBTER_FORNECEDOR: 'entradamercadoria/obter-fornecedor',
  ENTRADA_MERCADORIA_ALTERAR_FORNECEDOR: 'entradamercadoria/alterar-fornecedor',
  ENTRADA_MERCADORIA_EXCLUIR: 'entradamercadoria/excluir',
  ENTRADA_MERCADORIA_ADICIONAR_ITENS: 'entradamercadoria/adicionar-itens',
  ENTRADA_MERCADORIA_REMOVER_ITEM: 'entradamercadoria/remover-item',
  ENTRADA_MERCADORIA_ALTERAR_ITEM: 'entradamercadoria/alterar-item',
  ENTRADA_MERCADORIA_OBTER_VALORES: 'entradamercadoria/obter-valores',
  ENTRADA_MERCADORIA_ADICIONAR_VALORES: 'entradamercadoria/adicionar-valores',
  ENTRADA_MERCADORIA_LISTAR_PAGINADO: 'entradamercadoria/listar-paginado',
  ENTRADA_MERCADORIA_LISTAR_ITENS_PARA_RATEIO_ICMSST:
    'entradamercadoria/listar-itens-para-rateio-icmsst',
  ENTRADA_MERCADORIA_ALTERAR_ITEM_PARTICIPA_RATEIO_ICMSST:
    'entradamercadoria/alterar-item-participa-rateio-icmsst',
  ENTRADA_MERCADORIA_OBTER_PAGAMENTO: 'entradamercadoria/obter-pagamento',
  ENTRADA_MERCADORIA_ADICIONAR_PAGAMENTOS:
    'entradamercadoria/adicionar-pagamentos',
  ENTRADA_MERCADORIA_OBTER_INFORMACOES_GERAIS:
    'entradamercadoria/obter-informacoes-gerais',
  ENTRADA_MERCADORIA_ADICIONAR_INFORMACOES_GERAIS:
    'entradamercadoria/adicionar-informacoes-gerais',
  ENTRADA_MERCADORIA_OBTER_INFORMACOES_PRODUTOS:
    'entradamercadoria/obter-informacoes-produtos',
  ENTRADA_MERCADORIA_CANCELAR: 'entradamercadoria/cancelar',
  ENTRADA_MERCADORIA_CANCELAR_VINCULAR_XML: 'entradamercadoria/vincular-xml',
  ENTRADA_MERCADORIA_DUPLICAR: 'entradamercadoria/duplicar',
  ENTRADA_MERCADORIA_OBTER_PRODUTOS_LANCAR_ESTOQUE:
    'entradamercadoria/obter-produtos-lancar-estoque',
  ENTRADA_MERCADORIA_LANCAR_ESTOQUE: 'entradamercadoria/lancar-estoque',
  ENTRADA_MERCADORIA_OBTER_STATUS_LANCAMENTOS:
    'entradamercadoria/obter-status-lancamentos',
  ENTRADA_MERCADORIA_OBTER_HISTORICO_LANCAMENTO_ESTOQUE:
    'entradamercadoria/obter-historico-lancamento-estoque',
  ENTRADA_MERCADORIA_OBTER_IMPORTACAO_XML_EM_ANDAMENTO:
    'entradamercadoria/obter-importacao-xml-em-andamento',
  ENTRADA_MERCADORIA_CADASTRAR_ENTRADA_IMPORTACAO:
    'entradamercadoria/cadastrar-entrada-importacao',
  ENTRADA_MERCADORIA_LISTAR_ITENS_PAGINADOS_IMPORTACAO_XML:
    'entradamercadoria/listar-itens-paginados-importacao-xml',
  ENTRADA_MERCADORIA_VINCULAR_ITEM: 'entradamercadoria/vincular-item',
  ENTRADA_MERCADORIA_ESTORNAR_ESTOQUE: 'entradamercadoria/estornar-estoque',
  ENTRADA_MERCADORIA_OBTER_ITENS_VINCULADOS:
    'entradamercadoria/obter-itens-vinculados',
  ENTRADA_MERCADORIA_LANCAR_FINANCEIRO: 'entradamercadoria/lancar-financeiro',
  ENTRADA_MERCADORIA_ESTORNAR_FINANCEIRO:
    'entradamercadoria/estornar-financeiro',

  CADASTRAR_PROMOCAO: 'v2/Promocao',
  CADASTRAR_PRODUTO_PROMOCAO: 'v2/Promocao/id/itens',
  PESQUISAR_PRODUTOS_PROMOCAO: 'v2/Promocao/produtos',

  MOVIDESK_GERAR_TOKEN_TEMPORARIO: 'movidesk/gerar-token-temporario-movidesk',

  SUPRIMENTOSANGRIA_CADASTRAR_SUPRIMENTO:
    'suprimentosangria/cadastrar-suprimento',
  SUPRIMENTOSANGRIA_CADASTRAR_SANGRIA: 'suprimentosangria/cadastrar-sangria',

  CONTROLE_CAIXA_LISTAR_PAGINADO: 'controlecaixa/listar-paginado',
  CONTROLE_CAIXA_DETALHES: 'controlecaixa/detalhes',
  CONTROLE_CAIXA_LISTAR_MOVIMENTACAO:
    'controlecaixa/listar-movimentacoes-paginadas-por-caixa-movimentacao',
  CONTROLE_CAIXA_REABRIR_CAIXA: 'controlecaixa/reabrir-caixa',

  EXTRATO_TRANSFERIR: 'extrato/transferir',
  EXTRATO_LISTAR: 'extrato/listar',
  EXTRATO_CONCILIACAO: 'extrato/conciliacao',
  EXTRATO_EXCLUIR: 'extrato/excluir',

  FATURA_LISTAR_MES_ATUAL: 'fatura/listar-mes-atual',
  FATURA_OBTER_FATURA_COMPLETA: 'fatura/obter-fatura-completa',
  FATURA_EXCLUIR_MOVIMENTACAO_FINANCEIRA:
    'fatura/excluir-movimentacao-financeira',
  FATURA_CONCILIACAO: 'fatura/conciliacao',
  FATURA_MOVER_MOVIMENTACAO_FINANCEIRA: 'fatura/mover-movimentacao-financeira',

  IMPRIMI_RELATORIO_DETALHES_FATURA: 'fatura/relatorio-listagem',
  ENVIAR_RELATORIO_EMAIL_DETALHES_FATURA:
    'fatura/enviar-email-relatorio-listagem',

  FECHAMENTO_CAIXA_LISTAR_MOVIMENTACOES: 'fechamentocaixa/listar-movimentacoes',
  FECHAMENTO_CAIXA_FECHAR: 'fechamentocaixa/fechar',
  FECHAMENTO_CAIXA_IMPRESSAO_A4: 'fechamentocaixa/impressao-a4',

  FECHAMENTO_CAIXA_OBTER_PARA_IMPRIMIR: 'fechamentocaixa/obter-para-imprimir',
  FECHAMENTO_CAIXA_ENVIAR_EMAIL: 'fechamentoCaixa/enviar-email',

  LANCAMENTO_FINANCEIRO_CADASTRAR: 'lancamentofinanceiro/cadastrar',
  LANCAMENTO_FINANCEIRO_ALTERAR: 'lancamentofinanceiro/alterar',
  LANCAMENTO_FINANCEIRO_OBTER: 'lancamentofinanceiro/obter',

  MANIFESTO_DESTINATARIO_CONSULTA_NOTAS: 'manifestodestinatario/consulta-notas',
  MANIFESTO_DESTINATARIO_ACAO: 'manifestodestinatario/acao',
  MANIFESTO_DESTINATARIO_IMPORTAR_XML: 'manifestodestinatario/importar-xml',
  MANIFESTO_DESTINATARIO_BAIXAR_XML: 'manifestodestinatario/baixar-xml',
  MANIFESTO_DESTINATARIO_LISTAR_PAGINADO:
    'manifestodestinatario/listar-paginado',
  MANIFESTO_DESTINATARIO_EVENTOS: 'manifestoDestinatario/eventos/',

  CONTAS_RECEBER_LISTAR_PAGINADO: 'contasreceber/listar-paginado',
  CONTAS_RECEBER_OBTER_DETALHE: 'contasreceber/obter-detalhes',
  CONTAS_RECEBER_BAIXAR_CONTAS: 'contasreceber/baixar-contas-cliente',
  CONTAS_RECEBER_EXCLUIR_BAIXA: 'contasreceber/excluir-baixa',

  CONCILIACAO: 'conciliacao',
  CONCILIACAO_ALTERAR: 'conciliacao/alterar',
  LISTAR_CONCILIAR: 'conciliacao/listar-conciliar',
  CONCILIACAO_RELATORIO_LISTAGEM: 'conciliacao/relatorio-listagem',
  CONCILIACAO_RELATORIO_EMAIL: 'conciliacao/enviar-email-relatorio-listagem',
  CONCILIACAO_ANTECIPACAO: 'conciliacao/antecipacao',
  CONCILIACAO_CANCELAR_ANTECIPACAO: 'conciliacao/cancelar-antecipacao',

  RECEBIMENTO_CONTAS_OBTER_CONTAS_EM_ABERTO:
    'clientefornecedor/obter-contas-em-aberto',

  CONTAS_PAGAR_LISTAR_PAGINADO: 'contaspagar/listar-paginado',
  CONTAS_PAGAR_BAIXAR_CONTAS: 'contaspagar/baixar-contas',
  CONTAS_PAGAR_EXCLUIR_CONTAS: 'contaspagar/excluir-contas',
  CONTAS_PAGAR_EXCLUIR_BAIXA: 'contaspagar/excluir-baixa',
  CONTAS_PAGAR_OBTER_DETALHE: 'contaspagar/obter-detalhes',

  CADASTRAR_ENTRADA_MANIFESTO: 'entradamercadoria/cadastrar-entrada-manifesto',

  BAIXAR_CONTAS_A_RECEBER: 'contasreceber/baixar-contas',
  CONTAS_A_RECEBER_EXCLUIR: 'contasreceber/excluir-contas',
  CONTAS_RECEBER_RECIBO_A4: 'contasreceber/impressao-recibo-a4',

  CONTAS_PAGAR_RELATORIO_LISTAGEM: 'contaspagar/relatorio-listagem',
  CONTAS_PAGAR_RELATORIO_EMAIL: 'contaspagar/enviar-email-relatorio-listagem',

  CONTAS_RECEBER_RELATORIO_LISTAGEM: 'contasreceber/relatorio-listagem',
  CONTAS_RECEBER_RELATORIO_EMAIL:
    'contasreceber/enviar-email-relatorio-listagem',

  CONFERENCIA_ESTOQUE_IMPRIMIR_RELATORIO:
    'conferenciaestoque/relatorio-listagem',
  CONFERENCIA_ESTOQUE_IMPRIMIR_EMAIL:
    'conferenciaestoque/enviar-email-relatorio-listagem',
  CONFERENCIA_ESTOQUE_IMPRIMIR_CSV: 'conferenciaEstoque/exportar-conferencia',

  RELATORIOS_PERSONALIZADOS_LISTAR:
    'relatorioproduto/listar-relatoriospersonalizados',
  RELATORIOS_PERSONALIZADOS_LISTAR_PAGINADO: 'relatorioproduto/listar-paginado',
  RELATORIOS_PERSONALIZADOS_OBTER: 'relatorioproduto/obter',
  RELATORIOS_PERSONALIZADOS_CADASTRAR: 'relatorioproduto/cadastrar',
  RELATORIO_PERSONALIZADOS_DELETAR: 'relatorioproduto/excluir',
  REALTORIO_PERSONALIZADOS_ALTERAR: 'relatorioproduto/alterar',

  RELATORIO_PRODUTO_POR_VENDA: 'relatorioproduto/relatorio-produtos-por-venda',
  RELATORIO_LUCRO_POR_PRODUTO: 'relatorioproduto/relatorio-lucro-por-produto',

  PRODUTO_RELATORIO_PRODUTOS_COM_PRECO:
    'relatorioproduto/gerar-relatorio-produtos-com-preco',
  PRODUTO_RELATORIO_PERSONALIZADO:
    'relatorioproduto/gerar-relatorio-personalizado',
  PRODUTO_OBTER_IMAGEM: 'produto/obter-imagem',

  RELATORIO_DE_INVENTARIO_LISTAGEM: 'inventario/listar-historico',
  GERAR_RELATORIO_DE_INVENTARIO: 'inventario/gerar-relatorio',

  LISTAR_COLUNAS_PARA_IMPORTACAO: '/importacao/listar-colunas-para-importacao',

  PRODUTO_COR_TAMANHO_LISTAR_SELECT: 'produtoCorTamanho/listar-select-id-nome',
  PRODUTO_LISTAR_SELECT_PAGINADO:
    'pesquisaproduto/listar-select-produto-cor-tamanho',

  TROCA_DEVOLUCAO_DEVOLVER_DINHEIRO: 'trocaDevolucao/devolver-dinheiro',
  LISTAGEM_PAGINADA_TROCA:
    'pedidoOrcamentoVenda/listar-vendas-para-troca-paginado',
  LISTAR_PRODUTOS_PARA_TROCA_DEVOLUCAO:
    'pedidoOrcamentoVendaItem/listar-itens-venda-para-troca-paginado',
  TROCA_DEVOLUCAO_GERAR_VOUCHER: 'trocaDevolucao/devolver-com-voucher',

  LISTAR_PAGINADO_CLIENTE_VOUCHER: 'vale/listar-paginado-por-cliente',
  LISTAR_PAGINAD0_VOUCHER: 'vale/listar-paginado',
  OBTER_VALE: 'vale/obter-saldo-vale',

  DEVOLUCAO_DINHEIRO_OBTER_PARA_COMPARTILHAR:
    'trocaDevolucao/obter-para-compartilhar-devolucao-dinheiro',

  VALE_OBTER_PARA_COMPARTILAHR: 'Vale/obter-para-compartilhar',

  IMPRESSAO_A4_VENDA: 'pedidoOrcamentoVenda/impressao-a4',

  OBTER_RELATORIO_VENDAS_CURVA_ABC:
    'relatorioVendas/obter-informacoes-curva-abc',
  IMPRESSAO_RELATORIO_CURVA_ABC:
    'relatorioVendas/obter-para-imprimir-curva-abc',
  RELATORIO_CURVA_ABC_ENVIAR_POR_EMAIL:
    'relatorioVendas/enviar-email-curva-abc',
  RELATORIO_CURVA_ABC_EXPORTAR_CSV: 'relatorioVendas/exportar-csv-curva-abc',

  IMPRESSAO_VENDA_MEIA_FOLHA: 'pedidoOrcamentoVenda/impressao-meia-folha',

  YOU_TUBE: 'https://www.googleapis.com/youtube/v3/videos?id=',

  PRODUTOS_CADASTRAR_DADOS_GERAIS_V2: 'v2/produtos',
  PRODUTOS_FICHA_TECNICA: 'v2/produtos/id/fichaTecnica',
  PRODUTOS_INFORMACOES_FOOD: 'v2/produtos/id/informacoes-food',
  LISTAR_LOJA_ENDERECO: 'v2/lojas/enderecos',
  PRODUTO_CADASTRAR_COR: 'cor',
  PRODUTOS_CATEGORIA_OBTER: 'categoriaProduto',

  INTEGRACAO_CAIXA_MOVEL_GERAR_PIN: 'integracaoCaixaMovel/gerar-pin',
  INTEGRACAO_CAIXA_MOVEL_CADASTRAR: 'integracaoCaixaMovel/cadastrar',
  INTEGRACAO_CADASTRAR_LOCAL_ESTOQUE:
    'integracaoCaixaMovel/alterar-local-estoque',
  INTEGRACAO_OBTER_LOCAL_ESTOQUE: 'integracaoCaixaMovel/obter-local-estoque',
  INTEGRACAO_CAIXA_ALTERAR_IMAGEM_IMPRESSAO:
    'integracaoCaixaMovel/alterar-imagem-impressao',
  INTEGRACAO_TEXTO_IMPRESSAO: 'integracaoCaixaMovel/alterar-impressao',
  INTEGRACAO_ALTERAR_TEXTO_IMPRESSAO:
    'IntegracaoCaixaMovel/alterar-texto-impressao',
  INTEGRACAO_ALTERAR_TIPO_IMPRESSAO:
    'integracaoCaixaMovel/alterar-tipo-impressao',
  INTEGRACAO_OBTER_TIPO_IMPRESSAO_SMART_POS:
    'integracaoCaixaMovel/obter-tipo-impressao',
  INTEGRACAO_CAIXA_MOVEL_ALTERAR_SENHA: 'integracaoCaixaMovel/alterar-senha',
  INTEGRACAO_OBTER_TIPO_CUPOM_FICHA:
    'integracaoCaixaMovel/obter-tipo-impressao',
  INTEGRACAO_OBTER_IMPRESSAO_SMART_POS: 'integracaoCaixaMovel/obter-impressao',
  INTEGRACAO_OBTER_SENHA_ADM: 'integracaoCaixaMovel/obter-senha-admin',
  INTEGRACAO_CAIXA_MOVEL_FORMA_RECEBIMENTO_LISTAR_SELECT:
    'integracaoCaixaMovelFormaRecebimento/listar-select',
  INTEGRACAO_CAIXA_MOVEL_FORMA_RECEBIMENTO_ENVIAR_FORMAS_RECEBIMENTO:
    'integracaoCaixaMovelFormaRecebimento/enviar-formas-recebimento',
  INTEGRACAO_SMART_POS_ENVIAR_DADOS_STONE:
    'integracaoCaixaMovel/enviar-dados-formulario-stone',
  INTEGRACAO_SMART_POS_OBTER_STONE:
    'integracaoCaixaMovel/obter-formulario-stone',
  INTEGRACAO_SMART_POS_PLANO_CONTRATADO: 'dispositivo/quantidade-contratada',
  INTEGRACAO_SMART_POS_PLANO_ATIVA: 'dispositivo/quantidade-ativa',
  INTEGRACAO_SMART_POS_LISTAR_DISPOSITIVO: 'dispositivo/listar-paginado',
  INTEGRACAO_SMART_POS_DESABILITAR_LICENCA:
    'dispositivo/desabilitar-habilitar-licenca',
  INTEGRACAO_SMART_POS_ADICIONAR_NOME: 'dispositivo/adicionar-nome-pdv',

  INTEGRACAO_HISTORICO_ACOES_LOG: 'IntegracaoLogPlataforma/listar-select',
  INTEGRACAO_TRAY_VENDAS_PEDIDO: 'pedidoVendaPorIntegracao/listar',
  INTEGRACAO_VINCULAR_PRODUTO: 'integracaoTray/vincular-produto',
  INTEGRACAO_CADASTRAR_PRODUTO: 'integracaoTray/cadastrar-produto',
  INTEGRACAO_TRAY_OPERACOES_VENDA: 'integracaoSituacaoPedido/listar-select',
  INTEGRACAO_ALTERAR_RASTREIO: 'integracaoTray/alterar-rastreio-pedido',
  INTEGRACAO_ALTERAR_RASTREIO_FINALIZAR: 'integracaoTray/finalizar-pedido',
  INTEGRACAO_ORIGEM_PEDIDO:
    'integracaoOrigemPedido/obter-por-descricao-identificacao-integracao',
  INTEGRACAO_ORIGEM_PEDIDO_ALTERAR: 'integracaoOrigemPedido/alterar',
  INTEGRACAO_TRAY_EXCLUIR_PRODUTO: 'integracaoTray/excluir-produto',
  INTEGRACAO_TRAY_EXCLUIR: 'integracaoTray/desistir',

  INTEGRACAO_TRAY_BLOQUEAR_PRODUTO: 'integracaoTray/publicar-produto',
  INTEGRACAO_TRAY_SITUACAO: 'IntegracaoSituacaoPedido',
  INTEGRACAO_TRAY_ORIGEM: 'integracaoOrigemPedido/listar-select',
  INTEGRACAO_TRAY_OBTER_LISTA_PRODUTO_PAGINADA:
    'integracaoTray/obter-lista-produto-sem-cadastro-paginado',
  INTEGRACAO_TRAY_OBTER_LISTA_PRODUTO_CADASTRADO_PAGINADA:
    'integracaoTray/obter-lista-produto-com-cadastro-paginado',
  INTEGRACOES_TRAY_EXPORTAR_PRODUTO: 'integracaoTray/exportar-produto',
  INTEGRACAO_ATIVAR_PRODUTO_TRAY: 'integracaoTray/publicar-produto',
  INTEGRACAO_TRAY_ALTERAR_IDENTIFICACAO_ETAPA:
    'integracaoTray/alterar-identificacao-etapa',
  INTEGRACAO_TRAY_OBTER_IDENTIFICACAO_ETAPA:
    'integracaoTray/obter-identificacao-etapa',
  INTEGRACAO_TRAY_AUTENTICAR_DADOS: 'integracaoTray/autenticar-dados',
  INTEGRACAO_TRAY_OBTER_AUTENTICACAO_DADOS: 'integracaoTray/obter-autenticacao',
  INTEGRACAO_TRAY_OBTER: 'integracaoTray/obter',
  INTEGRACAO_TRAY_CADASTRAR: 'integracaoTray/cadastrar-etapa',
  INTEGRACAO_TRAY_TOTAL_VENDAS: 'integracao/obter-totalizador',
  INTEGRACAO_TRAY_QUANTIDADE_PENDENCIA: 'integracao/obter-quantidade-pendencia',
  INTEGRACAO_PEDIDO_TRAY_DESVINCULAR_OPERACAO:
    'IntegracaoPedido/desvincular/operacao/{operacaoId}/identificacao/{identificacaoIntegracao}',

  INTEGRACAO_TRAY_CANAL_VENDAS_ALTERAR: 'integracaoTray/alterar-canal-venda',
  INTEGRACAO_TRAY_CANAL_VENDAS_OBTER: 'integracaoTray/obter-canal-venda',

  INTEGRACAO_TRAY_VENDEDOR_OBTER: 'integracaoTray/obter-vendedor',
  INTEGRACAO_TRAY_VENDEDOR_ALTERAR: 'integracaoTray/alterar-vendedor',

  INTEGRACAO_TRAY_COMISSAO_VENDA_ALTERAR:
    'integracaoTray/alterar-comissao-venda',
  INTEGRACAO_TRAY_OBTER_COMISSAO_VENDA: 'integracaoTray/obter-comissao-venda',

  INTEGRACAO_TRAY_TABELA_PRECO_ALTERAR: 'integracaoTray/alterar-tabela-preco',
  INTEGRACAO_TRAY_ATUALIZACAO_TABELA_PRECO:
    'integracaoTray/atualizacao-tabela-preco',
  INTEGRACAO_TRAY_TABELA_PRECO_OBTER: 'integracaoTray/obter-tabela-preco',

  INTEGRACAO_TRAY_TIPO_CADASTRO_ALTERAR: 'integracaoTray/alterar-tipo-cadastro',
  INTEGRACAO_TRAY_TIPO_CADASTRO_OBTER: 'integracaoTray/obter-tipo-cadastro',

  INTEGRACAO_TRAY_BUSCAR_PRODUTO_OBTER: 'integracaoTray/obter-buscar-produto',
  INTEGRACAO_TRAY_VINCULAR_COR: 'integracaoTray/vincular-cor',
  INTEGRACAO_TRAY_BUSCAR_PRODUTO_ALTERAR:
    'integracaoTray/alterar-buscar-produto',
  INTEGRACAO_TRAY_QUANTIDADE_PRODUTO: 'integracaoTray/obter-quantidade-produto',
  INTEGRACAO_TRAY_PRODUTO_SITE: 'integracaotray/obter-lista-produto-snapshot',
  INTEGRACAO_TRAY_IMPORTAR_PRODUTOS_SITE: 'integracaoTray/importar-produto/',
  INTEGRACAO_TRAY_IMPORTACAO_CONCLUIDA:
    'integracaoTray/notificar-importacao-etapa',
  INTEGRACAO_TRAY_OBTER_LISTA_COR: 'integracaoTray/obter-lista-cor-snapshot',
  INTEGRACAO_TRAY_COPIAR_TABELA_PRECO: 'integracaoTray/copiar-tabela-preco',

  INTEGRACAO_TRAY_OBTER_LISTA_TAMANHO:
    'integracaoTray/obter-lista-tamanho-snapshot',
  INTEGRACAO_TRAY_VINCULAR_TAMANHOS: 'integracaoTray/vincular-tamanho',

  INTEGRACAO_TRAY_OBTER_LISTA_MARCA:
    'integracaoTray/obter-lista-marca-snapshot',
  INTEGRACAO_TRAY_VINCULAR_LISTA_MARCA: 'integracaoTray/vincular-marca',

  INTEGRACAO_TRAY_OBTER_FORMA_PAGAMENTO:
    'integracaoTray/obter-lista-forma-pagamento-snapshot',
  INTEGRACAO_TRAY_VINCULAR_FORMA_PAGAMENTO:
    'integracaoTray/vincular-forma-pagamento',
  INTEGRACAO_TRAY_ALTERAR_LOCAL_ESTOQUE: 'integracaoTray/alterar-local-estoque',
  INTEGRACAO_TRAY_OBTER_LOCAL_ESTOQUE: 'integracaoTray/obter-local-estoque',

  INTEGRACAO_TRAY_OBTER_LISTA_CATEGORIAS:
    'integracaoTray/obter-lista-categoria-snapshot',

  INTEGRACAO_GERAR_NOTA_FISCAL: 'integracao/gerar-nota-fiscal',

  CADASTRAR_ETAPA_SMART_POS: 'integracaoCaixaMovel/alterar-identificacao-etapa',
  OBTER_ETAPA_SMART_POS: 'integracaoCaixaMovel/obter-identificacao-etapa',
  AUTENCICACAO_DADOS_STONE: 'integracaoCaixaMovel/autenticar-dados-stone',
  AUTENCICACAO_OBTER_DADOS_STONE:
    'integracaoCaixaMovel/obter-autenticacao-stone',
  INTEGRACAO_NOTA_FISCAL_ALERTA:
    'integracao/obter-quantidade-nota-fiscal-alerta',
  INTEGRACAO_CANCELAR_PEDIDO: 'integracaoTray/cancelar-pedido',

  GERAR_TRANSACAO_STONE: 'transacaoStone/gerar-transacao',
  FECHAR_TRANSACAO_PEDIDO_STONE: 'transacaoStone/fechar-transacao',

  ACCESS_TOKEN_OBTER: 'autenticacao/access-token',
  ACCESS_TOKEN_LOGIN: 'autenticacao/access-token/login',

  REVENDA: 'revenda',

  GERAR_TRANSACAO_ZOOP: 'transacaoPay/gerar-transacao',
  TRANSACOES_ZOOP_LISTAR_PAGINADO: 'transacaoPay/listar-paginado',
  TRANSACAO_ZOOP_ATUALIZAR_STATUS: 'transacaoPay/atualizar-transacao',

  OBTER_CREDENCIAMENTO: 'credenciamentoZoop/obter-credenciamento',
  LISTAR_ATIVIDADES: 'credenciamentoZoop/listar-atividades',
  CREDENCIAR_ZOOP: 'credenciamentoZoop/credenciar',
  CREDENCIAMENTO_CONTA_BANCARIA:
    'credenciamentoZoop/conta-bancaria-credenciamento',
  CONFIG_COBRANCAS_BOLETO: 'credenciamentoZoop/configurar-cobrancas',
  VALIDAR_CLIENTE_BOLETO_PDV: 'clienteFornecedor/validar-cliente-boleto-pdv',

  PRODUTO_ETAPA_CATEGORIAS_OBTER: 'v2/etapa-produto/categorias',
  PRODUTO_ETAPA_PRODUTOS_CATEGORIA_OBTER:
    'v2/etapa-produto/categorias/id/produtos',

  PRODUTO_ETAPA: 'v2/produtos/id/etapas',
  PRODUTO_ETAPA_SEQUENCIA_ORDENACAO:
    'v2/produtos/id/etapas/sequencia-ordenacao',

  RESET_BANCO_DADOS: 'reset',

  LISTAR_SELECT_PRODUTO_COR_PAGINADO:
    'pesquisaProduto/listar-select-produto-cor-paginado',
  PESQUISAR_PRODUTO: 'PesquisaProduto/listar-select-produto-cor',
  OBTER_INFORMACOES_PRODUTO: 'ProdutoCor/obter-pdv',
  URL_IMAGEM_PRODUTO: 'v2/Produtos/produtoId/produto-cores/produtoCorId/foto',

  GERENCIADOR_IMPRESSAO_SELECT: 'v2/GerenciadorImpressao/listar-select',

  FRENTE_CAIXA_PERIODO_CAIXA_CADASTRAR: 'periodoCaixa/cadastrar',
  FRENTE_CAIXA_PERIODO_CAIXA_OBTER: 'periodoCaixa/obter',
  FRENTE_CAIXA_PERIODO_CAIXA_ALTERAR: 'periodoCaixa/alterar',
  FRENTE_CAIXA_PERIODO_CAIXA_LISTAR_PAGINADO: 'periodoCaixa/listar-paginado',
  FRENTE_CAIXA_PERIODO_CAIXA_INATIVAR: 'periodoCaixa/inativar',

  AUTO_ATENDIMENTO_OBTER: 'AutoAtendimento/obter',
  AUTO_ATENDIMENTO_CADASTRAR: 'AutoAtendimento/cadastrar',
  AUTO_ATENDIMENTO_ALTERAR: 'AutoAtendimento/alterar',
  AUTO_ATENDIMENTO_OBTER_CONFIGURACAO: 'AutoAtendimento/obter-configuracao',
  AUTO_ATENDIMENTO_ALTERAR_IMAGEM: 'AutoAtendimento/alterar-imagem',

  CATEGORIA_PRODUTO_LISTAR_PRIMEIRO_NIVEL:
    'categoriaProduto/listar-select-primeiro-nivel',

  CATEGORIA_PRODUTO_ORDEM_EXIBICAO: 'categoriaProduto/ordem-exibicao',

  CREDENCIADORA_CARTAO_OBTER: 'credenciadoraCartao/obter',
  CREDENCIADORA_CARTAO_LISTAR_PAGINADO: 'credenciadoraCartao/listar-paginado',
  CREDENCIADORA_CARTAO_LISTAR_SELECT: 'credenciadoraCartao/listar-select',
  CREDENCIADORA_CARTAO_CADASTRAR: 'credenciadoraCartao/cadastrar',
  CREDENCIADORA_CARTAO_ALTERAR: 'credenciadoraCartao/alterar',
  CREDENCIADORA_CARTAO_EXCLUIR: 'credenciadoraCartao/excluir',
};

export default ConstanteEnderecoWebservice;
